import { bpost, GetListParam } from './api'
/**
 * 小区
 */
export interface Manger {
  id: string
  name: string
}
/**
 * 获取小区列表
 */
export async function listManger() {
  return bpost<{ list: Pick<Manger, 'id' | 'name'>[], status: Boolean }>({
    url: '/house/listManger',
  })
}
/**
 * 报修
 */
export interface Repair {
  id: string // 嘉隆那边的id
  number: string // 这个是嘉隆那边的报修单号
  mangerName: string // 小区名
  address: string // 房屋地址
  name: string // 姓名
  phone: string // 手机号码
  content: string // 报修内容
  customerId: string // 用户onlyId
  submitTime: string // 提交时间
  exBookTime: string // 期望上门日期  2019-11-23
  exNoon: string // 期望上门时段 10:20-22:40
  isRead: boolean // 是否已读
  status: string // 状态  是字符串
  utime: string // 状态更新时间
}
export interface Complaint {
  id: string // 嘉隆那边的id
  number: string // 这个是嘉隆那边的报修单号
  type: string // 现在是固定的投诉
  mangerName: string // 小区名
  address: string // 房屋地址
  name: string // 姓名
  phone: string // 手机号码
  content: string // 报修内容
  customerId: string // 用户onlyId
  submitTime: string // 提交时间
  images: string[] // 图片url列表 //todo 等待buff连
  isRead: boolean // 是否已读
  status: string // 状态  是字符串
  utime: string // 状态更新时间
}
/**
 * 获取指定用户的报修申请列表
 */
export async function listRepair(data: { uid: number }) {
  return bpost<{
    list: Repair[]
  }>({
    url: 'house/listRepair',
    data,
  })
}
/**
 * 获取指定用户的投诉列表
 */
export async function listComplaint(data: { uid: number }) {
  return bpost<{
    list: Complaint[]
  }>({
    url: 'house/listComplaint',
    data,
  })
}

/**
 * 停车位
 */
export interface Parking {
  id: number //
  parkId: string // 停车位id 这是嘉隆的id
  parkNo: string // 停车为no 你不用管,防止以后有用
  parkAddr: string // 停车位地址
  carNo: string //车牌号
  uid: number // uid
  mangerName: string // 小区名
  ctime: string
  phone: string // 手机号码 这些字段是后台用的
  wxNick: string // 微信昵称
  wxAvatar: string //微信头像
}
export interface MangerParking {
  parkId: string // 停车位id 这是嘉隆的id
  parkNo: string // 停车为no 你不用管,防止以后有用
  parkAddr: string // 停车位地址
}

/**
 * 获取车位列表
 */
export async function adminListParking(data: GetListParam) {
  return bpost<{ list: Parking[] }>({
    url: 'parking/adminListParking',
    data,
  })
}
/**
 * 获取车位详情
 */
export async function adminGetParking(data: { parkId: string }) {
  return bpost<{ detail: Parking }>({
    url: 'parking/adminGetParking',
    data,
  })
}
