import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { BraftMediaFn } from '@/services/api'
import { add, AddData, AdminSceneNotice, detail as getNotice, edit, EditData } from '@/services/notice'
import { listManger, Manger } from '@/services/outer'
import { PostsType, postsType as POSTS_TYPE } from '@api/posts'
import { Button, Form, Input, message, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import BraftEditor, { EditorState } from 'braft-editor'
import 'braft-editor/dist/index.css'
import { ContentUtils } from 'braft-utils'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

type Mode = 'add' | 'edit' | 'look'
interface Props {
  mode: Mode
  type?: PostsType
}
interface State {
  id: number
  houseIds: string[]
  previewVisible: boolean
  previewImage: string
  editorState: EditorState
  mangerList: Manger[]
  detail: AdminSceneNotice | null
  breadCrumbList: breadCrumbItem[]
}
const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 5,
      offset: 0,
    },
  },
}
const htmlEditLayout = {
  labelCol: {
    sm: {
      span: 1,
      offset: 0,
    },
    xxl: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 23,
      offset: 0,
    },
    xxl: {
      span: 18,
      offset: 0,
    },
  },
}
// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let houseIds: string[] = search.ids ? JSON.parse(search.ids) : ['0']
    return {
      id: 0,
      houseIds,
      previewVisible: false,
      previewImage: '',
      mangerList: [],
      editorState: BraftEditor.createEditorState(null),
      detail: null,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '公告管理', to: pathMap.admin.notice.list },
        { name: '添加/编辑' },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    let {
      data: { list: mangerList,status },
    } = await listManger()
    if(status){
      mangerList.unshift({
        id: '0',
        name: '全部',
      })
    }
    this.setState({
      mangerList,
    })
    const { mode } = this.props
    if (mode === 'edit' || mode === 'look') {
      let { id } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const {
        data: { detail },
      } = await getNotice({ id: parseInt(id) })
      const { setFieldsValue } = this.props.form
      const { excerpt, content, link } = detail
      setFieldsValue({
        excerpt,
        content,
        link,
      })
      this.setState({
        editorState: BraftEditor.createEditorState(content),
        detail,
        id: parseInt(id),
        houseIds: detail.mangerIdList,
      })
    }
  }
  render() {
    const {
      form: { getFieldDecorator },
      mode,
    } = this.props
    const { editorState, mangerList, houseIds } = this.state
    let mangerIdList: string[] = mode === 'edit' || mode === 'add' ? houseIds : ['0'];
    if(mangerList[0] && mode === 'add' && houseIds[0] === '0'){
      mangerIdList = [mangerList[0].id]
    }
    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>
          <Form>
            <Form.Item label="小区" {...textLayout}>
              {getFieldDecorator('houseId', {
                rules: [{ required: true, message: '请选择小区' }],
                initialValue: mangerIdList,
              })(
                <Select mode="multiple">
                  {mangerList.map(house => {
                    return (
                      <Select.Option value={house.id} key={house.id}>
                        {house.name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...textLayout} label="摘要">
              {getFieldDecorator('excerpt', {
                rules: [{ max: 500, message: '摘要信息最多500字' }],
              })(<TextArea rows={4} maxLength={500} placeholder="请输入摘要,简介,描述" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="跳转连接">
              {getFieldDecorator('link', {
                rules: [{ max: 1024, message: '跳转连接最多1024字' }],
              })(<Input size="large" placeholder="微信端的网页路径 列如:/pages/index/..." />)}
            </Form.Item>
            <Form.Item {...htmlEditLayout} label="内容">
              <BraftEditor
                style={{
                  border: 'solid 1px #333',
                  maxWidth: 1000,
                }}
                media={{
                  uploadFn: BraftMediaFn,
                }}
                value={editorState}
                onChange={editorState => {
                  this.setState({ editorState })
                }}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 12,
                },
              }}
            >
              <Button disabled={mode === 'look'} size="large" type="primary" onClick={this.formSubmit}>
                {this.props.mode === 'add' ? '添加' : '保存编辑'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
  clearContent = () => {
    this.setState({
      editorState: ContentUtils.clear(this.state.editorState),
    })
  }
  formSubmit = () => {
    const { editorState } = this.state
    const { mode } = this.props
    let validFields = ['houseId', 'excerpt', 'link']
    this.props.form.validateFields(validFields, async (err, fieldsValue) => {
      if (err) {
        return
      }
      let param: AddData | EditData | null = null
      let { excerpt, link, content, houseId: mangerIdList } = fieldsValue
      if (editorState.isEmpty()) {
        return message.error('文章内容必填', 2)
      }
      content = editorState.toHTML()
      param = {
        id: 0,
        excerpt,
        content,
        link: link || '',
        mangerIdList,
      }
      if (!param) {
        return
      }
      if (mode === 'add') {
        add(param)
          .then(_ => {
            this.props.form.resetFields()
            this.clearContent()
            message.success('添加成功', 1)
          })
          .catch(err => {
            message.error('添加失败,失败原因: ' + err.msg, 3)
            console.log(err)
          })
      } else {
        let { id } = this.state
        ;(param as EditData).id = id
        edit(param as EditData)
          .then(_ => {
            message.success('编辑成功', 1)
          })
          .catch(err => {
            message.error('编辑失败,错误信息: ' + err.msg, 3)
            console.log(err)
          })
      }
    })
  }
}
