import { AppState } from '@/redux/stores/app'
import pathMap from '@/routes/pathMap'
import { logout } from '@api/user'
import * as adminAction from '@redux/actions/admin'
import { LocalStorage as storage } from '@utils/jsbdk'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Dispatch } from 'redux'

interface Props {
  logout: () => void
}
interface State {
  redirectUrl: string | null
}
const mapStateToProps = (_: AppState) => {
  return {}
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => {
      dispatch(adminAction.logout())
    },
  }
}
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Logout extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      redirectUrl: null,
    }
  }
  componentDidMount() {
    logout()
      .then(_ => {
        let redirectUrl = pathMap.home
        if (storage.has('adminIsLogin')) {
          this.props.logout()
          redirectUrl = pathMap.admin.index
        }
        this.setState({
          redirectUrl,
        })
        storage.remove('userIsLogin')
        storage.remove('adminIsLogin')
        storage.remove('isLogin')
        storage.remove('uid')
        storage.remove('nick')
        storage.remove('token')
      })
      .catch(err => {
        console.log(err)
      })
  }
  render() {
    return this.state.redirectUrl !== null ? <Redirect to={this.state.redirectUrl} /> : <div />
  }
}
