import pathMap from '@/routes/pathMap'
import { getUploadImgUrl } from '@api/api'
// import RealNameAuthentication from "./RealNameAuthentication";
// import CampusInformation from "./CampusInformation";
import gSass from '@utils/sass'
import { Form, Menu } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import qs from 'qs'
import React, { Component } from 'react'
import { Link, Route } from 'react-router-dom'
import BaseSettings from './BaseSettings'
import Security from './Security'

const style = gSass.admin.settings.base
interface Props {}
interface State {
  id: number
  previewVisible: boolean
  previewImage: string
  fileList: any[]
  uploadUrl: any
  menuKey: number
}
type DefaultProps = FormComponentProps
//@ts-ignore
@Form.create()
export default class Detail extends Component<Props & DefaultProps, State> {
  constructor(props: any) {
    super(props)
    let {
      location: { search },
    } = props
    let { id } = qs.parse(search, { ignoreQueryPrefix: true })
    let menuKey = 1
    switch (props.location.pathname) {
      case pathMap.admin.master.detail.index:
      case pathMap.admin.master.detail.base:
        menuKey = 1
        break
      case pathMap.admin.master.detail.security:
        menuKey = 2
        break
      default:
        menuKey = 1
        break
    }
    this.state = {
      id: parseInt(id),
      previewVisible: false,
      previewImage: '',
      fileList: [],
      uploadUrl: getUploadImgUrl,
      menuKey,
    }
  }
  componentDidMount() {}
  menuOnSelect = (evt: any) => {
    this.setState({
      menuKey: parseInt(evt.key),
    })
  }
  render() {
    return (
      <div className={style.base}>
        <div className={style.baseBox}>
          <div className={style.left}>
            <Menu mode="inline" defaultSelectedKeys={[this.state.menuKey + '']} onSelect={this.menuOnSelect}>
              <Menu.Item key="1">
                <Link to={pathMap.admin.master.detail.base + '?id=' + this.state.id}>
                  <span>基本信息</span>
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="2">
                <Link to={pathMap.admin.master.detail.security + '?id=' + this.state.id}>
                  <span>安全设置</span>
                </Link>
              </Menu.Item> */}
            </Menu>
          </div>
          <div className={style.right}>
            <Route exact path={pathMap.admin.master.detail.index} component={BaseSettings} />
            <Route exact path={pathMap.admin.master.detail.base} component={BaseSettings} />
            <Route exact path={pathMap.admin.master.detail.security} component={Security} />
          </div>
        </div>
      </div>
    )
  }
}
