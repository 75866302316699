import React, { Component } from "react"
interface Props {}
interface State {}

export default class Page404 extends Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {}
  }
  render() {
    return <div> 404 </div>
  }
}
