import { DbBaseField, Picture } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { GetListParam } from './api'
import { GoodsCategory } from './goodsCategory'
import { GoodsComment } from './goodsComment'
export type Scene = 'default' | 'admin'

export interface PriceInfo {
  standard: string // 规格
  description: string // 介绍
  score: number // 所需积分
}

/**
 * 商品
 */
export interface Goods extends DbBaseField {
  name: number
  picList: Picture[]
  picIdList: number[]
  category: GoodsCategory
  categoryId: number
  priceInfo: PriceInfo[] // 价格信息
  stock: number // 库存
  buyCount: number // 销量
  isOnSale: 'true' | 'false' // 是否已上架
  content: string // 商品介绍 富文本
  comments: GoodsComment[] // 评论列表
}
export type AdminSceneGoods = Goods & {
  mangerIdList: string[] // 小区id列表
}

export type AddData = Pick<
  AdminSceneGoods,
  'name' | 'picIdList' | 'categoryId' | 'content' | 'priceInfo' | 'stock' | 'mangerIdList'
>
export type EditData = Pick<
  AdminSceneGoods,
  'id' | 'name' | 'picIdList' | 'categoryId' | 'content' | 'priceInfo' | 'stock' | 'mangerIdList'
>
export async function add(data: AddData) {
  return bpost({
    url: 'goods/add',
    data,
  })
}
export async function del(data: { ids: number[] }) {
  return bpost({
    url: 'goods/del',
    data,
  })
}
export async function edit(data: EditData) {
  return bpost({
    url: 'goods/edit',
    data,
  })
}
export async function detail(data: { id: number }) {
  return bpost<{ detail: AdminSceneGoods }>({
    url: 'goods/detail',
    data: {
      ...data,
      scene: 'admin',
    },
  })
}

export async function list(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'admin'
  return bpost<{ list: AdminSceneGoods[] }>({
    url: 'goods/list',
    data,
  })
}
export async function changeOnSaleStatus(data: { id: number; isOnSale: 'true' | 'false' }) {
  return bpost({
    url: 'goods/changeOnSaleStatus',
    data,
  })
}
