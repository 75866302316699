import { AppState } from '@/redux/stores/app'
import { MenuItem, BuffRouteProps } from '@/typings'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Layout } from 'antd'
import SideBar from '@/components/admin/common/SideBar'
import Header from '@/components/admin/common/Header'
import Content from '@/components/admin/common/Content'
import { getMenuList } from '@/config/menuList'
import { rootUid } from '@/services/user'
// import { rootUid } from '@/services/user'
interface Props {}
interface State {
  sideBarIsCollapsed: boolean
  menuList: MenuItem[]
  defaultOpenKeys: string[]
  defaultSelectedKeys: string[]
}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
  admin: state.admin,
})
type DefaultProps = ReturnType<typeof mapStateToProps> & RouteComponentProps & BuffRouteProps
// @ts-ignore
@connect(mapStateToProps)
export default class Index extends Component<Props & DefaultProps, State> {
  menuKey = 0
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }

  getInitState = (): State => {
    const menuList = getMenuList(this.props.admin.uid === rootUid)
    const [defaultOpenKeys, defaultSelectedKeys] = this.getDefaultKeys(menuList)
    return {
      sideBarIsCollapsed: false,
      menuList,
      defaultOpenKeys,
      defaultSelectedKeys,
    }
  }
  getDefaultKeys = (menuList: MenuItem[], level = 0, partentKeys: string[] = []): [string[], string[]] => {
    for (let v of menuList) {
      if (Array.isArray(v.child) && v.child.length > 0) {
        let currPartentKeys = [...partentKeys]
        let key = this.menuKey++
        currPartentKeys.push(`sub${key}`)
        let res: [string[], string[]] = this.getDefaultKeys(v.child, level + 1, currPartentKeys)
        if (res[0].length !== 0) {
          return res
        }
      } else {
        let key = this.menuKey++
        if (v.href === this.props.location.pathname) {
          return [partentKeys, [key + '']]
        }
      }
    }
    return [[], []]
  }
  changeCollapse = (sideBarIsCollapsed: boolean) => {
    this.setState({
      sideBarIsCollapsed,
    })
  }
  render() {
    return (
      <div>
        <Layout style={{ minHeight: '100vh' }}>
          <SideBar
            collapsed={this.state.sideBarIsCollapsed}
            changeCollapse={this.changeCollapse}
            menuList={this.state.menuList}
            defaultOpenKeys={this.state.defaultOpenKeys}
            defaultSelectedKeys={this.state.defaultSelectedKeys}
          />
          <Layout>
            <Header collapsed={this.state.sideBarIsCollapsed} changeCollapse={this.changeCollapse} />
            <Content routes={this.props.routes} />
          </Layout>
        </Layout>
      </div>
    )
  }
}
