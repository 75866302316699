import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { BraftMediaFn, getUploadImgUrl } from '@/services/api'
import { listManger, Manger } from '@/services/outer'
import { conditionType } from '@/utils/constant'
import { getPicFullUrl } from '@/utils/fn'
import { ListParam } from '@/utils/jsbdk'
import {
  add as addPosts,
  addMangerPost,
  AddPostsData,
  detail as getPostsDetail,
  edit as editPosts,
  editMangerPost,
  EditPostsData,
  postCategoryNameMapId,
  Posts,
  PostsType,
  postsType as POSTS_TYPE,
  postsTypeZh as POSTS_TYPE_ZH,
  status,
} from '@api/posts'
import { list as listPostsCategory, PostsCategoryWithChild } from '@api/postsCategory'
import { Button, Form, Icon, Input, InputNumber, message, Modal, Select, TreeSelect, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import BraftEditor, { EditorState } from 'braft-editor'
import 'braft-editor/dist/index.css'
import { ContentUtils } from 'braft-utils'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
const TreeNode = TreeSelect.TreeNode

type Mode = 'add' | 'edit' | 'look'
interface Props {
  mode: Mode
  type?: PostsType
  categoryId?: number
}
interface State {
  previewVisible: boolean
  previewImage: string
  postsType: PostsType
  editorState: EditorState
  postsCategoryList: PostsCategoryWithChild[]
  postsPicList: UploadFile[]
  detail: Posts
  mangerList: Manger[]
  breadCrumbList: breadCrumbItem[]
}
const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 5,
      offset: 0,
    },
  },
}
const imgListLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 6,
      offset: 0,
    },
  },
}
const htmlEditLayout = {
  labelCol: {
    sm: {
      span: 1,
      offset: 0,
    },
    xxl: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 23,
      offset: 0,
    },
    xxl: {
      span: 18,
      offset: 0,
    },
  },
}
// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      postsType: this.defaultType,
      previewVisible: false,
      previewImage: '',
      postsCategoryList: [],
      postsPicList: [],
      editorState: BraftEditor.createEditorState(null),
      detail: {
        mangerIdList: [],
        author: {
          id: 0,
          nick: '',
          phone: '',
          avatar: {
            id: 0,
            title: '',
            url: '',
          },
        },
        category: {
          id: 0,
          name: '',
          pid: 0,
        },
        content: '',
        ctime: '',
        excerpt: '',
        id: 0,
        // mimeType: "",
        name: '',
        sort: 0,
        sourceName: '',
        sourceUrl: '',
        status: status.publish,
        title: '',
        type: this.defaultType,
        utime: '',
        viewCount: 0,
        picList: [],
      },
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '文章管理', to: pathMap.admin.post.list },
        { name: '添加/编辑' },
      ],
      mangerList: [],
    }
  }
  componentDidMount() {
    this.init()
    this.breadCrumbList()
  }

  init = async () => {
    const { categoryId } = this.props
    const { setFieldsValue } = this.props.form
    let listPostsCategoryParam: ListParam = { page: 0, limit: 0 }
    if (categoryId) {
      listPostsCategoryParam.filter = {
        id: {
          condition: conditionType.eq,
          val: categoryId,
        },
      }
    }
    let {
      data: { list: mangerList,status },
    } = await listManger()
    if(status){
      mangerList.unshift({
        id: '0',
        name: '全部',
      })
    }
    this.setState({
      mangerList,
    })
    const {
      data: { list: postsCategoryList },
    } = await listPostsCategory(listPostsCategoryParam)
    if (categoryId) {
      this.setState({
        postsCategoryList,
      })
    } else {
      this.setState({
        postsCategoryList: [
          {
            id: 0,
            name: '无分类',
            pid: 0,
            description: '',
            picture: {
              id: 0,
              title: '',
              url: '',
            },
            children: postsCategoryList,
          },
        ],
      })
    }
    const { mode } = this.props
    if (mode === 'edit' || mode === 'look') {
      let { id } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const {
        data: { detail },
      } = await getPostsDetail({ id:parseInt(id), withMangerIdList: true })
      this.setState({ detail })
      const {
        type: postsType,
        category,
        title,
        excerpt,
        sourceName,
        sourceUrl,
        content,
        name,
        sort,
        picList,
        mangerIdList: houseId,
      } = detail
      const categoryId = category ? category.id : 0
      this.setState({
        postsType,
      })
      setFieldsValue({
        type: postsType,
        category: categoryId,
        houseId,
      })
      switch (postsType) {
        case POSTS_TYPE.article:
          setFieldsValue({
            title,
            sort,
            excerpt,
            sourceName,
            sourceUrl,
          })
          this.setPicList(picList)
          this.setState({
            editorState: BraftEditor.createEditorState(content),
          })
          break
        case POSTS_TYPE.text:
          setFieldsValue({
            name,
            content,
          })
          break
        case POSTS_TYPE.int:
          setFieldsValue({
            name,
            content: parseInt(content),
          })
          break
        case POSTS_TYPE.double:
          setFieldsValue({
            name,
            content: parseFloat(content),
          })
          break
        case POSTS_TYPE.json:
          setFieldsValue({
            name,
            content,
          })
          break
        case POSTS_TYPE.pic:
          setFieldsValue({
            name,
          })
          this.setPicList(picList)
          break
      }
    }
  }
  breadCrumbList = () => {
    let { mode, categoryId } = this.props
    let { breadCrumbList } = this.state
    if (mode === 'edit' || mode === 'look') {
      switch (categoryId) {
        case postCategoryNameMapId.news:
          breadCrumbList[1] = {
            name: '资讯管理',
            to: pathMap.admin.news.list,
          }
          breadCrumbList[2] = {
            name: '资讯编辑',
          }
          break
        default:
          break
      }
    } else {
      switch (categoryId) {
        case postCategoryNameMapId.news:
          breadCrumbList[1] = {
            name: '资讯管理',
            to: pathMap.admin.news.list,
          }
          breadCrumbList[2] = {
            name: '添加资讯',
          }
          break
        default:
          break
      }
    }
    this.setState({
      breadCrumbList,
    })
  }
  setPicList = (picList: Posts['picList']) => {
    if (picList) {
      let { postsPicList } = this.state
      postsPicList = []
      for (let pic of picList) {
        ;(postsPicList as (UploadFile & { picId: number })[]).push({
          name: pic.title,
          uid: pic.id + '',
          url: getPicFullUrl(pic.url),
          type: 'image',
          size: 0,
          picId: pic.id,
        })
      }
      this.setState({ postsPicList })
    }
  }
  renderTreeNodes = (data: PostsCategoryWithChild[]) =>
    data.map(category => {
      return (
        <TreeNode key={category.id + ''} title={category.name} value={category.id} dataRef={category}>
          {this.renderTreeNodes(category.children || [])}
        </TreeNode>
      )
    })
  getFormDom = () => {
    const {
      form: { getFieldDecorator },
      mode,
      categoryId,
      type,
    } = this.props
    let { mangerList } = this.state
    return (
      <Form>
        <Form.Item label="文章分类" {...textLayout}>
          {getFieldDecorator('category', {
            rules: [{ required: true, message: '请选择文章分类' }],
            initialValue: categoryId ? categoryId : 0,
          })(
            <TreeSelect
              showSearch
              style={{ width: 300 }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="请选择文章分类"
              allowClear
              treeDefaultExpandAll
            >
              {this.renderTreeNodes(this.state.postsCategoryList)}
            </TreeSelect>,
          )}
        </Form.Item>
        <Form.Item label="文章类型" {...textLayout}>
          {getFieldDecorator('type', {
            rules: [{ required: true, message: '请选择文章类型' }],
            initialValue: type ? type : this.defaultType,
          })(
            <Select<PostsType>
              onChange={postsType => {
                this.setState({
                  postsType,
                })
              }}
              disabled={type ? true : false}
            >
              {Object.values(POSTS_TYPE).map((type, k) => {
                return (
                  <Select.Option value={type} key={k}>
                    {POSTS_TYPE_ZH[type]}
                  </Select.Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="小区" {...textLayout}>
          {getFieldDecorator('houseId', {
            rules: [{ required: true, message: '请选择小区' }],
            initialValue: mangerList[0] ? [mangerList[0].id] : ['0'],
          })(
            <Select mode="multiple">
              {mangerList.map(house => {
                return (
                  <Select.Option value={house.id} key={house.id}>
                    {house.name}
                  </Select.Option>
                )
              })}
            </Select>,
          )}
        </Form.Item>
        {this.getActualFieldDom()}
        <Form.Item
          wrapperCol={{
            xs: {
              span: 7,
              offset: 12,
            },
          }}
        >
          <Button disabled={mode === 'look'} size="large" type="primary" onClick={this.formSubmit}>
            {this.props.mode === 'add' ? '添加' : '编辑'}
          </Button>
        </Form.Item>
      </Form>
    )
  }
  getFormNameFieldDom = () => {
    const {
      form: { getFieldDecorator },
    } = this.props
    return (
      <>
        <Form.Item {...textLayout} label="文章名">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: '文章名必填' },
              { min: 1, max: 64, message: '请输入1-64位的文章名' },
            ],
          })(<Input size="large" placeholder="请输入文章名" />)}
        </Form.Item>
      </>
    )
  }
  getActualFieldDom = () => {
    const { postsType } = this.state
    switch (postsType) {
      case POSTS_TYPE.article:
        return this.getArticleDom()
      case POSTS_TYPE.text:
        return this.getTextDom()
      case POSTS_TYPE.int:
        return this.getIntDom()
      case POSTS_TYPE.double:
        return this.getDoubleDom()
      case POSTS_TYPE.json:
        return this.getJsonDom()
      case POSTS_TYPE.pic:
        return this.getPicDom()
    }
    return null
  }
  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }
  getArticleDom = () => {
    const {
      form: { getFieldDecorator },
    } = this.props
    const { editorState, postsPicList } = this.state
    return (
      <>
        <Form.Item {...textLayout} label="标题">
          {getFieldDecorator('title', {
            rules: [
              { required: true, message: '标题必填' },
              { min: 2, max: 64, message: '请输入2-64位的标题名' },
            ],
          })(<Input size="large" placeholder="请输入标题" />)}
        </Form.Item>
        <Form.Item {...textLayout} label="摘要">
          {getFieldDecorator('excerpt', {
            rules: [{ max: 500, message: '摘要信息最多500字' }],
          })(<TextArea rows={4} maxLength={500} placeholder="请输入摘要,简介,描述" />)}
        </Form.Item>
        <Form.Item {...imgListLayout} label="图片列表">
          <Upload
            action={getUploadImgUrl()}
            listType="picture-card"
            fileList={postsPicList}
            onPreview={this.handlePreview}
            onChange={this.onPostsPicChange}
            accept="image/*"
            name="file"
            data={{ name: 'file' }}
          >
            {postsPicList.length > 8 ? null : (
              <div>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
              </div>
            )}
          </Upload>
          <Modal
            visible={this.state.previewVisible}
            footer={null}
            onCancel={() => this.setState({ previewVisible: false })}
          >
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
          </Modal>
        </Form.Item>
        <Form.Item {...textLayout} label="排序">
          {getFieldDecorator(
            'sort',
            {},
          )(
            <InputNumber style={{ width: 200 }} precision={0} step={1} size="large" placeholder="请输入排序,默认为0" />,
          )}
        </Form.Item>
        <Form.Item {...textLayout} label="来源名">
          {getFieldDecorator('sourceName', {
            rules: [{ max: 64, message: '来源名最多64字' }],
          })(<Input size="large" placeholder="请输入来源名" />)}
        </Form.Item>
        <Form.Item {...textLayout} label="来源网址">
          {getFieldDecorator('sourceUrl', {
            rules: [
              { max: 1024, message: '来源url最多1024字' },
              {
                type: 'url',
                message: '不是正确的url',
              },
            ],
          })(<Input size="large" placeholder="请输入来源网址" />)}
        </Form.Item>
        <Form.Item {...htmlEditLayout} label="内容">
          <BraftEditor
            style={{
              border: 'solid 1px #333',
              maxWidth: 1000,
            }}
            media={{
              uploadFn: BraftMediaFn,
            }}
            value={editorState}
            onChange={editorState => {
              this.setState({ editorState })
            }}
          />
        </Form.Item>
      </>
    )
  }
  getTextDom = () => {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        {this.getFormNameFieldDom()}
        <Form.Item {...htmlEditLayout} label="内容">
          {getFieldDecorator('content', {
            rules: [
              { required: true, message: '内容必填' },
              { max: 500, message: '请输入500字以内内容' },
            ],
          })(<TextArea rows={4} maxLength={500} placeholder="请输入内容(500字以内)" />)}
        </Form.Item>
      </>
    )
  }
  getIntDom = () => {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        {this.getFormNameFieldDom()}
        <Form.Item {...textLayout} label="内容">
          {getFieldDecorator('content', {
            rules: [
              { required: true, message: '内容必填' },
              { type: 'integer', message: '内容必须为整数' },
            ],
          })(<InputNumber style={{ width: 100 }} precision={0} step={1} placeholder="请输入整数" />)}
        </Form.Item>
      </>
    )
  }
  getDoubleDom = () => {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        {this.getFormNameFieldDom()}
        <Form.Item {...textLayout} label="内容">
          {getFieldDecorator('content', {
            rules: [
              { required: true, message: '内容必填' },
              { type: 'number', message: '内容必须为浮点数' },
            ],
          })(<InputNumber style={{ width: 100 }} precision={2} step={0.01} placeholder="请输入浮点数" />)}
        </Form.Item>
      </>
    )
  }
  getJsonDom = () => {
    const { getFieldDecorator } = this.props.form
    return (
      <>
        {this.getFormNameFieldDom()}
        <Form.Item {...textLayout} label="内容">
          {getFieldDecorator('content', {
            rules: [
              { required: true, message: '内容必填' },
              {
                validator: (_, val, cb) => {
                  let errors = []
                  try {
                    JSON.parse(val)
                  } catch (e) {
                    errors.push(new Error('json格式不正确'))
                  }
                  cb(errors)
                },
              },
            ],
          })(<TextArea rows={4} maxLength={10000} placeholder="请输入json内容(10000字以内)" />)}
        </Form.Item>
      </>
    )
  }
  getPicDom = () => {
    const { postsPicList } = this.state
    return (
      <>
        {this.getFormNameFieldDom()}
        <Form.Item {...imgListLayout} label="内容">
          <Upload
            action={getUploadImgUrl()}
            listType="picture-card"
            fileList={postsPicList}
            onChange={this.onPostsPicChange}
            accept="image/*"
            name="file"
            data={{ name: 'file' }}
          >
            {postsPicList.length > 8 ? null : (
              <div>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </>
    )
  }
  getValidFields = (postsType: PostsType): string[] => {
    let validFields = ['category', 'type', 'houseId']
    switch (postsType) {
      case POSTS_TYPE.article:
        validFields.push(...['title', 'sort', 'excerpt', 'sourceName', 'sourceUrl'])
        break
      case POSTS_TYPE.text:
      case POSTS_TYPE.int:
      case POSTS_TYPE.double:
      case POSTS_TYPE.json:
        validFields.push(...['name', 'content'])
        break
      case POSTS_TYPE.pic:
        validFields.push(...['name'])
        break
    }
    return validFields
  }
  render() {
    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>{this.getFormDom()}</div>
      </div>
    )
  }
  onPostsPicChange = ({ file, fileList: postsPicList }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code !== 0) {
          postsPicList.splice(postsPicList.length - 1, 1)
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        } else {
          ;(postsPicList[postsPicList.length - 1] as UploadFile & { picId: number }).picId = json.data.picId
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      postsPicList,
    })
  }
  clearContent = () => {
    this.setState({
      editorState: ContentUtils.clear(this.state.editorState),
    })
  }
  formSubmit = () => {
    const { postsType, postsPicList, editorState } = this.state
    const { mode, categoryId } = this.props
    let validFields = this.getValidFields(postsType)
    this.props.form.validateFields(validFields, async (err, fieldsValue) => {
      if (err) {
        return
      }
      let param: AddPostsData | EditPostsData | null = null
      let { category, title, excerpt, sourceName, sourceUrl, name, content, sort, houseId: mangerIdList } = fieldsValue
      let picIdList: number[] = []
      for (let pic of postsPicList) {
        picIdList.push((pic as UploadFile & { picId: number }).picId)
      }
      switch (postsType) {
        case POSTS_TYPE.article:
          if (editorState.isEmpty()) {
            return message.error('文章内容必填', 2)
          }
          content = editorState.toHTML()
          param = {
            category,
            type: postsType,
            title,
            excerpt,
            sourceName,
            sourceUrl,
            content,
            picIdList,
            sort,
          }
          break
        case POSTS_TYPE.text:
        case POSTS_TYPE.int:
        case POSTS_TYPE.double:
        case POSTS_TYPE.json:
          param = {
            category,
            type: postsType,
            name,
            content,
          }
          break
        case POSTS_TYPE.pic:
          param = {
            name,
            type: postsType,
            category,
            picIdList,
          }
          break
      }
      if (!param) {
        return
      }
      if (mode === 'add') {
        if (categoryId === postCategoryNameMapId.news) {
          ;(param as AddPostsData & { mangerIdList: string[] }).mangerIdList = mangerIdList
          addMangerPost(param as AddPostsData & { mangerIdList: string[] })
            .then(_ => {
              this.props.form.resetFields()
              this.setState({
                postsPicList: [],
                postsType: this.defaultType,
              })
              this.clearContent()
              message.success('添加成功', 1)
            })
            .catch(err => {
              message.error('添加失败,失败原因: ' + err.msg, 3)
              console.log(err)
            })
        } else {
          addPosts(param)
            .then(_ => {
              this.props.form.resetFields()
              this.setState({
                postsPicList: [],
                postsType: this.defaultType,
              })
              this.clearContent()
              message.success('添加成功', 1)
            })
            .catch(err => {
              message.error('添加失败,失败原因: ' + err.msg, 3)
              console.log(err)
            })
        }
      } else {
        if (categoryId === postCategoryNameMapId.news) {
          ;(param as EditPostsData & { mangerIdList: string[] }).mangerIdList = mangerIdList
          ;(param as EditPostsData).id = this.state.detail.id
          editMangerPost(param as EditPostsData & { mangerIdList: string[] })
            .then(_ => {
              message.success('编辑成功', 1)
            })
            .catch(err => {
              message.error('编辑失败,错误信息: ' + err.msg, 3)
              console.log(err)
            })
        } else {
          ;(param as EditPostsData).id = this.state.detail.id
          editPosts(param as EditPostsData)
            .then(_ => {
              message.success('编辑成功', 1)
            })
            .catch(err => {
              message.error('编辑失败,错误信息: ' + err.msg, 3)
              console.log(err)
            })
        }
      }
    })
  }
}
