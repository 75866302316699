import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { adminGetCommonUserDetail, CommonAdminGetCommonUser } from '@/services/user'
import { Sex, sexMap } from '@/services/wxUser'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
const style = gSass.admin.user.detail
interface Props {}
interface State {
  id: number
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  breadCrumbList: breadCrumbItem[]
  detail: CommonAdminGetCommonUser | null
}
type DefaultProps = RouteComponentProps<{ id: string }> & FormComponentProps

// @ts-ignore
@Form.create()
export default class Detail extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id) || 0
    return {
      id,
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '投诉管理', to: pathMap.admin.complaint.index },
        { name: '投诉详情' },
      ],
      detail: null,
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
      let { id } = this.state
      let {
        data: { detail },
      } = await adminGetCommonUserDetail({ id })
      this.setState({ detail })
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    let { detail } = this.state
    if (!detail) {
      return <div></div>
    }
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          <div className={style.list}>
            <div className={style.item}>
              <div className={style.title}>用户信息</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>ID</div>
                  <div className={style.detail}>{detail.id}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>用户昵称</div>
                  <div className={style.detail}>{detail.nick}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>积分</div>
                  <div className={style.detail}>{detail.score}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>邮箱</div>
                  <div className={style.detail}>{detail.email}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>简介</div>
                  <div className={style.detail}>{detail.profile}</div>
                </div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>微信信息</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>微信昵称</div>
                  <div className={style.detail}>{detail.wxInfo.nickname}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>性别</div>
                  <div className={style.detail}>{sexMap[detail.wxInfo.sex as Sex]}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>openId</div>
                  <div className={style.detail}>{detail.wxInfo.openid}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>地区</div>
                  <div className={style.detail}>
                    {detail.wxInfo.country}/{detail.wxInfo.province}/{detail.wxInfo.city}
                  </div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>头像</div>
                  <div
                    className={style.detail}
                    onClick={() => {
                      this.setState({
                        isShowImg: detail ? true : false,
                        showImages: detail ? [detail.wxInfo.headImgUrl] : [],
                      })
                    }}
                  >
                    <img alt="" className={style.img} src={detail.wxInfo.headImgUrl || gImg.common.defaultAvatar}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PictureView
          isShowImg={this.state.isShowImg}
          photoIndex={this.state.photoIndex}
          showImages={this.state.showImages}
          onMovePrevRequest={this.onMovePrevRequest}
          onMoveNextRequest={this.onMoveNextRequest}
          onChange={this.onChange}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
