import { DbBaseField } from '@/typings'
import { ListParam, bpost } from '@/utils/jsbdk'
import { OriOperationGroup, EditGroupData, AddGroupData } from './operationGroup'
/**
 * 操作
 */
export interface Operation extends DbBaseField {
  name: string
  action: string
  description: string
  cuid: number // 创建者uid
}
export type CommonOperation = Pick<Operation, 'id' | 'name' | 'action' | 'description'>

/**
 * 添加操作
 */
export async function addOperation({ name, action, description }: Partial<Operation>) {
  return bpost({
    url: 'operation/add',
    data: {
      name,
      action,
      description,
    },
  })
}
/**
 * 删除操作
 */
export async function deleteOperation({ ids }: { ids: number[] }) {
  return bpost({
    url: 'operation/del',
    data: {
      ids,
    },
  })
}
/**
 * 编辑操作
 */
export async function editOperation({ id, name, action, description }: Partial<Operation>) {
  return bpost({
    url: 'operation/edit',
    data: {
      id,
      name,
      action,
      description,
    },
  })
}
/**
 * 操作详情
 */
export async function detail(id: number) {
  return bpost<{ detail: CommonOperation }>({
    url: 'operation/detail',
    data: {
      id,
    },
  })
}
/**
 * 获取操作列表
 */
export async function listOperation(data: ListParam) {
  return bpost<{ list: CommonOperation[] }>({
    url: 'operation/list',
    data,
  })
}

/**
 * 添加操作组
 */
export async function addOperationGroup(data: AddGroupData) {
  return bpost({
    url: 'operation/addGroup',
    data,
  })
}
/**
 * 删除操作组
 */
export async function deleteOperationGroup(ids: number[]) {
  return bpost({
    url: 'operation/delGroup',
    data: {
      ids,
    },
  })
}
export async function editOperationGroup(data: EditGroupData) {
  return bpost({
    url: 'operation/editGroup',
    data,
  })
}
export async function groupDetail(id: number) {
  return bpost<{ detail: OriOperationGroup }>({
    url: 'operation/groupDetail',
    data: {
      id,
    },
  })
}

export async function listGroup(data: ListParam) {
  return bpost<{ list: OriOperationGroup[] }>({
    url: 'operation/listGroup',
    data,
  })
}
