import pathMap from '@/routes/pathMap'
import { listRecord, UserScoreRecord } from '@/services/score'
import { ListFilter } from '@/utils/jsbdk'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { UserWithAvatar } from '@/services/user'
import { Order } from '@/services/order'
import { GoodsOrder } from '@/services/goodsOrder'
import qs from 'qs'
import { conditionType } from '@/utils/constant'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
type Item = UserScoreRecord
interface State {
  uid: number
  breadCrumbList: breadCrumbItem[]
  list: Item[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: Item[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class ScoreRecord extends Component<
  Props & FormComponentProps & RouteProps & RouteComponentProps,
  State
> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '用户',
      dataIndex: 'user',
      render: (user: UserWithAvatar) => {
        return <Link to={pathMap.admin.user.detail + '?id=' + user.id}>{user.nick}</Link>
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
    },
    {
      title: '物业费订单',
      dataIndex: 'order',
      render: (order: Order, record: Item) => {
        return (
          <>
            {record.type === '缴费'
              ? order && (
                  <Link to={pathMap.admin.pay.detail + '?id=' + record.orderId}>{order && order.outTradeNo}</Link>
                )
              : '_'}
          </>
        )
      },
    },
    {
      title: '商品订单ID',
      dataIndex: 'goodsOrder',
      render: (goodsOrder: GoodsOrder, record: Item) => {
        return (
          <>
            {record.type === '购买商品'
              ? goodsOrder && (
                  <Link to={pathMap.admin.integralMall.detail + '?id=' + record.goodsOrder!.goodsId}>
                    {goodsOrder && goodsOrder.goodsDetail.name}
                  </Link>
                )
              : '_'}
          </>
        )
      },
    },
    {
      title: '连续签到',
      dataIndex: 'signInDays',
      render: (signInDays: number, record: Item) => {
        return <div>{record.type === '签到' ? signInDays : '_'}</div>
      },
    },
    {
      title: '原始积分',
      dataIndex: 'oriScore',
    },
    {
      title: '变动积分',
      dataIndex: 'offset',
    },
    {
      title: '当前积分',
      dataIndex: 'currScore',
    },
    {
      title: '变动时间',
      dataIndex: 'ctime',
      render: (text: string) => {
        return <div>{text && text.substr(0, 10)}</div>
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let uid: number = parseInt(search.id) || 0
    return {
      uid,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '用户管理', to: pathMap.admin.user.index },
        { name: '积分变动记录' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }

  listUser = async () => {
    try {
      let { uid, pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      if (uid) {
        filter.uid = {
          condition: conditionType.eq,
          val: uid,
        }
      }
      let json = await listRecord({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
      this.setState({
        tableIsLoading: false,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  sendMsg = (ids: number[]) => {
    this.props.history.push(pathMap.admin.message.add + '?ids=' + JSON.stringify(ids))
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
    ]
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入姓名|类型...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              // rowSelection: {
              //   onChange: (_: any, selectedRows: any) => {
              //     this.setState({
              //       selectedRows,
              //     })
              //   },
              // },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'uName',
                  conditionFieldName: 'uNameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '用户名',
                },
                {
                  valFieldName: 'type',
                  conditionFieldName: 'typeCondition',
                  type: 'select',
                  title: '类型',
                  selectDataList: [
                    { value: '', title: '全部' },
                    { value: '管理员改动', title: '管理员改动' },
                    { value: '缴费', title: '缴费' },
                    { value: '签到', title: '签到' },
                    { value: '购买商品', title: '购买商品' },
                    { value: '首次注册绑定房产', title: '首次注册绑定房产' },
                  ],
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'ctimeTime',
                  conditionFieldName: 'ctimeTimeCondition',
                  dateFmt: 'YYYY-MM-DD',
                  outDateFmt: 'YYYY-MM-DD 00:00:00',
                  type: 'time',
                  title: '积分变动时间',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
