import OrderDetail from '@/pages/admin/goods_order/Detail'
import OrderList from '@/pages/admin/goods_order/List'
import Index from '@/pages/admin/Index'
import IntegralMallCategory from '@/pages/admin/integralMall/Category'
import IntegralMallProductDetail from '@/pages/admin/integralMall/ProductDetail'
import Login from '@/pages/admin/Login'
import MasterAdd from '@/pages/admin/master/Add'
import MasterDetail from '@/pages/admin/master/Detail'
import MessageAddOrEdit from '@/pages/admin/message/AddOrEdit'
import MessageList from '@/pages/admin/message/List'
import { postCategoryNameMapId } from '@/services/posts'
import { BuffRoute } from '@/typings'
import ApplyList from '@pages/admin/apply/List'
import PostCategoryList from '@pages/admin/cms/category/List'
import AddOrEdit from '@pages/admin/cms/posts/AddOrEdit'
import PostList from '@pages/admin/cms/posts/List'
import ComplaintDetail from '@pages/admin/complaint/Detail'
import ComplaintList from '@pages/admin/complaint/List'
import HouseDetail from '@pages/admin/house/Detail'
import HouseList from '@pages/admin/house/HouseList'
import MangerList from '@pages/admin/house/List'
import IntegralMallAddOrEdit from '@pages/admin/integralMall/AddOrEdit'
import IntegralMallProduct from '@pages/admin/integralMall/ProductList'
import IntegralMallRule from '@pages/admin/integralMall/Rule'
import Base from '@pages/admin/master/BaseSettings'
import MasterList from '@pages/admin/master/List'
import Security from '@pages/admin/master/Security'
import NewsList from '@pages/admin/news/List'
import NoticeAddOrEdit from '@pages/admin/notice/AddOrEdit'
import NoticeList from '@pages/admin/notice/List'
import AddOperation from '@pages/admin/operation/Add'
import AddOperationGroup from '@pages/admin/operation/AddGroup'
import EditOperation from '@pages/admin/operation/Edit'
import EditOperationGroup from '@pages/admin/operation/EditGroup'
import OperationGroupList from '@pages/admin/operation/GroupList'
import OperationList from '@pages/admin/operation/List'
import PayDetail from '@pages/admin/order/Detail'
import PayList from '@pages/admin/order/List'
import NoticeRule from '@pages/admin/order/NoticeRule'
import RepairDetail from '@pages/admin/repair/Detail'
import RepairList from '@pages/admin/repair/List'
import UserDetail from '@pages/admin/user/Detail'
import UserList from '@pages/admin/user/List'
import UserAddOrEdit from '@/pages/admin/user/AddOrEdit'
import ScoreRecord from '@pages/admin/user/ScoreRecord'
import ConvenientServiceList from '@pages/admin/convenientService/List'
import ConvenientServiceAddOrEdit from '@pages/admin/convenientService/AddOrEdit'
import ParkingList from '@pages/admin/parking/List'
import ParkingDetail from '@pages/admin/parking/Detail'
import React from 'react'
import AdminAuth from './AdminAuth'
import pathMap from './pathMap'
export default {
  name: '后台首页',
  path: pathMap.admin.index,
  routes: [
    {
      name: '登陆页',
      path: pathMap.admin.login,
      exact: true,
      component: Login,
    },
    {
      name: '后台首页',
      path: pathMap.admin.index,
      routeComponent: AdminAuth,
      component: Index,
      routes: [
        // 操作权限相关
        {
          name: '操作首页',
          path: pathMap.admin.operation.index,
          routes: [
            {
              name: '操作列表',
              path: pathMap.admin.operation.index,
              exact: true,
              component: OperationList,
            },
            {
              name: '操作列表',
              path: pathMap.admin.operation.list,
              exact: true,
              component: OperationList,
            },
            {
              name: '添加操作',
              path: pathMap.admin.operation.add,
              exact: true,
              component: AddOperation,
            },
            {
              name: '编辑操作',
              path: pathMap.admin.operation.edit,
              exact: true,
              component: EditOperation,
            },
            {
              name: '添加操作组',
              path: pathMap.admin.operation.group.add,
              exact: true,
              component: AddOperationGroup,
            },
            {
              name: '编辑操作组',
              path: pathMap.admin.operation.group.edit,
              exact: true,
              component: EditOperationGroup,
            },
            {
              name: '操作组首页',
              path: pathMap.admin.operation.group.index,
              exact: true,
              component: OperationGroupList,
            },
            {
              name: '操作组列表',
              path: pathMap.admin.operation.group.index,
              exact: true,
              component: OperationGroupList,
            },
          ],
        },
        // 文章分类 -cms
        {
          name: '文章分类',
          path: pathMap.admin.post.category.index,
          routes: [
            {
              name: '文章分类首页',
              path: pathMap.admin.post.category.index,
              exact: true,
              component: PostCategoryList,
            },
            {
              name: '文章分类列表',
              path: pathMap.admin.post.category.list,
              exact: true,
              component: PostCategoryList,
            },
          ],
        },
        // 文章
        {
          name: '文章',
          path: pathMap.admin.post.index,
          routes: [
            {
              name: '文章首页',
              path: pathMap.admin.post.index,
              exact: true,
              component: PostList,
            },
            {
              name: '文章查看',
              path: pathMap.admin.post.look,
              exact: true,
              component: (props: any) => <AddOrEdit {...props} mode={'look'} />,
            },
            {
              name: '添加文章',
              path: pathMap.admin.post.add,
              exact: true,
              component: (props: any) => <AddOrEdit {...props} mode={'add'} />,
            },
            {
              name: '文章编辑',
              path: pathMap.admin.post.edit,
              exact: true,
              component: (props: any) => <AddOrEdit {...props} mode={'edit'} />,
            },
            {
              name: '文章列表',
              path: pathMap.admin.post.list,
              exact: true,
              component: PostList,
            },
          ],
        },
        // 用户
        {
          name: '用户相关',
          path: pathMap.admin.user.index,
          routes: [
            {
              name: '用户列表',
              path: pathMap.admin.user.list,
              exact: true,
              component: UserList,
            },
            {
              name: '添加管理员',
              path: pathMap.admin.user.add,
              exact: true,
              component: UserAddOrEdit,
            },
            
            {
              name: '编辑管理员',
              path: pathMap.admin.user.edit,
              exact: true,
              component: (props: any) => <UserAddOrEdit {...props} mode={'edit'} />,
            },
            {
              name: '用户详情',
              path: pathMap.admin.user.detail,
              exact: true,
              component: UserDetail,
            },
            {
              name: '积分变动记录',
              path: pathMap.admin.user.score,
              exact: true,
              component: ScoreRecord,
            },
            {
              name: '用户首页',
              path: pathMap.admin.user.index,
              exact: true,
              component: UserList,
            },
          ],
        },
        // 缴费记录
        {
          name: '缴费记录',
          path: pathMap.admin.pay.index,
          routes: [
            {
              name: '缴费记录列表',
              path: pathMap.admin.pay.list,
              exact: true,
              component: PayList,
            },
            {
              name: '缴费记录详情',
              path: pathMap.admin.pay.detail,
              exact: true,
              component: PayDetail,
            },
            {
              name: '用户首页',
              path: pathMap.admin.pay.index,
              exact: true,
              component: PayList,
            },
            {
              name: '缴费通知配置',
              path: pathMap.admin.pay.rule,
              exact: true,
              component: NoticeRule,
            },
          ],
        },
        // 申请管理
        {
          name: '申请管理',
          path: pathMap.admin.apply.index,
          routes: [
            {
              name: '申请列表',
              path: pathMap.admin.apply.list,
              exact: true,
              component: ApplyList,
            },
            {
              name: '申请',
              path: pathMap.admin.apply.index,
              exact: true,
              component: ApplyList,
            },
          ],
        },
        // 物业管理
        {
          name: '物业管理',
          path: pathMap.admin.manger.index,
          routes: [
            {
              name: '小区列表',
              path: pathMap.admin.manger.list,
              exact: true,
              component: MangerList,
            },
          ],
        },
        // 车位管理
        {
          name: '车位管理',
          path: pathMap.admin.parking.index,
          routes: [
            {
              name: '车位列表',
              path: pathMap.admin.parking.list,
              exact: true,
              component: ParkingList,
            },
            {
              name: '车位详情',
              path: pathMap.admin.parking.detail,
              exact: true,
              component: ParkingDetail,
            },
          ],
        },
        // 物业管理
        {
          name: '小区管理',
          path: pathMap.admin.house.index,
          routes: [
            {
              name: '小区房屋列表',
              path: pathMap.admin.house.list,
              exact: true,
              component: HouseList,
            },
            {
              name: '房屋详情',
              path: pathMap.admin.house.detail,
              exact: true,
              component: HouseDetail,
            },
          ],
        },
        // 资讯管理
        {
          name: '资讯管理',
          path: pathMap.admin.news.index,
          routes: [
            {
              name: '资讯列表',
              path: pathMap.admin.news.list,
              exact: true,
              component: NewsList,
            },
            {
              name: '资讯编辑',
              path: pathMap.admin.news.edit,
              exact: true,
              component: (props: any) => (
                <AddOrEdit {...props} type={'article'} mode={'edit'} categoryId={postCategoryNameMapId.news} />
              ),
            },
            {
              name: '添加资讯',
              path: pathMap.admin.news.add,
              exact: true,
              component: (props: any) => (
                <AddOrEdit {...props} mode={'add'} type={'article'} categoryId={postCategoryNameMapId.news} />
              ),
            },
          ],
        },
        // 公告管理
        {
          name: '公告管理',
          path: pathMap.admin.notice.index,
          routes: [
            {
              name: '公告列表',
              path: pathMap.admin.notice.list,
              exact: true,
              component: NoticeList,
            },
            {
              name: '公告编辑',
              path: pathMap.admin.notice.edit,
              exact: true,
              component: (props: any) => (
                <NoticeAddOrEdit {...props} mode={'edit'} categoryId={postCategoryNameMapId.notice} />
              ),
            },
            {
              name: '添加公告',
              path: pathMap.admin.notice.add,
              exact: true,
              component: (props: any) => (
                <NoticeAddOrEdit {...props} mode={'add'} type={'article'} categoryId={postCategoryNameMapId.notice} />
              ),
            },
          ],
        },
        // 积分商城
        {
          name: '积分商城',
          path: pathMap.admin.integralMall.index,
          routes: [
            {
              name: '积分规则',
              path: pathMap.admin.integralMall.rule,
              exact: true,
              component: IntegralMallRule,
            },
            {
              name: '商品分类',
              path: pathMap.admin.integralMall.category,
              exact: true,
              component: IntegralMallCategory,
            },
            {
              name: '添加商品',
              path: pathMap.admin.integralMall.add,
              exact: true,
              component: (props: any) => <IntegralMallAddOrEdit {...props} mode={'add'} />,
            },
            {
              name: '编辑商品',
              path: pathMap.admin.integralMall.edit,
              exact: true,
              component: (props: any) => <IntegralMallAddOrEdit {...props} mode={'edit'} />,
            },
            {
              name: '商品列表',
              path: pathMap.admin.integralMall.product,
              routes: [
                {
                  name: '商品列表',
                  path: pathMap.admin.integralMall.product,
                  exact: true,
                  component: IntegralMallProduct,
                },
                {
                  name: '商品列表',
                  path: pathMap.admin.integralMall.productListWithPage,
                  exact: true,
                  component: IntegralMallProduct,
                },
              ],
            },
            {
              name: '商品详情',
              path: pathMap.admin.integralMall.detail,
              exact: true,
              component: IntegralMallProductDetail,
            },
          ],
        },
        // 消息
        {
          name: '消息',
          path: pathMap.admin.message.index,
          routes: [
            {
              name: '消息列表',
              path: pathMap.admin.message.list,
              exact: true,
              component: MessageList,
            },
            {
              name: '编辑消息',
              path: pathMap.admin.message.edit,
              exact: true,
              component: (props: any) => <MessageAddOrEdit {...props} mode={'edit'} />,
            },
            {
              name: '发送消息',
              path: pathMap.admin.message.add,
              exact: true,
              component: (props: any) => <MessageAddOrEdit {...props} mode={'add'} />,
            },
          ],
        },
        // 商品订单
        {
          name: '商品订单',
          path: pathMap.admin.order.index,
          routes: [
            {
              name: '商品订单列表',
              path: pathMap.admin.order.list,
              exact: true,
              component: OrderList,
            },
            {
              name: '商品订单详情',
              path: pathMap.admin.order.detail,
              exact: true,
              component: OrderDetail,
            },
          ],
        },
        // 管理员
        {
          name: '管理员',
          path: pathMap.admin.master.index,
          routes: [
            {
              name: '管理员列表',
              path: pathMap.admin.master.list,
              exact: true,
              component: MasterList,
            },
            {
              name: '添加管理员',
              path: pathMap.admin.master.add,
              exact: true,
              component: MasterAdd,
            },
            {
              name: '管理员详情',
              path: pathMap.admin.master.detail.index,
              component: MasterDetail,
              routes: [
                {
                  name: '基本信息',
                  path: pathMap.admin.master.detail.base,
                  exact: true,
                  component: Base,
                },
                {
                  name: '安全信息',
                  path: pathMap.admin.master.detail.security,
                  exact: true,
                  component: Security,
                },
              ],
            },
          ],
        },
        // 报修
        {
          name: '报修管理',
          path: pathMap.admin.repair.index,
          routes: [
            {
              name: '报修列表',
              path: pathMap.admin.repair.list,
              exact: true,
              component: RepairList,
            },
            {
              name: '报修详情',
              path: pathMap.admin.repair.detail,
              exact: true,
              component: RepairDetail,
            },
            {
              name: '报修列表',
              path: pathMap.admin.repair.index,
              exact: true,
              component: RepairList,
            },
          ],
        },
        // 投诉
        {
          name: '投诉管理',
          path: pathMap.admin.complaint.index,
          routes: [
            {
              name: '投诉列表',
              path: pathMap.admin.complaint.list,
              exact: true,
              component: ComplaintList,
            },
            {
              name: '投诉详情',
              path: pathMap.admin.complaint.detail,
              exact: true,
              component: ComplaintDetail,
            },
            {
              name: '投诉列表',
              path: pathMap.admin.complaint.index,
              exact: true,
              component: ComplaintList,
            },
          ],
        },
        // 便民服务
        {
          name: '便民服务',
          path: pathMap.admin.convenientService.index,
          routes: [
            {
              name: '便民服务列表',
              path: pathMap.admin.convenientService.list,
              exact: true,
              component: ConvenientServiceList,
            },
            {
              name: '便民服务编辑',
              path: pathMap.admin.convenientService.edit,
              exact: true,
              component: (props: any) => <ConvenientServiceAddOrEdit {...props} mode={'edit'} />,
            },
            {
              name: '添加便民服务',
              path: pathMap.admin.convenientService.add,
              exact: true,
              component: (props: any) => <ConvenientServiceAddOrEdit {...props} mode={'add'} />,
            },
            {
              name: '便民服务列表',
              path: pathMap.admin.convenientService.index,
              exact: true,
              component: ConvenientServiceList,
            },
          ],
        },
        {
          name: '后台首页',
          path: pathMap.admin.index,
          exact: true,
          component: MangerList,
        },
      ],
    },
    {
      name: '后台首页',
      path: pathMap.admin.index,
      exact: true,
      redirect: pathMap.admin.login,
    },
  ],
} as BuffRoute
