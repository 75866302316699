import { bpost } from '@utils/jsbdk'
import { GetListParam } from './api'
import { Picture, DbBaseField } from '@/typings'
export type UserType = '业主' | '租户' | '家庭成员'
/**
 * 小区
 */
export interface Manger {
  id: string // 嘉隆那边的id 是012 这种格式
  name: string // 小区名
  houseCount: number // 房屋数量
  userCount: number // 用户数量
  pwd: string // 商城密码
}
/**
 * 房屋申请
 */
export interface HouseApply extends Omit<House, 'members' | 'applyMembers'> {
  uid: number // 申请人uid
  name: string // 申请人姓名
  phone: string // 申请人手机号码
  picList: Picture[]
}

/**
 * 房屋
 */
export interface House extends DbBaseField {
  houseId: string // 房屋id 这是嘉隆的id
  houseAddr: string // 房屋地址
  uid: number // uid
  mangerName: string
  phone: string
  type: UserType
  wxNick: string // 微信昵称
  wxAvatar: string //微信头像
  members: Omit<House, 'members' | 'applyMembers'>[]
  applyMembers: HouseApply[]
}
/**
 * 管理员house详情
 */
export type AdminHouse = Pick<House, 'houseId' | 'houseAddr' | 'mangerName'> & { userCount: number }
export type AdminHouseDetail = Pick<House, 'houseId' | 'houseAddr' | 'mangerName' | 'applyMembers' | 'members'>
/**
 * 获取申请列表
 */
export async function listApply(data: GetListParam) {
  return bpost<{ list: HouseApply[] }>({
    url: 'house/listApply',
    data,
  })
}
/**
 * 通过申请
 */
export async function passApply(data: Pick<HouseApply, 'id'>) {
  return bpost<{ list: HouseApply[] }>({
    url: 'house/passApply',
    data,
  })
}
/**
 * 拒绝申请
 */
export async function rejectApply(data: Pick<HouseApply, 'id'>) {
  return bpost({
    url: 'house/rejectApply',
    data,
  })
}

/**
 * 删除用户房产
 */
export async function delHouse(data: Pick<House, 'houseId'> & { uid: number }) {
  return bpost({
    url: 'house/delHouse',
    data,
  })
}
/**
 * 管理员获取小区列表
 */
export async function adminListManger(data: GetListParam) {
  return bpost<{
    list: Manger[]
  }>({
    url: 'house/adminListManger',
    data,
  })
}
/**
 * 管理员获取房屋列表
 * filter 中添加 mangerName eqString 小区名
 */
export async function adminListHouse(data: GetListParam) {
  return bpost<{
    list: AdminHouse[]
  }>({
    url: 'house/adminListHouse',
    data,
  })
}
/**
 * 管理员获取房屋详情
 * filter 中添加 mangerName eqString 小区名
 */
export async function adminGetHouse(data: Pick<House, 'houseId'>) {
  return bpost<{
    detail: AdminHouseDetail
  }>({
    url: 'house/adminGetHouse',
    data,
  })
}
export async function getPayNotifyConf() {
  return bpost<{
    limit: string
    start: string
  }>({
    url: 'house/getPayNotifyConf',
  })
}
export async function updatePayNotifyConf(data: { config: { limit: string; start: string } }) {
  return bpost({
    url: 'house/updatePayNotifyConf',
    data,
  })
}
/**
 * 更新小区商城管理员密码
 */
export async function updateMangerMasterPwd(data: { mangerId: string; pwd: string }) {
  return bpost({
    url: 'house/updateMangerMasterPwd',
    data,
  })
}
