import { applyMiddleware, combineReducers, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { PersistConfig, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import createSagaMiddleware from "redux-saga"
import adminReducer, { AdminState } from "../reducers/admin"
import appReducer, { State as ApplicationState } from "../reducers/app"

// import rootSaga from "../sagas/app"
const sagaMiddleware = createSagaMiddleware()
export interface AppState {
  app: ApplicationState
  admin: AdminState
}
const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
  whitelist: ["app"],
}
export default () => {
  let store = createStore(
    persistReducer(persistConfig, combineReducers({ app: appReducer, admin: adminReducer })),
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  )
  // sagaMiddleware.run(rootSaga)
  let persistor = persistStore(store)
  return { store, persistor }
}
