import { BASE_URL, withCredentials } from '@/config/prod'
import { getRegion } from '@api/api'
import { LocalStorage as storage } from '@utils/jsbdk'
import axios from 'axios'
export function iota(): () => number {
  let i = 1
  return () => i++
}
export function iotaStr(): () => string {
  let i = 1
  return () => i++ + ''
}

export function init() {
  axios.defaults.baseURL = BASE_URL
  axios.defaults.withCredentials = withCredentials
}
export const getFmtRegion = async () => {
  let json = await getRegion()
  const region = json.data.region
  for (let provinceItem of json.data.region) {
    if (provinceItem.children.length > 0) {
      let cityList = provinceItem.children
      if (provinceItem.value === undefined) {
        provinceItem.value = provinceItem.cid
        provinceItem.label = provinceItem.areaName
        delete provinceItem.cid
        delete provinceItem.areaName
        if (!(provinceItem.children.length > 0)) {
          delete provinceItem.children
        }
      }
      for (let cityItem of cityList) {
        if (cityItem.children.length > 0) {
          if (cityItem.value === undefined) {
            cityItem.value = cityItem.cid
            cityItem.label = cityItem.areaName
            delete cityItem.cid
            delete cityItem.areaName
            if (!(cityItem.children.length > 0)) {
              delete cityItem.children
            }
          }
          let countyList = cityItem.children
          for (let countyItem of countyList) {
            if (countyList.length > 0) {
              if (countyItem.value === undefined) {
                countyItem.value = countyItem.cid
                countyItem.label = countyItem.areaName
                delete countyItem.cid
                delete countyItem.areaName
                if (!(countyItem.children.length > 0)) {
                  delete countyItem.children
                }
              }
            }
          }
        }
      }
    }
  }
  if (!storage.has('region')) {
    storage.set('region', region, 0)
  }
  return region
}

/**
 * 获取图片的完整url
 */
export function getPicFullUrl(url: string): string {
  return url.indexOf('http') === 0 ? url : BASE_URL + url
}
