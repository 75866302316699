import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { PostsType, postsType as POSTS_TYPE } from '@api/posts'
import gSass from '@utils/sass'
import { Alert, Button, Form, Select, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import 'braft-editor/dist/index.css'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { updatePayNotifyConf, getPayNotifyConf } from '@/services/house'
const style = gSass.admin.integralMall.rule

interface Props {}
interface State {
  breadCrumbList: breadCrumbItem[]
}
const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 3,
      offset: 0,
    },
  },
}

// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class NoticeRule extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '缴费记录', to: pathMap.admin.pay.list },
        { name: '缴费通知配置' },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    const {
      data: { limit, start },
    } = await getPayNotifyConf()
    this.props.form.setFieldsValue({
      limit,
      start,
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          <Alert message="缴费通知配置" description="系统将会于设定时间发送微信通知给客户" type="info" showIcon />
          <div className={style.list}>
            <Form.Item {...textLayout} label="缴费期限">
              {getFieldDecorator('limit', {
                rules: [{ required: true, message: '请选择缴费期限' }],
              })(
                <Select placeholder="请选择" style={{ width: 200 }}>
                  <Select.Option value="每月">每月</Select.Option>
                  <Select.Option value="每季度">每季度</Select.Option>
                  <Select.Option value="每年">每年</Select.Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...textLayout} label="缴费时间">
              {getFieldDecorator('start', {
                rules: [{ required: true, message: '请选择缴费时间' }],
              })(
                <Select placeholder="请选择" style={{ width: 200 }}>
                  <Select.Option value="起始">起始</Select.Option>
                  <Select.Option value="结束">结束</Select.Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 12,
                },
              }}
            >
              <Button className={style.btn} type="primary" onClick={this.formSubmit}>
                确认编辑
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    )
  }
  formSubmit = () => {
    let validFields: string[] = ['limit', 'start']
    this.props.form.validateFields(validFields, async (err, fieldsValue) => {
      if (err) {
        return
      }
      const { limit, start } = fieldsValue
      updatePayNotifyConf({ config: { limit, start } })
        .then(() => {
          message.success('更新成功', 1)
        })
        .catch(err => {
          message.success(err.msg)
        })
    })
  }
}
