import { defaultLang } from '@/config/app'
import { Action } from '@/typings'
import { Lang } from '@/utils/locale'
import { Overwrite } from 'utility-types'
import * as appAction from '../actions/app'
export interface State {
  lang: Lang
}
const initState: State = {
  lang: defaultLang,
}
function setLanguage(state = initState, action: Overwrite<Action, { preload: Lang }>) {
  if (action.type === appAction.SET_LANGUAGE) {
    let newState = Object.assign({}, state)
    newState.lang = action.preload
    return newState
  }
  return state
}
export default function reducer(state = initState, action: Overwrite<Action, { preload: any }>) {
  switch (action.type) {
    case appAction.SET_LANGUAGE:
      return setLanguage(state, action)
    default:
      break
  }
  return state
}
