import postsCategoryApi, {
  AddCategoryData,
  del,
  EditCategoryData,
  PostsCategoryWithChild,
} from '@/services/postsCategory'
import { getPicFullUrl } from '@/utils/fn'
import { getUploadImgUrl } from '@api/api'
import gSass from '@utils/sass'
import { Button, Icon, Input, message, Modal, Popconfirm, Tree, Upload } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import csn from 'classnames'
import React, { Component } from 'react'
const style = gSass.admin.cms.category.list

const { TreeNode } = Tree
type Mode = 'common' | 'edit' | 'add'
interface Props {}

interface State {
  mode: Mode
  currCategoryName: string
  currCategoryId: number
  currCategoryPid: number
  currCategoryPicId: number
  currCategoryDescription: string
  categoryList: PostsCategoryWithChild[]
  categoryPicList: UploadFile[]
}
class List extends Component<Props & FormComponentProps, State> {
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      currCategoryId: 0,
      currCategoryPicId: 0,
      currCategoryPid: 0,
      currCategoryDescription: '',
      currCategoryName: '',
      mode: 'common',
      categoryList: [],
      categoryPicList: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    const {
      data: { list: categoryList },
    } = await postsCategoryApi.list({ page: 0, limit: 0 })
    this.setState({
      categoryList,
    })
  }
  findCategoryById = (id: number, categoryList: PostsCategoryWithChild[]): PostsCategoryWithChild | null => {
    for (let category of categoryList) {
      if (category.id === id) {
        return category
      }
      let res = this.findCategoryById(id, category.children || [])
      if (res !== null) {
        return res
      }
    }
    return null
  }
  renderTreeNodes = (data: PostsCategoryWithChild[]) => {
    const { setFieldsValue } = this.props.form
    return data.map(category => {
      return (
        <TreeNode
          className={style.treeNode}
          key={category.id + ''}
          title={
            <>
              <div className={csn(style.nodeItem, 'flex')}>
                <div className={'flex'} style={{ alignItems: 'center' }}>
                  {category.picture ? (
                    <img
                      style={{ width: 50, height: 50, margin: '0 10px' }}
                      src={getPicFullUrl(category.picture.url)}
                      alt={category.picture.title}
                    />
                  ) : null}
                  <div className={style.title}>{category.name} </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Button
                    type="ghost"
                    className={style.addChild}
                    onClick={() => {
                      let updateData: any = {
                        currCategoryId: category.id,
                        currCategoryPid: category.pid,
                        currCategoryName: category.name,
                        mode: 'edit',
                      }
                      if (category.picture) {
                        updateData.categoryPicList = [
                          {
                            picId: category.picture.id,
                            name: category.picture.title,
                            url: getPicFullUrl(category.picture.url),
                            uid: '0',
                            status: 'done',
                          },
                        ]
                      }
                      this.setState(updateData)
                      setFieldsValue({
                        name: category.name,
                        description: category.description,
                      })
                    }}
                  >
                    编辑
                  </Button>
                  <Button
                    className={style.addChild}
                    onClick={() => {
                      this.setState({
                        currCategoryPid: category.id,
                        currCategoryName: category.name,
                        mode: 'add',
                      })
                    }}
                  >
                    添加下级分类
                  </Button>
                  <Popconfirm
                    title={`确定要删除` + category.name + '分类吗?'}
                    onConfirm={() => this.delCategory([category.id])}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button type="danger" className={style.addChild}>
                      删除
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </>
          }
          dataRef={category}
        >
          {this.renderTreeNodes(category.children || [])}
        </TreeNode>
      )
    })
  }
  delCategory = async (ids: number[]) => {
    del(ids)
      .then(_ => {
        message.success('删除分类成功', 1, this.init)
      })
      .catch(e => {
        message.error('删除分类失败,错误信息: ' + e.msg)
      })
  }
  closeCategoryModal = () => {
    this.setState({
      mode: 'common',
    })
  }
  modalSubmit = () => {
    const { mode } = this.state
    mode === 'add' ? this.addCategory() : this.editCategory()
  }
  addCategory = () => {
    this.props.form.validateFields(['name', 'description'], (err, fields) => {
      if (err) {
        return
      }
      const { name, description } = fields
      let addCategoryData: AddCategoryData = {
        name,
        description,
        pid: this.state.currCategoryPid,
      }
      const { categoryPicList } = this.state
      if (categoryPicList.length > 0) {
        addCategoryData.picId = (categoryPicList[0] as UploadFile & { picId: number }).picId
      }
      postsCategoryApi
        .add(addCategoryData)
        .then(_ => {
          message.success('添加成功', 0.5, () => {
            this.props.form.resetFields()
            this.setState({
              categoryPicList: [],
              currCategoryName: '',
              currCategoryPid: 0,
              mode: 'common',
            })
            this.init()
            this.closeCategoryModal()
          })
        })
        .catch(err => {
          console.log(err)
          this.closeCategoryModal()
          message.error('添加失败, 失败原因: ' + err.msg || err, 3)
        })
    })
  }
  editCategory = () => {
    this.props.form.validateFields(['name', 'description'], (err, fields) => {
      if (err) {
        return
      }
      const { name, description } = fields
      const { currCategoryId: id } = this.state
      let editCategoryData: EditCategoryData = {
        id,
        name,
        description,
        pid: this.state.currCategoryPid,
      }
      const { categoryPicList } = this.state
      if (categoryPicList.length > 0) {
        editCategoryData.picId = (categoryPicList[0] as UploadFile & { picId: number }).picId
      } else {
        editCategoryData.picId = 0
      }
      postsCategoryApi
        .edit(editCategoryData)
        .then(_ => {
          message.success('编辑成功', 0.5, () => {
            this.props.form.resetFields()
            this.setState({
              categoryPicList: [],
              currCategoryName: '',
              currCategoryPid: 0,
              currCategoryId: 0,
              currCategoryPicId: 0,
              currCategoryDescription: '',
              mode: 'common',
            })
            this.init()
            this.closeCategoryModal()
          })
        })
        .catch(err => {
          console.log(err)
          this.closeCategoryModal()
          message.error('编辑失败, 失败原因: ' + err.msg || err, 3)
        })
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const { categoryPicList, mode, currCategoryName, currCategoryPid, currCategoryId } = this.state
    let currEditCategoryParentName = ''
    if (mode === 'edit') {
      let parentCategory = this.findCategoryById(currCategoryPid, this.state.categoryList)
      currEditCategoryParentName = parentCategory ? parentCategory.name : '顶级'
    }
    return (
      <div className={csn(style.categoryBox, 'flex')}>
        <Tree blockNode className={style.treeBox}>
          {this.renderTreeNodes(this.state.categoryList)}
        </Tree>
        <Button
          className={style.addCategoryBtn}
          onClick={() => {
            this.setState({
              currCategoryName: '顶级',
              currCategoryPid: 0,
              mode: 'add',
            })
          }}
        >
          添加顶级分类
        </Button>
        <Modal
          title={
            mode === 'add'
              ? '为"' + currCategoryName + '"添加子分类'
              : mode === 'edit'
              ? `编辑 ${currEditCategoryParentName} -> ${currCategoryName} 分类`
              : ''
          }
          visible={mode !== 'common'}
          onOk={this.modalSubmit}
          onCancel={this.closeCategoryModal}
        >
          <Form>
            {mode === 'edit' ? (
              <Form.Item label="分类id" labelCol={{ span: 3, offset: 3 }} wrapperCol={{ span: 14, offset: 1 }}>
                {getFieldDecorator('id', {
                  rules: [{ required: true, message: '请输入分类id' }],
                  initialValue: currCategoryId,
                })(<Input type="number" autoFocus size="large" placeholder="请输入" disabled />)}
              </Form.Item>
            ) : null}
            <Form.Item label="分类名" labelCol={{ span: 3, offset: 3 }} wrapperCol={{ span: 14, offset: 1 }}>
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: '请输入分类名' },
                  { min: 2, max: 10, message: '分类名长度为2-10' },
                ],
              })(<Input type="text" autoFocus size="large" placeholder="请输入" />)}
            </Form.Item>
            <Form.Item label="分类图片" labelCol={{ span: 4, offset: 2 }} wrapperCol={{ span: 14, offset: 1 }}>
              {getFieldDecorator(
                'pic',
                {},
              )(
                <Upload
                  action={getUploadImgUrl()}
                  listType="picture-card"
                  fileList={categoryPicList}
                  onChange={this.onCategoryPicChange}
                  accept="image/*"
                  name="file"
                  data={{ name: 'file' }}
                >
                  {categoryPicList.length > 0 ? null : (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">上传图片</div>
                    </div>
                  )}
                </Upload>,
              )}
            </Form.Item>
            <Form.Item label="分类描述" labelCol={{ span: 3, offset: 3 }} wrapperCol={{ span: 14, offset: 1 }}>
              {getFieldDecorator('description', {
                rules: [{ max: 500, message: '分类描述最多500个字' }],
              })(<TextArea rows={5} maxLength={500} placeholder="请输入" onPressEnter={this.modalSubmit} />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
  onCategoryPicChange = ({ file, fileList: categoryPicList }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code === 0) {
          file.url = getPicFullUrl(json.data.url)
          ;(categoryPicList[0] as UploadFile & { picId: number }).picId = json.data.picId
        } else {
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    } else if (file.status === 'removed') {
      categoryPicList = []
    }
    this.setState({
      categoryPicList,
    })
  }
}
export default Form.create()(List)
