import { BASE_URL, SESSION_EXPIRE, tokenExpire } from '@/config/prod'
import * as adminAction from '@/redux/actions/admin'
import pathMap from '@/routes/pathMap'
import { adminLogin } from '@api/user'
import { AppState } from '@redux/stores/app'
import gImg from '@utils/img'
import { LocalStorage as storage } from '@utils/jsbdk'
import gSass from '@utils/sass'
import { Button, Checkbox, Col, Form, Icon, Input, message, Row, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import uuid from 'uuid/v4'
import csn from 'classnames'
const style = gSass.admin.common.login
interface State {
  accountLoginVerifyCodePicUrl: string
  codeKey: string
}

const mapStateToProps = (state: AppState) => {
  return {
    isLogin: state.admin.isLogin,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: (preload: adminAction.AdminLoginPreload) => {
      dispatch(adminAction.login(preload))
    },
    logout: () => {
      dispatch(adminAction.logout())
    },
  }
}
class Login extends Component<
  RouteComponentProps & FormComponentProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>,
  State
> {
  changeVerifyCodeTimer: NodeJS.Timeout | null = null
  constructor(props: any) {
    super(props)
    const codeKey = uuid()
    this.state = {
      codeKey,
      accountLoginVerifyCodePicUrl: BASE_URL + '/getVerifyCode?codeKey=' + codeKey,
    }
  }
  componentDidMount() {
    this.init()
    this.changeVerifyCodeTimer = setInterval(this.refreshAccountLoginVerifyCode, 15 * 60 * 1000)
  }
  init = async () => {}
  componentWillUnmount() {
    if (this.changeVerifyCodeTimer) {
      clearInterval(this.changeVerifyCodeTimer)
    }
  }
  //登录
  login = (evt: React.FormEvent<HTMLElement>) => {
    evt && evt.preventDefault()
    let fieldNames = ['account', 'pwd', 'code']
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err !== null) {
        return
      }
      const { codeKey } = this.state
      const { account, pwd, code } = fieldsValue
      adminLogin({
        codeKey,
        account,
        pwd,
        code,
      })
        .then(json => {
          message.success('登录成功', 1, () => {
            storage.set('adminIsLogin', true, SESSION_EXPIRE)
            storage.set('uid', json.data.detail.id, SESSION_EXPIRE)
            storage.set('nick', json.data.detail.nick, SESSION_EXPIRE)
            storage.set('token', json.data.token, tokenExpire)
            this.props.login({
              uid: json.data.detail.id,
              nick: json.data.detail.nick,
            })
          })
        })
        .catch(err => {
          message.error('登录失败,失败原因: ' + err.msg)
          console.log(err)
          this.refreshAccountLoginVerifyCode()
        })
    })
  }

  refreshAccountLoginVerifyCode = () => {
    const codeKey = uuid()
    this.setState({
      codeKey,
      accountLoginVerifyCodePicUrl: BASE_URL + '/getVerifyCode?codeKey=' + codeKey,
    })
  }
  render() {
    if (this.props.isLogin) {
      return <Redirect to={pathMap.admin.index} />
    }
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 18, offset: 2 },
        md: { span: 12, offset: 6 },
        lg: { span: 4, offset: 10 },
      },
    }

    return (
      <div className={style.login}>
        <div className={style.loginBox}>
          <div className={style.logo}>
            <img src={`${gImg.login.loginLogo}`} alt="logo" className={style.logoImg} />
          </div>
          <Form className={style.form}>
            <Form.Item {...formItemLayout}>
              {getFieldDecorator('account', {
                rules: [
                  {
                    min: 2,
                    max: 32,
                    required: true,
                    message: '请输入2-32的账号',
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="请输入账号"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}>
              {getFieldDecorator('pwd', {
                rules: [
                  {
                    min: 4,
                    max: 32,
                    required: true,
                    message: '请输入4-32位密码',
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="请输入密码"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}>
              {getFieldDecorator('code', {
                rules: [
                  {
                    min: 4,
                    max: 32,
                    required: true,
                    message: '请输入验证码',
                  },
                ],
              })(
                <div className={style.verificationCodePic}>
                  <Input
                    size="large"
                    className={style.input}
                    onPressEnter={this.login}
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="请输入验证码"
                  />
                  <Tooltip title="点击刷新">
                    <img
                      src={this.state.accountLoginVerifyCodePicUrl}
                      onClick={this.refreshAccountLoginVerifyCode}
                      className={style.img}
                      alt="点击刷新"
                    />
                  </Tooltip>
                </div>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}>
              {getFieldDecorator(
                'rememberAccountPwd',
                {},
              )(
                <div className={style.autoLogin}>
                  <Checkbox className={style.autoLoginTitle}>
                    自动登录
                    <Tooltip title="3天免登录">
                      <Icon type="info-circle" className={style.tooltip} />
                    </Tooltip>
                  </Checkbox>
                </div>,
              )}
            </Form.Item>
          </Form>
          <div className={style.submit}>
            <Row>
              <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }} lg={{ span: 4, offset: 10 }}>
                <Button size="large" className={style.btn} type="primary" onClick={this.login}>
                  登录
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className={csn(style.footer, 'flex a-center j-center')}>
          <img alt="" className={style.img} src={gImg.login.keepOnRecord}></img>
          <a target="blank" href="http://beian.gov.cn/portal/recordQuery?token=e5427370-3f8d-46e2-ac99-3d95af56fe16">
            沪公网安备 31011402004935号 沪ICP备16036182号-3
          </a>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login))
