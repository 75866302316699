import localeName from "@utils/localeName"
export default {
  [localeName.app.公司名]: "Aohua",
  [localeName.app.集团名]: "Aohua Group",
  [localeName.app.成立时间信息]: "Established time {time}",
  [localeName.app.绿色使命]: "Green Mission",
  [localeName.app.公司简介]:
    "Founded in 1998, AOC is an international high-tech enterprise focusing on high-end feed research and development." +
    "Production and marketing. At the same time, we provide Indian industry with animal nutrition matching, aquaculture environment and other services. Purification and aquaculture models and other comprehensive solutions.",
  [localeName.app.common.导航.我们的产品]: "Product",
  [localeName.app.common.导航.我们的使命]: "Mission",
  [localeName.app.common.导航.我们的新闻]: "News",
  [localeName.app.common.导航.我们的研发]: "Research",
  [localeName.app.common.导航.投资者关系]: "Investor Relations",
  [localeName.app.common.导航.走进我们]: "About",
}
