import Page404 from '@/pages/common/Page404'
import { BuffRoute } from '@/typings'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
export function FmtRoute({ routes }: { routes: BuffRoute[] }) {
  return (
    <Switch>
      {routes.map((route, k) => {
        if (route.redirect) {
          return <Redirect key={k} path={route.path as string} to={route.redirect} />
        }
        const ChooseRoute = route.routeComponent ? route.routeComponent : Route
        const Component = route.component
          ? route.component
          : () => {
              return <FmtRoute routes={route.routes || []} />
            }
        return (
          <ChooseRoute
            key={k}
            path={route.path}
            exact={route.exact}
            sensitive
            component={(props: any) => <Component {...props} routes={route.routes} />}
          />
        )
      })}
      {/* 这种方式打开直接就是404内容,但是url不变 */}
      <Route component={Page404} />
      {/* 这种方式就是直接跳转到/404 */}
      {/* <Redirect to={pathMap[404]} /> */}
    </Switch>
  )
}
