import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { AdminSceneOrder, detail as getOrder, statusZh, typeZh } from '@/services/order'
import gSass from '@utils/sass'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { sexMap, Sex } from '@/services/wxUser'
const style = gSass.admin.user.detail
interface Props {}
interface State {
  id: number
  breadCrumbList: breadCrumbItem[]
  detail: AdminSceneOrder | null
}
type DefaultProps = RouteComponentProps<{ id: string }> & FormComponentProps

// @ts-ignore
@Form.create()
export default class Detail extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id) || 0
    return {
      id,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '缴费记录管理', to: pathMap.admin.user.index },
        { name: '缴费记录详情' },
      ],
      detail: null,
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
      let { id } = this.state
      let {
        data: { detail },
      } = await getOrder({ id })
      this.setState({ detail })
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    let { detail } = this.state
    if (!detail) {
      return <div></div>
    }
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          <div className={style.list}>
            <div className={style.item}>
              <div className={style.title}>缴费记录</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>流水号</div>
                  <div className={style.detail}>{detail.outTradeNo}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>房屋ID</div>
                  <div className={style.detail}>{detail.houseId}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>缴费详情</div>
                  <div className={style.detail}>{detail.title}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>缴费类别</div>
                  <div className={style.detail}>{typeZh[detail.type]}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>缴费状态</div>
                  <div className={style.detail}>{statusZh[detail.status]}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>缴费周期</div>
                  <div className={style.detail}>
                    {detail.extra.begin} 至 {detail.extra.end}
                  </div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>缴费金额</div>
                  <div className={style.detail}>{detail.totalFee/100}元</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>缴费时间</div>
                  <div className={style.detail}>{detail.ctime}</div>
                </div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>用户信息</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>ID</div>
                  <div className={style.detail}>{detail.id}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>昵称</div>
                  <Link
                    style={{ color: '#0089ff' }}
                    to={pathMap.admin.user.detail + '?id=' + detail.uid}
                    className={style.detail}
                  >
                    {detail.user.nick || '未命名'}
                  </Link>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>头像</div>
                  <div className={style.detail}>
                    <img alt="" style={{ width: 90 }} src={detail.user.wxInfo.headImgUrl} />
                  </div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>openid</div>
                  <div className={style.detail}>{detail.user.wxInfo.openid}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>联系方式</div>
                  <div className={style.detail}>{detail.user.phone}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>性别</div>
                  <div className={style.detail}>{sexMap[detail.user.wxInfo.sex as Sex]}</div>
                </div>
                {/* <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>地址</div>
                  <div className={style.detail}>
                    {detail.user.wxInfo.country}/{detail.user.wxInfo.province}/{detail.user.wxInfo.city}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
