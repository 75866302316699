import localeName from "@utils/localeName"
export default {
  [localeName.app.公司名]: "澳华",
  [localeName.app.集团名]: "澳华集团",
  [localeName.app.成立时间信息]: "成立时间{time}",
  [localeName.app.绿色使命]: "绿色使命",
  [localeName.app.公司简介]:
    "澳华公司成立于1998年，是一家专注于高端饲料研发的国际性高科技企业。 生产和销售。同时，我们为印度工业提供动物营养匹配、水产养殖环境等服务。 净化和水产养殖模型及其他综合解决方案。",
  [localeName.app.common.导航.我们的产品]: "我们的产品",
  [localeName.app.common.导航.我们的使命]: "我们的使命",
  [localeName.app.common.导航.我们的新闻]: "我们的新闻",
  [localeName.app.common.导航.我们的研发]: "我们的研发",
  [localeName.app.common.导航.投资者关系]: "投资者关系",
  [localeName.app.common.导航.走进我们]: "走进我们",
}
