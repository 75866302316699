import pathMap from '@/routes/pathMap'
import { addOperationGroup, CommonOperation, listGroup, listOperation } from '@api/operation'
import { OriOperationGroup } from '@api/operationGroup'
import { gSass } from '@utils/global'
import { Breadcrumb, Button, Checkbox, Form, Input, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import _ from 'lodash'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
type OperationGroup = OriOperationGroup & {
  indeterminate: boolean
  checked: boolean
  extend_operation_list: Operation[]
  operationList: Operation[]
}
type Operation = CommonOperation & { checked: boolean }
const style = gSass.admin.operation.addGroup
const { TextArea } = Input
const formItemLayout = {
  labelCol: {
    sm: {
      span: 2,
      offset: 0,
    },
  },
  wrapperCol: {
    sm: {
      span: 8,
      offset: 0,
    },
  },
}

interface Props {}
interface State {
  operationGroupList: OperationGroup[]
  operationList: Operation[]
  operationChildGroupList: number[]
}

type DefaultProps = FormComponentProps & RouteComponentProps

export class AddOperationApiGroup extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps

  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      operationGroupList: [],
      operationList: [],
      operationChildGroupList: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    let operationGroupListPromise = listGroup({ page: 0, limit: 0 }),
      operationListPromise = listOperation({ page: 0, limit: 0 }),
      dbOperationGroupList = (await operationGroupListPromise).data.list,
      dbOperationList = (await operationListPromise).data.list

    let idMapOperationGroup = new Map(),
      idMapOperation = new Map(),
      operationList: Operation[] = [],
      operationGroupList: OperationGroup[] = []
    for (let operationGroupItem of dbOperationGroupList) {
      operationGroupList.push({
        ...operationGroupItem,
        checked: false,
        indeterminate: false,
        extend_operation_list: [],
        operationList: [],
      })
      idMapOperationGroup.set(operationGroupItem.id, operationGroupList[operationGroupList.length - 1])
    }
    //获取操作组的所有继承操作
    let getOperationGroupExtendOperationList = (operationGroupItem: OperationGroup): any => {
      let operationExtendList = []
      let childGroupIdArr = operationGroupItem.operation_group_list
      for (let groupId of childGroupIdArr) {
        let childOperationGroupItem = _.clone(idMapOperationGroup.get(groupId))
        if (childOperationGroupItem) {
          operationExtendList.push(getOperationGroupExtendOperationList(childOperationGroupItem))
          operationExtendList.push(childOperationGroupItem.operation_list)
        }
      }
      return operationExtendList
    }

    for (let operationItem of dbOperationList) {
      operationList.push({
        ...operationItem,
        checked: false,
      })
      idMapOperation.set(operationItem.id, operationList[operationList.length - 1])
    }
    for (let operationGroupItem of operationGroupList) {
      operationGroupItem.extend_operation_list = getOperationGroupExtendOperationList(operationGroupItem)
      for (let i = 0; i < operationGroupItem.operation_list.length; i++) {
        operationGroupItem.operationList[i] = _.clone(idMapOperation.get(operationGroupItem.operation_list[i]))
      }
    }
    for (let operationGroupItem of operationGroupList) {
      for (let operationItem of operationGroupItem.extend_operation_list) {
        operationList = operationList.filter(v => v.id !== operationItem.id)
      }
      for (let operationItem of operationGroupItem.operationList) {
        operationList = operationList.filter(v => v.id !== operationItem.id)
      }
    }
    this.setState({
      operationGroupList,
      operationList,
    })
  }
  //提交
  addOperationGroup = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.preventDefault()
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return
      }

      let operationGroupList: number[] = [],
        operationList: number[] = []
      //获取所有的操作组
      for (let v of this.state.operationGroupList) {
        if (v.checked) {
          operationGroupList.push(v.id)
        } else {
          for (let v2 of v.extend_operation_list) {
            if (v2.checked) {
              operationList = operationList.filter(val => {
                return val !== v2.id
              })
              operationList.push(v2.id)
            }
          }
          for (let v3 of v.operationList) {
            if (v3.checked) {
              operationList = operationList.filter(val => {
                return val !== v3.id
              })
              operationList.push(v3.id)
            }
          }
        }
      }
      //获取所有的操作方法
      for (let operationItem of this.state.operationList) {
        if (operationItem.checked) {
          operationList = operationList.filter(val => val !== operationItem.id)
          operationList.push(operationItem.id)
        }
      }
      let data = {
        name: fieldsValue.name,
        operationIdList: Array.from(new Set(operationList)),
        operationGroupIdList: Array.from(new Set(operationGroupList)),
        description: fieldsValue.description,
      }
      addOperationGroup(data)
        .then(() => {
          message.success('添加成功', 2, () => this.props.history.push(pathMap.admin.operation.group.index))
        })
        .catch(err => {
          message.error('添加失败,失败原因: ' + err.msg, 3)
          console.log(err)
        })
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div className={style.permission}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to={pathMap.admin.index}>首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={pathMap.admin.operation.group.index}>权限管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>添加操作组</Breadcrumb.Item>
        </Breadcrumb>
        <Form className={style.form}>
          <Form.Item {...formItemLayout} label="操作名">
            {getFieldDecorator('name', {
              rules: [{ min: 2, max: 32, required: true, message: '请输入2-32的操作名' }],
            })(<Input size="large" placeholder="请输入操作名" />)}
          </Form.Item>
          <Form.Item
            labelCol={{
              sm: {
                span: 2,
                offset: 0,
              },
            }}
            wrapperCol={{
              sm: {
                span: 20,
                offset: 0,
              },
            }}
            label="子操作组列表"
          >
            {this.state.operationGroupList.map((v, k) => {
              return (
                <div className={style.operationGroupItem} key={k}>
                  <div className={style.ogiTitle}>
                    <Checkbox
                      indeterminate={v.indeterminate}
                      onChange={_ => {
                        let operationGroupList = this.state.operationGroupList
                        operationGroupList[k].checked = !v.checked
                        if (operationGroupList[k].checked) {
                          operationGroupList[k].indeterminate = false
                          let operationChildGroupList = this.state.operationChildGroupList
                          operationChildGroupList.push(v.id)
                          this.setState({
                            operationChildGroupList,
                          })
                        } else {
                          let id = v.id,
                            operationChildGroupList
                          operationChildGroupList = this.state.operationChildGroupList.filter(val => {
                            return val !== id
                          })
                          this.setState({
                            operationChildGroupList,
                          })
                        }
                        for (let val of operationGroupList[k].extend_operation_list) {
                          val.checked = operationGroupList[k].checked
                        }
                        for (let val of operationGroupList[k].operationList) {
                          val.checked = operationGroupList[k].checked
                        }
                        this.setState({
                          operationGroupList,
                        })
                      }}
                      checked={v.checked}
                    >
                      {v.name}
                    </Checkbox>
                  </div>
                  <div className={style.operationList}>
                    {v.extend_operation_list.map((v2, k2) => {
                      return (
                        <Checkbox
                          key={`0-${k}-${k2}`}
                          onChange={_ => {
                            let operationGroupList = this.state.operationGroupList
                            operationGroupList[k].extend_operation_list[k2].checked = !v2.checked
                            let groupIndeterminate = false,
                              groupAllchecked = true
                            for (let val of operationGroupList[k].extend_operation_list) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            for (let val of operationGroupList[k].operationList) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            operationGroupList[k].checked = groupAllchecked
                            operationGroupList[k].indeterminate = groupAllchecked ? false : groupIndeterminate
                            this.setState({
                              operationGroupList,
                            })
                          }}
                          checked={v2.checked}
                        >
                          {v2.name}
                        </Checkbox>
                      )
                    })}
                    {v.operationList.map((v2, k2) => {
                      return (
                        <Checkbox
                          key={`1-${k}-${k2}`}
                          onChange={_ => {
                            let operationGroupList = this.state.operationGroupList
                            operationGroupList[k].operationList[k2].checked = !v2.checked
                            let groupIndeterminate = false,
                              groupAllchecked = true
                            for (let val of operationGroupList[k].extend_operation_list) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            for (let val of operationGroupList[k].operationList) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            operationGroupList[k].checked = groupAllchecked
                            operationGroupList[k].indeterminate = groupAllchecked ? false : groupIndeterminate
                            this.setState({
                              operationGroupList,
                            })
                          }}
                          checked={v2.checked}
                        >
                          {v2.name}
                        </Checkbox>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </Form.Item>
          <Form.Item
            labelCol={{
              sm: {
                span: 2,
                offset: 0,
              },
            }}
            wrapperCol={{
              sm: {
                span: 20,
                offset: 0,
              },
            }}
            label="操作方法列表"
          >
            {
              <div className={style.operationMethodList}>
                {this.state.operationList.map((v, k) => {
                  return (
                    <div className={style.operationMethodItem} key={k}>
                      <Checkbox
                        key={`0-${k}-${k}`}
                        onChange={_ => {
                          let operationList = this.state.operationList
                          operationList[k].checked = !v.checked
                          this.setState({
                            operationList,
                          })
                        }}
                        checked={v.checked}
                      >
                        {v.name}
                      </Checkbox>
                    </div>
                  )
                })}
              </div>
            }
          </Form.Item>
          <Form.Item {...formItemLayout} label="操作组描述">
            {getFieldDecorator('description', {})(<TextArea rows={4} placeholder="请输入操作描述" />)}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 7,
                offset: 2,
              },
            }}
          >
            <Button size="large" className={style.btn} type="primary" onClick={this.addOperationGroup}>
              添加
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const WrappedLogin = Form.create()(AddOperationApiGroup)

export default WrappedLogin
