import pathMap from '@/routes/pathMap'
import { Picture } from '@/typings'
import { conditionType } from '@/utils/constant'
import { getPicFullUrl } from '@/utils/fn'
import { ListFilter } from '@/utils/jsbdk'
import { adminListCommonUser, deleteUsers, UserType, userType } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form, message, Popconfirm } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import React, { Component } from 'react'
import { Link, RouteProps } from 'react-router-dom'
const tableStyle = gSass.common.table
interface Props {}

interface State {
  breadCrumbList: breadCrumbItem[]
  userList: UserRecord[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: UserRecord[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
}
interface UserRecord {
  id: number
  type: UserType
  account: string
}

class UserList extends Component<Props & FormComponentProps & RouteProps, State> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '账号',
      dataIndex: 'account',
    },
    {
      title: '用户名',
      dataIndex: 'nick',
    },
    {
      title: '头像',
      dataIndex: 'avatar',
      render: (avatar: Picture) => {
        return (
          <img
            className={tableStyle.avatar}
            src={avatar ? getPicFullUrl(avatar.url) : this.state.defaultAvatar}
            alt="头像"
          />
        )
      },
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
    },
    {
      title: '绑定小区',
      dataIndex: 'mangerIdList',
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      render: (text: string) => {
        return <div>{text.substr(0, 10)}</div>
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 300,
      render: (_: string, record: UserRecord) =>
        this.state.userList.length >= 1 ? (
          <div className={'flex '}>
            <Link
              className={tableStyle.btn}
              to={pathMap.admin.user.edit + '?id=' + record.id}
            >
              编辑
            </Link>
            <div className="ant-divider ant-divider-vertical" style={{ top: 4 }} />
            <span className={tableStyle.btn}>
              <Popconfirm
                title={<div>你确定要删除用户{record.account}吗?</div>}
                onConfirm={() => this.deleteUser([record.id])}
              >
                <span style={{ color: '#1890ff' }}>删除</span>
              </Popconfirm>
            </span>
          </div>
        ) : null,
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    return {
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '管理员管理', to: pathMap.admin.master.index },
        { name: '管理员列表' },
      ],
      userList: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
        type: {
          condition: conditionType.eqString,
          val: userType.admin,
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }
  deleteUser = (idArr: number[]) => {
    console.log(idArr)
    const list = this.state.userList,
      selectedRows = this.state.selectedRows
    deleteUsers({ idArr })
      .then(() => {
        message.success("删除成功", 1)
        this.setState({
          userList: list.filter(item => !idArr.includes(item.id)),
          selectedRows: selectedRows.filter(item => !idArr.includes(item.id)),
        })
      })
      .catch(err => {
        message.error("删除失败,错误原因: " + err.msg, 3)
      })
  }

  listUser = async () => {
    const { pagination } = this.state
    try {
      // 注释掉的东西不能删
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
        filter.type = {
          condition: conditionType.eqString,
          val: 1,
        }
      this.setState({
        tableIsLoading: true,
      })
      let json = await adminListCommonUser({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        userList: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '新增',
        to: pathMap.admin.user.add,
        btnProps: {
          type: 'primary',
          icon: 'plus',
        },
      },
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '删除',
              onClick: () => {
                let idArr = []
                for (let v of this.state.selectedRows) {
                  idArr.push(v.id)
                }
                if (idArr.length < 1) {
                  message.error('请选择要删除的管理员', 2)
                  return
                }
                this.deleteUser(idArr)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
    ]
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入姓名|手机号|身份证号码...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.userList,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'account',
                  conditionFieldName: 'accountCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '账号',
                },
                {
                  valFieldName: 'nick',
                  conditionFieldName: 'nickCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '昵称',
                },
                {
                  valFieldName: 'phone',
                  conditionFieldName: 'phoneCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '手机号码',
                  decoratorOptions: {},
                },
                // {
                //   valFieldName: 'email',
                //   conditionFieldName: 'emailCondition',
                //   type: 'value',
                //   valueType: 'string',
                //   title: '邮箱',
                // },
                {
                  valFieldName: 'registerTime',
                  conditionFieldName: 'registerTimeCondition',
                  dateFmt: 'YYYY-MM-DD',
                  outDateFmt: 'YYYY-MM-DD 00:00:00',
                  type: 'time',
                  title: '创建时间',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
}
export default Form.create()(UserList)
