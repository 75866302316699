import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { detail, Goods } from '@/services/goods'
import gSass from '@utils/sass'
import { Button, Form, Modal, Table, Popconfirm, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GoodsComment } from '@/services/goodsComment'
import { del } from '@/services/goodsCategory'
import { getPicFullUrl } from '@/utils/fn'
const style = gSass.admin.integralMall.detail
interface Props {}
interface State {
  id: number
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  breadCrumbList: breadCrumbItem[]
  detail: Goods | null
}
type DefaultProps = RouteComponentProps<{ id: string }> & FormComponentProps

// @ts-ignore
@Form.create()
export default class ProductDetail extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'UID',
      dataIndex: 'uid',
    },
    {
      title: '商品ID',
      dataIndex: 'goodsId',
    },
    {
      title: '用户昵称',
      dataIndex: 'nick',
    },
    {
      title: '评论内容',
      dataIndex: 'content',
      render: (content: string) => {
        return (
          <div>
            <div>{content.length > 10 ? content.substr(1, 10) + '...' : content}</div>
            <Button
              type="primary"
              onClick={() => {
                Modal.confirm({
                  maskClosable: true,
                  title: '评论内容',
                  content: <div dangerouslySetInnerHTML={{ __html: content }}></div>,
                  okText: '确认',
                  cancelText: '',
                })
              }}
            >
              查看
            </Button>
          </div>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: GoodsComment) => {
        return (
          <div>
            <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
              <div style={{ cursor: 'pointer' }}>
                <Popconfirm
                  title={<div>你确定要删除[评论]{record.id}吗?</div>}
                  onConfirm={() => this.delComments([record.id])}
                >
                  <span>删除</span>
                </Popconfirm>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id) || 0
    return {
      id,
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '积分商品', to: pathMap.admin.integralMall.product },
        { name: '商品详情' },
      ],
      detail: null,
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
      let { id } = this.state
      let {
        data: { detail: dataDetail },
      } = await detail({ id })
      this.setState({ detail: dataDetail })
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    let { detail } = this.state
    if (!detail) {
      return <div></div>
    }
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          <div className={style.list}>
            <div className={style.item} style={{ borderBottom: 'none' }}>
              <div className={style.title}>商品信息</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>ID</div>
                  <div className={style.detail}>{detail.categoryId}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>商品分类</div>
                  <div className={style.detail}>
                    {detail.category.name} (商品分类ID:{detail.category.id})
                  </div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>商品名称</div>
                  <div className={style.detail}>{detail.name}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>库存</div>
                  <div className={style.detail}>{detail.stock}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>销量</div>
                  <div className={style.detail}>{detail.buyCount}</div>
                </div>
              </div>
            </div>
            <div className={style.item} style={{ paddingBottom: 15 }}>
              <div className={style.title} style={{ marginBottom: 10 }}>
                价格信息
              </div>
              <div className={csn(style.detail, 'flex a-center wrap')}>
                <div className={csn(style.priceList, 'flex a-center wrap')}>
                  {detail.priceInfo.map((item, idx) => {
                    return (
                      <div className={style.priceItem} key={'price-info-' + idx}>
                        <div className={csn(style.labelPar, 'flex')}>
                          <div className={style.label}>规格 </div>
                          <div className={style.desc}>{item.standard}</div>
                        </div>
                        <div className={csn(style.labelPar, 'flex ')}>
                          <div className={style.label}>所需积分 </div>
                          <div className={style.desc}>{item.score}</div>
                        </div>
                        <div className={csn(style.labelPar, 'flex')}>
                          <div className={style.label}>介绍 </div>
                          <div className={style.desc}>{item.description}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.label} style={{ marginBottom: 10 }}>
                商品图片
              </div>
              <div className={csn(style.detail, 'flex a-center wrap')}>
                {detail.picList.map((v, k) => {
                  return (
                    <img
                      key={v.id}
                      alt={v.title}
                      style={{ width: 100, height: 100, objectFit: 'cover', marginRight: 10, marginBottom: 10 }}
                      src={getPicFullUrl(v.url)}
                      onClick={() => {
                        let picList: string[] = []
                        if (detail) {
                          for (let v of detail.picList) {
                            picList.push(getPicFullUrl(v.url))
                          }
                          this.setState({
                            isShowImg: true,
                            photoIndex: k,
                            showImages: picList,
                          })
                        }
                      }}
                    ></img>
                  )
                })}
              </div>
            </div>
            <div className={csn(style.item)} style={{ borderBottom: 'none' }}>
              <div className={style.label} style={{ marginBottom: 10 }}>
                商品描述
              </div>
              <div className={style.description}>
                <div
                  className={csn(style.detail, style.description)}
                  dangerouslySetInnerHTML={{ __html: detail.content }}
                ></div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>评论列表</div>
              <Table rowKey="id" dataSource={detail.comments} columns={this.columns} />
            </div>
          </div>
        </div>
        <PictureView
          isShowImg={this.state.isShowImg}
          photoIndex={this.state.photoIndex}
          showImages={this.state.showImages}
          onMovePrevRequest={this.onMovePrevRequest}
          onMoveNextRequest={this.onMoveNextRequest}
          onChange={this.onChange}
        />
      </div>
    )
  }
  delComments = (ids: number[]) => {
    del({ ids })
      .then(() => {
        message.success('删除成功', 0.5, () => {
          this.init()
        })
      })
      .catch(err => {
        message.success('删除失败,错误信息: ' + err.msg, 3)
        console.log(err)
      })
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
