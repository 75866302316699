import { bpost, GetListParam } from './api'
import { Picture, DbBaseField } from '@/typings'
/**
 * 便民服务
 */
export interface ConvenientService extends DbBaseField {
  logo: Picture //门店logo
  logoId: number
  name: string //门店名称
  description: string //门店描述
  phone: string //电话号码
  businessHours: string //营业时间
  address: string //地址
  picList: Picture[] //门店图集
  picIdList: number[]
  mangerIdList: string[] //小区列表
  characteristicList: Characteristic[]
}
/**
 * 门店特色
 */
export interface Characteristic {
  pic: Picture //图片
  picId: number
  title: string // 标题
  description: string //描述
}
export type AddData = Omit<ConvenientService, 'id' | 'logo' | 'picList'>
export type EditData = Omit<ConvenientService, 'logo' | 'picList'>
/**
 * 获取便民服务列表
 */
export async function list(data: GetListParam) {
  return bpost<{ list: ConvenientService[] }>({
    url: '/convenient/list',
    data,
  })
}
/**
 * 获取便民服务
 */
export async function detail(data: { id: number }) {
  return bpost<{ detail: ConvenientService }>({
    url: '/convenient/detail',
    data,
  })
}
/**
 * 添加便民服务
 */
export async function add(data: AddData) {
  return bpost({
    url: '/convenient/add',
    data,
  })
}
/**
 * 编辑便民服务
 */
export async function edit(data: EditData) {
  return bpost({
    url: '/convenient/edit',
    data,
  })
}
/**
 * 编辑便民服务
 */
export async function del(data: { ids: number[] }) {
  return bpost({
    url: '/convenient/del',
    data,
  })
}
