import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { AdminSceneGoods, del, list, changeOnSaleStatus } from '@/services/goods'
import { GoodsCategory, list as getCategoryList } from '@/services/goodsCategory'
import { listManger, Manger } from '@/services/outer'
import { Picture } from '@/typings'
import { conditionType } from '@/utils/constant'
import { getPicFullUrl } from '@/utils/fn'
import { ListFilter } from '@/utils/jsbdk'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Button, Divider, Form, message, Modal, Popconfirm, Tooltip, Switch } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import qs from 'qs'
import React, { Component } from 'react'
import { Link, RouteComponentProps, RouteProps, RouteChildrenProps } from 'react-router-dom'
import Page404 from '@/pages/common/Page404'
import { notLimit } from '@/services/api'
const tableStyle = gSass.common.table
interface Props { }
type Product = AdminSceneGoods
interface State {
  categoryId: number
  isTruePathname: boolean
  pathPage: number
  breadCrumbList: breadCrumbItem[]
  list: Product[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: Product[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  mangerList: Pick<Manger, 'id' | 'name'>[]
  categoryList: {
    value: number,
    title: string
  }[]
}
//@ts-ignore
@Form.create()
export default class List extends Component<
Props & FormComponentProps & RouteChildrenProps<{ page?: string }> & RouteComponentProps,
State
> {
  tableColumns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '商品分类',
      dataIndex: 'category',
      render: (category: GoodsCategory, record: Product) => {
        return (
          <Tooltip title={<div>分类ID: {record.categoryId}</div>} placement="right">
            <span style={{ color: '#0089ff' }}>{category.name}</span>
          </Tooltip>
        )
      },
    },
    {
      title: '商品名称',
      dataIndex: 'name',
    },
    {
      title: '所属小区',
      dataIndex: 'mangerIdList',
      render: (mangerIdList: string[]) => {
        let { mangerList } = this.state
        return (
          <div style={{ width: 150 }}>
            {mangerIdList[0] === '0' && '所有小区'}
            {mangerIdList[0] !== '0' &&
              mangerIdList.map(v => {
                let name = ''
                for (let manger of mangerList) {
                  if (manger.id === v) {
                    name = manger.name
                  }
                }
                return name + '  | '
              })}
          </div>
        )
      },
    },
    {
      title: '是否上架',
      dataIndex: 'isOnSale',
      render: (isOnSale: string, record: Product) => {
        return (
          <Switch
            checked={isOnSale === 'true'}
            checkedChildren="上架"
            unCheckedChildren="下架"
            onChange={isOnSale => {
              changeOnSaleStatus({ id: record.id, isOnSale: isOnSale ? 'true' : 'false' })
                .then(() => {
                  message.success('操作成功', 1, this.init)
                })
                .catch(err => {
                  message.error('操作失败, 错误信息: ' + err.msg, 3)
                })
            }}
          />
        )
      },
    },
    {
      title: '商品图片',
      dataIndex: 'picList',
      render: (picList: Picture[]) => {
        return (
          <>
            <img
              className={tableStyle.avatar}
              style={{ objectFit: 'cover' }}
              src={picList.length > 0 ? getPicFullUrl(picList[0].url) : this.state.defaultAvatar}
              alt="商品图片"
              onClick={() => {
                let showImages: string[] = []
                for (let v of picList) {
                  showImages.push(getPicFullUrl(v.url))
                }
                this.setState({
                  isShowImg: true,
                  showImages,
                })
              }}
            />
            <PictureView
              isShowImg={this.state.isShowImg}
              photoIndex={this.state.photoIndex}
              showImages={this.state.showImages}
              onMovePrevRequest={this.onMovePrevRequest}
              onMoveNextRequest={this.onMoveNextRequest}
              onChange={this.onChange}
            />
          </>
        )
      },
    },
    {
      title: '商品库存',
      dataIndex: 'stock',
    },
    {
      title: '所需积分',
      dataIndex: 'score',
    },
    {
      title: '销量',
      dataIndex: 'buyCount',
    },
    {
      title: '商品介绍',
      dataIndex: 'content',
      render: (content: string) => {
        return (
          <div>
            <div style={{ marginBottom: 8 }}>{content.length > 20 ? content.substr(1, 20) + '...' : content}</div>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                Modal.confirm({
                  maskClosable: true,
                  title: '评论内容',
                  content: <div dangerouslySetInnerHTML={{ __html: content }}></div>,
                  okText: '确认',
                  cancelText: '',
                })
              }}
            >
              查看
            </Button>
          </div>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: Product) => {
        return (
          <div>
            <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
              <Link to={pathMap.admin.integralMall.detail + '?id=' + record.id} style={{ cursor: 'pointer' }}>
                查看
              </Link>
              <Divider type="vertical" />
              <div style={{ cursor: 'pointer' }} onClick={() => this.editProduct(record.id)}>
                编辑
              </div>
              <Divider type="vertical" />
              <div style={{ cursor: 'pointer' }}>
                <Popconfirm
                  title={<div>你确定要删除文章{record.id}吗?</div>}
                  onConfirm={() => this.delProduct([record.id])}
                >
                  <span>删除</span>
                </Popconfirm>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  initPageInfo = () => {
    const { page } = this.props.match.params
    let actPage = 1
    let { pagination } = this.state
    if (page) {
      actPage = parseInt(page)
      if (isNaN(actPage)) {
        message.error('当前页数不正确, 默认显示第一页', 3)
        this.props.history.push(pathMap.admin.integralMall.product + '/1')
        return
      }
      pagination.current = actPage
      this.setState({
        pagination,
      })
    }
  }
  init = async () => {
    try {
      const {
        data: { list: categoryList },
      } = await getCategoryList({ page: notLimit, limit: notLimit, filter: {} })
      let list = [];
      for(let v of categoryList){
        list.push({
          value: v.id,
          title: v.name
        })
      }
      this.setState({
        categoryList:list,
      })
      this.initPageInfo()
      await this.listProduct()
      let {
        data: { list: mangerList },
      } = await listManger()
      this.setState({
        mangerList,
      })
    } catch (err) {
      console.log(err)
    }
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let categoryId: number = parseInt(search.id) || 0
    return {
      categoryId,
      categoryList: [],
      isTruePathname: true,
      pathPage: 1,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '积分商城', to: pathMap.admin.integralMall.rule },
        { name: '积分商品' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listProduct,
          )
          this.props.history.push(pathMap.admin.integralMall.product + '/' + page)
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
      },
      filterModalIsActive: false,
      filterList: [],
      mangerList: [],
    }
  }

  listProduct = async () => {
    try {
      const { pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      if (this.state.categoryId > 0 && !filter.categoryId) {
        filter.categoryId = {
          condition: conditionType.eq,
          val: this.state.categoryId,
        }
      }
      let json = await list({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listProduct,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listProduct,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '新增',
        btnProps: {
          icon: 'add',
          type: 'default',
          onClick: () => {
            this.props.history.push(pathMap.admin.integralMall.add)
          },
        },
      },
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '删除',
              onClick: () => {
                let ids: number[] = []
                for (let v of this.state.selectedRows) {
                  ids.push(v.id)
                }
                if (ids.length < 1) {
                  message.error('请选择要删除的文章', 2)
                  return
                }
                this.delProduct(ids)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
    ]
    let { isTruePathname } = this.state
    if (!isTruePathname) {
      return (
        <div>
          <Page404 />
        </div>
      )
    }
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入商品名称...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'categoryId',
                  conditionFieldName: 'categoryCondition',
                  type: 'select',
                  title: '商品分类',
                  selectDataList: this.state.categoryList,
                  decoratorOptions: {
                    initialValue: '',
                  },
                },
                {
                  valFieldName: 'name',
                  conditionFieldName: 'nameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '商品名称',
                },
                {
                  valFieldName: 'stock',
                  conditionFieldName: 'stockCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '商品库存',
                },
                {
                  valFieldName: 'score',
                  conditionFieldName: 'scoreCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '所需积分',
                },
                {
                  valFieldName: 'buyCount',
                  conditionFieldName: 'buyCountCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '销量',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
  delProduct = (ids: number[]) => {
    del({ ids })
      .then(() => {
        message.success('删除成功', 0.5, () => {
          this.init()
        })
      })
      .catch(err => {
        message.success('删除失败,错误信息: ' + err.msg, 3)
        console.log(err)
      })
  }
  editProduct = (productId: number) => {
    this.props.history.push(pathMap.admin.integralMall.edit + '?id=' + productId)
  }
}
