import { DbBaseField } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { GetListParam } from './api'
export type Scene = 'default' | 'admin'
/**
 * 公告
 */
export interface Notice extends DbBaseField {
  excerpt: string // 摘要 不填的话默认显示content前30个字
  content: string // html
  link: string // 如果 link 有值,点击就会跳转到小程序的页面
}
export type AdminSceneNotice = Notice & { mangerIdList: string[] }

export type AddData = AdminSceneNotice
export type EditData = AdminSceneNotice
export async function add(data: AdminSceneNotice) {
  return bpost({
    url: 'notice/add',
    data,
  })
}
export async function del(data: { ids: number[] }) {
  return bpost({
    url: 'notice/del',
    data,
  })
}
export async function edit(data: EditData) {
  return bpost({
    url: 'notice/edit',
    data,
  })
}
export async function detail(data: { id: number }) {
  return bpost<{ detail: AdminSceneNotice }>({
    url: 'notice/detail',
    data,
  })
}

export async function list(data: GetListParam) {
  return bpost<{ list: Notice[] }>({
    url: 'notice/list',
    data,
  })
}
