import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { getPicFullUrl } from '@/utils/fn'
import { getUploadImgUrl } from '@api/api'
import { Button, Form, Icon, Input, message, Modal, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { Component, FormEvent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
interface Props {}
interface State {
  previewVisible: boolean
  avatarPreviewImage: string
  previewImage: string
  fileList: any[]
  breadCrumbList: breadCrumbItem[]
}
type DefaultProps = RouteComponentProps & FormComponentProps
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      previewVisible: false,
      avatarPreviewImage: '',
      previewImage: '',
      fileList: [],
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '管理员管理', to: pathMap.admin.master.index },
        { name: '添加/编辑' },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
    } catch (err) {
      console.log(err)
    }
  }
  //添加用户提交表单
  addUser = (evt: FormEvent<any>) => {
    evt && evt.preventDefault()
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        console.log(err)
        return
      }
      let data = {
        account: fieldsValue.account,
        pwd: fieldsValue.pwd,
        rePwd: fieldsValue.rePwd,
        nick: fieldsValue.nick,
        email: fieldsValue.email,
        profile: fieldsValue.profile,
        phone: fieldsValue.phone,
      }
      console.log(data)
      // addUser(data)
      //   .then(_ => {
      //     this.props.form.resetFields()
      //     this.setState({
      //       previewVisible: false,
      //       previewImage: '',
      //       fileList: [],
      //     })
      //     message.success('添加成功', 1)
      //   })
      //   .catch(err => {
      //     message.error('添加失败,失败原因: ' + err.msg, 3)
      //     console.log(err)
      //   })
    })
  }
  //上传头像
  onAvatarChange = ({ file, fileList }: UploadChangeParam) => {
    if (file.status === 'done') {
      fileList = []
      let json = file.response
      if (typeof json === 'object') {
        if (json.code === 0) {
          file.url = json.data.url
          ;(file as UploadFile & { picId: number }).picId = json.data.picId
          fileList.push(file)
        } else {
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      fileList,
    })
  }
  handlePreview = (file: UploadFile) => {
    let previewImage = file.url ? file.url : (file.thumbUrl as string)
    this.setState({
      previewImage,
      previewVisible: true,
    })
  }
  handleCancel = () => this.setState({ previewVisible: false })

  checkNickIsExist = async (nick: string) => {
    console.log(nick)
    return false
    // return await checkNickIsExist(nick)
  }
  checkAccountIsExist = async (account: string) => {
    console.log(account)
    return false
    // return await checkAccountIsExist(account)
  }
  checkEmailIsExist = async (email: string) => {
    console.log(email)
    return false
    // return await checkEmailIsExist(email)
  }
  checkPhoneIsExist = async (phone: string) => {
    console.log(phone)
    return false
    // return await checkPhoneIsExist(phone)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        sm: {
          span: 4,
          offset: 4,
        },
      },
      wrapperCol: {
        sm: {
          span: 8,
          offset: 0,
        },
      },
    }
    //头像上传按钮
    const { fileList } = this.state
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传图片</div>
      </div>
    )

    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>
          <Form>
            <Form.Item {...formItemLayout} label="用户名">
              {getFieldDecorator('account', {
                rules: [
                  { min: 2, max: 32, required: true, message: '请输入2-32的用户名' },
                  {
                    pattern: /^[a-zA-Z0-9_-]{2,12}$/,
                    message: '用户名必须为2-32位大小写字母数字-_',
                  },
                  {
                    validator: async (_, val, cb) => {
                      let errors = []
                      let isExist = await this.checkAccountIsExist(val)
                      // console.log(isExist)
                      if (isExist) {
                        errors.push(new Error('用户名已存在'))
                      }
                      cb(errors)
                    },
                  },
                ],
              })(<Input placeholder="请输入用户名" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="密码">
              {getFieldDecorator('pwd', {
                rules: [
                  { min: 4, max: 32, message: '密码长度为4-32' },
                  { required: true, message: '请输入密码' },
                ],
              })(<Input.Password placeholder="请输入密码" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="确认密码">
              {getFieldDecorator('rePwd', {
                rules: [
                  { required: true, message: '请确认密码' },
                  {
                    validator: (_, val, cb) => {
                      let pwd = this.props.form.getFieldValue('pwd')
                      let errors = []
                      if (pwd !== val) {
                        errors.push(new Error('两次密码不一致'))
                      }
                      cb(errors)
                    },
                  },
                ],
              })(<Input.Password placeholder="请再次输入密码" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="昵称">
              {getFieldDecorator('nick', {
                rules: [
                  { min: 2, max: 12, message: '昵称长度为2-12位' },
                  {
                    pattern: /^[a-zA-Z0-9\u3400-\u9fff\uf900-\ufaff_-]{2,12}$/,
                    message: '昵称只能为2-12位汉字、字母、数字和下划线_及破折号-',
                  },
                  {
                    validator: async (_, val, cb) => {
                      let errors = []
                      let isExist = await this.checkNickIsExist(val)
                      if (isExist) {
                        errors.push(new Error('昵称已存在'))
                      }
                      cb(errors)
                    },
                  },
                ],
              })(<Input placeholder="请输入昵称" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="头像">
              {getFieldDecorator('avatar', {
                rules: [],
              })(
                <div className="clearfix">
                  <Upload
                    action={getUploadImgUrl()}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.onAvatarChange}
                    accept="image/*"
                    name="file"
                    data={{ name: 'file' }}
                  >
                    {fileList.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={getPicFullUrl(this.state.previewImage)} />
                  </Modal>
                </div>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="手机号码">
              {getFieldDecorator('phone', {
                rules: [
                  { message: '请输入正确的手机号码', pattern: /^1[3456789]\d{9}$/ },
                  {
                    validator: async (_, val, cb) => {
                      let errors = []
                      let isExist = await this.checkPhoneIsExist(val)
                      if (isExist && val) {
                        errors.push(new Error('手机号已注册'))
                      }
                      cb(errors)
                    },
                  },
                ],
              })(<Input placeholder="请输入手机号码" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="邮箱">
              {getFieldDecorator('email', {
                rules: [
                  { type: 'email', message: '请输入正确的邮箱' },
                  {
                    validator: async (_, val, cb) => {
                      let errors = []
                      let isExist = await this.checkEmailIsExist(val)
                      if (isExist && val) {
                        errors.push(new Error('邮箱已注册'))
                      }
                      cb(errors)
                    },
                  },
                ],
              })(<Input placeholder="请输入邮箱" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="个人介绍">
              {getFieldDecorator('profile')(
                <Input.TextArea rows={4} maxLength={500} placeholder="请输入个人介绍(500字以内)" />,
              )}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}
            >
              <Button type="primary" onClick={this.addUser}>
                添加
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}
