import pathMap from '@/routes/pathMap'
import { CommonOperation, editOperationGroup, groupDetail, listGroup, listOperation } from '@api/operation'
import { OriOperationGroup } from '@api/operationGroup'
import { gSass } from '@utils/global'
import { Breadcrumb, Button, Checkbox, Form, Input, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import _ from 'lodash'
import qs from 'qs'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
const style = gSass.admin.operation.addGroup
const { TextArea } = Input
const formItemLayout = {
  labelCol: {
    sm: {
      span: 2,
      offset: 0,
    },
  },
  wrapperCol: {
    sm: {
      span: 8,
      offset: 0,
    },
  },
}

type OperationGroup = OriOperationGroup & {
  indeterminate: boolean
  checked: boolean
  extend_operation_list: Operation[]
  operationList: Operation[]
}
type Operation = CommonOperation & { checked: boolean }

interface Props {}
interface State {
  id: number
  groupDetail: OriOperationGroup
  operationGroupList: OperationGroup[]
  operationList: Operation[]
  operationChildGroupList: number[]
}
type DefaultProps = RouteComponentProps & FormComponentProps
// @ts-ignore
@Form.create()
export default class EditOperationApiGroup extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      id: 0,
      groupDetail: {
        id: 0,
        description: '',
        name: '',
        operation_group_list: [],
        operation_list: [],
      },
      operationGroupList: [],
      operationList: [],
      operationChildGroupList: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id)
    try {
      let operationGroupListPromise = listGroup({ page: 0, limit: 0 }),
        operationListPromise = listOperation({ page: 0, limit: 0 }),
        groupDetailPromise = groupDetail(id),
        oriGroupDetail = (await groupDetailPromise).data.detail,
        oriOperationGroupList = (await operationGroupListPromise).data.list,
        oriOperationList = (await operationListPromise).data.list
      oriOperationGroupList = oriOperationGroupList.filter(v => v.id !== parseInt(search.id))
      let idMapOperationGroup = new Map(),
        idMapOperation = new Map(),
        operationGroupList: OperationGroup[] = [],
        operationList: Operation[] = []
      this.props.form.setFieldsValue({
        name: oriGroupDetail.name,
        description: oriGroupDetail.description,
      })
      for (let operationGroupItem of oriOperationGroupList) {
        operationGroupList.push({
          ...operationGroupItem,
          indeterminate: false,
          checked: oriGroupDetail.operation_group_list.includes(operationGroupItem.id),
          extend_operation_list: [],
          operationList: [],
        })
        idMapOperationGroup.set(operationGroupItem.id, operationGroupList[operationGroupList.length - 1])
      }
      //获取操作组的所有继承操作
      let getOperationGroupExtendOperationList = (operationGroupItem: OperationGroup): Operation[] => {
        let operationExtendList = []
        let childGroupIdArr = operationGroupItem.operation_group_list
        for (let groupId of childGroupIdArr) {
          let childOperationGroupItem = _.clone(idMapOperationGroup.get(groupId))
          if (childOperationGroupItem) {
            operationExtendList.push(getOperationGroupExtendOperationList(childOperationGroupItem))
            operationExtendList.push(childOperationGroupItem.operation_list)
          }
        }
        return operationExtendList
      }
      for (let operationItem of oriOperationList) {
        operationList.push({
          ...operationItem,
          checked: oriGroupDetail.operation_list.includes(operationItem.id),
        })
        idMapOperation.set(operationItem.id, operationList[operationList.length - 1])
      }
      for (let operationGroupItem of operationGroupList) {
        operationGroupItem.extend_operation_list = getOperationGroupExtendOperationList(operationGroupItem)
        for (let i = 0; i < operationGroupItem.operation_list.length; i++) {
          operationGroupItem.operationList[i] = _.clone<Operation>(
            idMapOperation.get(operationGroupItem.operation_list[i]),
          )
        }
      }
      for (let operationGroupItem of operationGroupList) {
        for (let operationItem of operationGroupItem.extend_operation_list) {
          operationList = operationList.filter(v => v.id !== operationItem.id)
        }
        for (let operationItem of operationGroupItem.operationList) {
          operationList = operationList.filter(v => v.id !== operationItem.id)
        }
      }
      this.setState({
        id,
        operationGroupList,
        operationList,
      })
    } catch (e) {
      message.error('获取操作组详情失败,错误信息: ' + (e instanceof Error ? e : e.msg))
    }
  }
  //提交
  editOperationGroup = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.preventDefault()
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return
      }
      let operationGroupList: number[] = [],
        operationList: number[] = []
      //获取所有的操作组
      for (let v of this.state.operationGroupList) {
        if (v.checked) {
          operationGroupList.push(v.id)
        } else {
          for (let v2 of v.extend_operation_list) {
            if (v2.checked) {
              operationList = operationList.filter(val => {
                return val !== v2.id
              })
              operationList.push(v2.id)
            }
          }
          for (let v3 of v.operationList) {
            if (v3.checked) {
              operationList = operationList.filter(val => {
                return val !== v3.id
              })
              operationList.push(v3.id)
            }
          }
        }
      }
      //获取所有的操作方法
      for (let operationItem of this.state.operationList) {
        if (operationItem.checked) {
          operationList = operationList.filter(val => val !== operationItem.id)
          operationList.push(operationItem.id)
        }
      }
      let data = {
        id: this.state.id,
        name: fieldsValue.name,
        operationIdList: operationList,
        operationGroupIdList: operationGroupList,
        description: fieldsValue.description,
      }
      editOperationGroup(data)
        .then(() => {
          message.success('编辑成功', 2)
        })
        .catch(err => {
          message.error('添加失败,失败原因: ' + err.msg, 3)
          console.log(err)
        })
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div className={style.permission}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to={pathMap.admin.index}>首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={pathMap.admin.operation.group.index}>权限管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>编辑操作组</Breadcrumb.Item>
        </Breadcrumb>
        <Form className={style.form}>
          <Form.Item {...formItemLayout} label="操作组名">
            {getFieldDecorator('name', {
              rules: [{ min: 2, max: 32, required: true, message: '请输入2-32的操作组名' }],
            })(<Input size="large" placeholder="请输入操作组名" />)}
          </Form.Item>
          <Form.Item
            labelCol={{
              sm: {
                span: 2,
                offset: 0,
              },
            }}
            wrapperCol={{
              sm: {
                span: 20,
                offset: 0,
              },
            }}
            label="子操作组列表"
          >
            {this.state.operationGroupList.map((v, k) => {
              return (
                <div className={style.operationGroupItem} key={k}>
                  <div className={style.ogiTitle}>
                    <Checkbox
                      indeterminate={v.indeterminate}
                      onChange={() => {
                        let operationGroupList = this.state.operationGroupList
                        operationGroupList[k].checked = !v.checked
                        if (operationGroupList[k].checked) {
                          operationGroupList[k].indeterminate = false
                          let { operationChildGroupList } = this.state
                          operationChildGroupList.push(v.id)
                          this.setState({
                            operationChildGroupList,
                          })
                        } else {
                          let id = v.id,
                            operationChildGroupList
                          operationChildGroupList = this.state.operationChildGroupList.filter(val => {
                            return val !== id
                          })
                          this.setState({
                            operationChildGroupList,
                          })
                        }
                        for (let val of operationGroupList[k].extend_operation_list) {
                          val.checked = operationGroupList[k].checked
                        }
                        for (let val of operationGroupList[k].operationList) {
                          val.checked = operationGroupList[k].checked
                        }
                        this.setState({
                          operationGroupList,
                        })
                      }}
                      checked={v.checked}
                    >
                      {v.name}
                    </Checkbox>
                  </div>
                  <div className={style.operationList}>
                    {v.extend_operation_list.map((v2, k2) => {
                      return (
                        <Checkbox
                          key={`0-${k}-${k2}`}
                          onChange={_ => {
                            let operationGroupList = this.state.operationGroupList
                            operationGroupList[k].extend_operation_list[k2].checked = !v2.checked
                            let groupIndeterminate = false,
                              groupAllchecked = true
                            for (let val of operationGroupList[k].extend_operation_list) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            for (let val of operationGroupList[k].operationList) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            operationGroupList[k].checked = groupAllchecked
                            operationGroupList[k].indeterminate = groupAllchecked ? false : groupIndeterminate
                            this.setState({
                              operationGroupList,
                            })
                          }}
                          checked={v.checked || v2.checked}
                        >
                          {v2.name}
                        </Checkbox>
                      )
                    })}
                    {v.operationList.map((v2, k2) => {
                      return (
                        <Checkbox
                          key={`1-${k}-${k2}`}
                          onChange={_ => {
                            let operationGroupList = this.state.operationGroupList
                            operationGroupList[k].operationList[k2].checked = !v2.checked
                            let groupIndeterminate = false,
                              groupAllchecked = true
                            for (let val of operationGroupList[k].extend_operation_list) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            for (let val of operationGroupList[k].operationList) {
                              if (val.checked) {
                                groupIndeterminate = true
                              } else {
                                groupAllchecked = false
                              }
                            }
                            operationGroupList[k].checked = groupAllchecked
                            operationGroupList[k].indeterminate = groupAllchecked ? false : groupIndeterminate
                            this.setState({
                              operationGroupList,
                            })
                          }}
                          checked={v.checked || v2.checked}
                        >
                          {v2.name}
                        </Checkbox>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </Form.Item>
          <Form.Item
            labelCol={{
              sm: {
                span: 2,
                offset: 0,
              },
            }}
            wrapperCol={{
              sm: {
                span: 20,
                offset: 0,
              },
            }}
            label="操作方法列表"
          >
            {
              <div className={style.operationMethodList}>
                {this.state.operationList.map((v, k) => {
                  return (
                    <div className={style.operationMethodItem} key={k}>
                      <Checkbox
                        key={`0-${k}-${k}`}
                        onChange={_ => {
                          let operationList = this.state.operationList
                          operationList[k].checked = !v.checked
                          this.setState({
                            operationList,
                          })
                        }}
                        checked={v.checked}
                      >
                        {v.name}
                      </Checkbox>
                    </div>
                  )
                })}
              </div>
            }
          </Form.Item>
          <Form.Item {...formItemLayout} label="操作组描述">
            {getFieldDecorator('description', {})(<TextArea rows={4} placeholder="请输入操作描述" />)}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 7,
                offset: 2,
              },
            }}
          >
            <Button size="large" className={style.btn} type="primary" onClick={this.editOperationGroup}>
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
