import pathMap from '@/routes/pathMap'
import { del, list, Msg, AdminSceneMsg } from '@/services/msg'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form, message, Popconfirm, Switch, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CommonWxUser } from '@/services/user'
import { getPicFullUrl } from '@/utils/fn'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
type Message = AdminSceneMsg
interface State {
  categoryId: number
  breadCrumbList: breadCrumbItem[]
  list: Msg[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: Message[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class MessageList extends Component<
  Props & FormComponentProps & RouteProps & RouteComponentProps,
  State
> {
  tableColumns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: '用户',
      dataIndex: 'user',
      render: (user: CommonWxUser | null, record: Message) => {
        if (!user) {
          return <div>{record.uid}</div>
        }
        return (
          <Tooltip
            title={
              <div>
                <div>uid:{user.id}</div>
                <div>手机号码:{user.phone}</div>
              </div>
            }
            placement="left"
          >
            <div className="flex a-center">
              <span style={{ marginRight: 8, width: 45 }}>{user.nick.substr(0, 3)}</span>
              <img
                alt=""
                style={{ width: 50 }}
                src={getPicFullUrl(user.wxInfo.headImgUrl) || gImg.common.defaultAvatar}
              ></img>
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: '内容',
      dataIndex: 'content',
    },
    {
      title: '是否已读',
      dataIndex: 'hasView',
      render: (hasView: string) => {
        return <Switch checkedChildren="是" unCheckedChildren="否" checked={hasView === 'true'}></Switch>
      },
    },
    {
      title: '发送时间',
      dataIndex: 'ctime',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: Message) => {
        return (
          <div>
            <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
              <div style={{ cursor: 'pointer' }}>
                <Popconfirm
                  title={<div>你确定要删除消息{record.id}吗?</div>}
                  onConfirm={() => this.delMessage([record.id])}
                >
                  <span>删除</span>
                </Popconfirm>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listProduct()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let categoryId: number = parseInt(search.id) || 0
    return {
      categoryId,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '消息管理', to: pathMap.admin.message.index },
        { name: '消息列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listProduct,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.after,
          val: '',
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }

  listProduct = async () => {
    try {
      const { pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      let json = await list({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listProduct,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listProduct,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '删除',
              onClick: () => {
                let ids: number[] = []
                for (let v of this.state.selectedRows) {
                  ids.push(v.id)
                }
                if (ids.length < 1) {
                  message.error('请选择要删除的文章', 2)
                  return
                }
                this.delMessage(ids)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
    ]
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入发送人姓名...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'name',
                  conditionFieldName: 'nameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '发送人姓名',
                },
                {
                  valFieldName: 'hasView',
                  decoratorOptions: { initialValue: null },
                  title: '是否已读',
                  type: 'radio',
                  radioDataList: [
                    {
                      title: '全部',
                      value: null,
                    },
                    {
                      title: '是',
                      value: true,
                    },
                    {
                      title: '否',
                      value: false,
                    },
                  ],
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  delMessage = (ids: number[]) => {
    const { list } = this.state,
      selectedRows = this.state.selectedRows
    del({ ids })
      .then(() => {
        message.success('删除成功', 1)
        this.setState({
          list: list.filter(item => !ids.includes(item.id)),
          selectedRows: selectedRows.filter(item => !ids.includes(item.id)),
        })
      })
      .catch(err => {
        message.error('删除失败,错误原因: ' + err.msg, 3)
      })
  }
  editProduct = (productId: number) => {
    this.props.history.push(pathMap.admin.message.edit + '?ids=' + JSON.stringify([productId]))
  }
}
