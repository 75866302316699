import { DbBaseField } from '@/typings'
export type Sex = 0 | 1 | 2
export const sexMap = {
  0: '未知',
  1: '男',
  2: '女',
}
export interface WxUser extends DbBaseField {
  uid: number
  openid: string
  nickname: string
  sex: number
  city: string
  country: string
  province: string
  language: string
  headImgUrl: string
}
