import 'core-js/stable' // 保证此行在第一行
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh'
import '@formatjs/intl-relativetimeformat/polyfill'
import configStore from '@redux/stores/app'
import '@sass/app.scss'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'
import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'regenerator-runtime/runtime'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import * as serviceWorker from './serviceWorker'
import { init } from '@utils/fn'

const { store, persistor } = configStore()
init()
const rootElement = document.getElementById('root')
const Root = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)
if (rootElement!.hasChildNodes()) {
  hydrate(<Root />, rootElement)
} else {
  render(<Root />, rootElement)
}
serviceWorker.register()
