import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { adminGetHouse, AdminHouseDetail,House, delHouse } from '@/services/house'
import { Picture } from '@/typings'
import { getPicFullUrl } from '@/utils/fn'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form, Table,Popconfirm, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
const style = gSass.admin.house.detail
interface Props { }
interface State {
  houseId: string
  breadCrumbList: breadCrumbItem[]
  detail: AdminHouseDetail | null
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
type DefaultProps = RouteComponentProps<{ id: string }> & FormComponentProps

// @ts-ignore
@Form.create()
export default class Detail extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  columns = [
    {
      title: 'ID',
      dataIndex: 'uid',
    },
    {
      title: '微信头像',
      dataIndex: 'wxAvatar',
      render: (avatar: string) => {
        return (
          <img
            alt=""
            style={{ width: 80, height: 80, cursor: 'pointer' }}
            src={avatar}
            onClick={() => {
              this.setState({
                isShowImg: true,
                showImages: [avatar],
              })
            }}
          />
        )
      },
    },
    {
      title: '昵称',
      dataIndex: 'wxNick',
    },
    {
      title: '类型',
      dataIndex: 'type',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string,record:Omit<House, 'members' | 'applyMembers'>) => {
        return (
          <span className={style.btn}>
            <Popconfirm
              title={<div>你确定要解绑该房屋用户{record.wxNick}吗?</div>}
              onConfirm={() => this.delHouse(record.uid, record.houseId)}
            >
              <span style={{ color: '#1890ff' }}>解绑</span>
            </Popconfirm>
          </span>
        )
      }
    }
  ]
  applyColumns = [
    {
      title: 'ID',
      dataIndex: 'uid',
    },
    {
      title: '微信头像',
      dataIndex: 'wxAvatar',
      render: (avatar: string) => {
        return <img alt="" style={{ width: 80, height: 80 }} src={avatar} />
      },
    },
    {
      title: '昵称',
      dataIndex: 'wxNick',
    },
    {
      title: '类型',
      dataIndex: 'type',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
    },
    {
      title: '申请图片',
      dataIndex: 'picList',
      render: (pic: Picture[]) => {
        if (!pic) {
          pic = [];
        }
        return (
          <img
            style={{ width: 80, height: 80, cursor: 'pointer' }}
            alt=""
            src={pic.length > 0 ? getPicFullUrl(pic[0].url) : gImg.common.defaultAvatar}
            onClick={() => {
              if (pic.length > 0) {
                let showImages: string[] = []
                for (let v of pic) {
                  showImages.push(getPicFullUrl(v.url))
                }
                this.setState({
                  isShowImg: true,
                  showImages,
                })
              }
            }}
          ></img>
        )
      },
    },
  ]
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let houseId: string = search.houseId || ''
    return {
      houseId,
      isShowImg: false,
      photoIndex: 0,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '物业管理', to: pathMap.admin.house.index },
        { name: '房屋详情' },
      ],
      detail: null,
      showImages: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    let { houseId } = this.state
    try {
      let {
        data: { detail },
      } = await adminGetHouse({ houseId })
      this.setState({ detail })
    } catch (err) {
      message.error("错误原因: " + err.msg, 3)
      this.setState({ detail: null })
    }
  }
  delHouse = (uid: number, houseId: string) => {
    delHouse({ uid, houseId })
      .then(() => {
        message.success("解绑成功", 1)
        this.init()
      })
      .catch(err => {
        message.error("解绑失败,错误原因: " + err.msg, 3)
      })
  }
  render() {
    let { detail } = this.state
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          {detail && (
            <div className={style.list}>
              <div className={style.title}>房屋信息</div>
              <div className={csn(style.item, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>房屋ID</div>
                  <div className={style.detail}>{detail.houseId}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>房屋名称</div>
                  <div className={style.detail}>{detail.mangerName}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>地址</div>
                  <div className={style.detail}>{detail.houseAddr}</div>
                </div>
              </div>
            </div>
          )}
          <div className={style.list}>
            <div className={style.title}>成员列表</div>
            <Table rowKey="id" dataSource={detail ? detail.members : []} columns={this.columns} />
          </div>
          <div className={style.list}>
            <div className={style.title}>申请列表</div>
            <Table rowKey="id" dataSource={detail ? detail.applyMembers : []} columns={this.applyColumns} />
          </div>
        </div>
        <PictureView
          isShowImg={this.state.isShowImg}
          photoIndex={this.state.photoIndex}
          showImages={this.state.showImages}
          onMovePrevRequest={this.onMovePrevRequest}
          onMoveNextRequest={this.onMoveNextRequest}
          onChange={this.onChange}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
