import React, { Component } from 'react'
import 'react-image-lightbox/style.css'
import Lightbox from 'react-image-lightbox'
interface Props {
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  onMovePrevRequest: () => void
  onMoveNextRequest: () => void
  onChange: () => void
}
interface State {}
type DefaultProps = {}

export default class PictureView extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {}
  }
  render() {
    let { photoIndex, showImages, onChange, isShowImg, onMoveNextRequest, onMovePrevRequest } = this.props
    return (
      <div>
        {isShowImg ? (
          <Lightbox
            animationDisabled={showImages.length === 1 ? true : false}
            mainSrc={showImages[photoIndex]}
            prevSrc={showImages[(photoIndex + showImages.length - 1) % showImages.length]}
            nextSrc={showImages[(photoIndex + 1) % showImages.length]}
            onMovePrevRequest={onMovePrevRequest}
            onMoveNextRequest={onMoveNextRequest}
            onCloseRequest={onChange}
          />
        ) : null}
      </div>
    )
  }
}
