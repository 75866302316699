import pathMap from '@/routes/pathMap'
import { AdminSceneOrder, list, OrderExtra, Status, statusZh, Type, typeZh } from '@/services/order'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Divider, Form, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
interface State {
  breadCrumbList: breadCrumbItem[]
  list: AdminSceneOrder[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: AdminSceneOrder[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class PayList extends Component<Props & FormComponentProps & RouteProps, State> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '流水号',
      dataIndex: 'outTradeNo',
    },
    {
      title: '缴费人ID',
      dataIndex: 'user',
      render: (_: string, record: AdminSceneOrder) => {
        return (
          <Tooltip
            placement="left"
            title={
              <div>
                <div>UID: {record.uid}</div>
                <div>openid: {record.user.wxInfo.openid}</div>
                <div>微信昵称: {record.user.wxInfo.nickname}</div>
              </div>
            }
          >
            <span>{record.user.nick}</span>
          </Tooltip>
        )
      },
    },
    {
      title: '头像',
      dataIndex: 'avatar',
      render: (_: string, record: AdminSceneOrder) => {
        return (
          <>
            <img
              className={tableStyle.avatar}
              src={record.user.wxInfo.headImgUrl ? record.user.wxInfo.headImgUrl : this.state.defaultAvatar}
              alt="头像"
              onClick={() => {
                this.setState({
                  isShowImg: true,
                  showImages: [record.user.wxInfo.headImgUrl],
                })
              }}
            />
          </>
        )
      },
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      render: (_: string, record: AdminSceneOrder) => {
        return <div>{record.user.phone}</div>
      },
    },
    {
      title: '缴费类别',
      dataIndex: 'type',
      render: (type: Type) => {
        return <div>{typeZh[type]}</div>
      },
    },
    {
      title: '缴费状态',
      dataIndex: 'status',
      render: (type: Status) => {
        return <div>{statusZh[type]}</div>
      },
    },
    {
      title: '缴费金额 ( 元 )',
      dataIndex: 'totalFee',
      render: (price: number) => {
        return <div>{(price / 100).toFixed(2)}</div>
      },
    },
    {
      title: '缴费周期',
      dataIndex: 'extra',
      render: (extra: OrderExtra) => {
        return (
          <div>
            {extra.begin} 至 {extra.end}
          </div>
        )
      },
    },
    {
      title: '缴费时间',
      dataIndex: 'ctime',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: AdminSceneOrder) => {
        return (
          <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
            <Link to={pathMap.admin.pay.detail + '?id=' + record.id}>查看</Link>
            <Divider type="vertical" />
            <div style={{ cursor: 'pointer' }}>
              <Link to={pathMap.admin.user.detail + '?id=' + record.uid}>查看用户</Link>
            </div>
          </div>
        )
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    return {
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '缴费记录', to: pathMap.admin.pay.index },
        { name: '缴费记录列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
        status: {
          condition: conditionType.eq,
          val: 'completePay',
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }

  listUser = async () => {
    try {
      let { pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      let json = await list({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  deleteUser = (ids: number[]) => {
    console.log(ids)
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
    ]
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入手机号|缴费人...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'openid',
                  conditionFieldName: 'openidCondition',
                  type: 'value',
                  valueType: 'string',
                  title: 'openid',
                },
                {
                  valFieldName: 'status',
                  conditionFieldName: 'in',
                  type: 'select',
                  title: '状态',
                  selectDataList: [
                    { value: 0, title: '全部' },
                    { value: 'waitPay', title: statusZh.waitPay },
                    { value: 'cancelPay', title: statusZh.cancelPay },
                    { value: 'completePay', title: statusZh.completePay },
                  ],
                  decoratorOptions: {
                    initialValue: 'waitPay',
                  },
                },
                {
                  valFieldName: 'nick',
                  conditionFieldName: 'nickCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '缴费人',
                },
                {
                  valFieldName: 'phone',
                  conditionFieldName: 'phoneCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '手机号码',
                  decoratorOptions: {},
                },
                {
                  valFieldName: 'category',
                  conditionFieldName: 'categoryCondition',
                  type: 'select',
                  title: '缴费类别',
                  selectDataList: [
                    { value: 0, title: '全部' },
                    { value: 2, title: '物业缴费' },
                    { value: 1, title: '停车缴费' },
                  ],
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'price',
                  conditionFieldName: 'priceCondition',
                  type: 'value',
                  valueType: 'int',
                  title: '缴费金额',
                  rangeValMin: 1,
                  rangeValMax: 10000,
                  decoratorOptions: {},
                },
                {
                  valFieldName: 'ctime',
                  conditionFieldName: 'ctimeTimeCondition',
                  dateFmt: 'YYYY-MM-DD',
                  outDateFmt: 'YYYY-MM-DD 00:00:00',
                  type: 'time',
                  title: '缴费时间',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
