import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { detail, GoodsOrder } from '@/services/goodsOrder'
import gImg from '@/utils/img'
import gSass from '@utils/sass'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import qs from 'qs'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { getPicFullUrl } from '@/utils/fn'
const style = gSass.admin.user.detail
interface Props {}
interface State {
  id: number
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  breadCrumbList: breadCrumbItem[]
  detail: GoodsOrder | null
}
type DefaultProps = RouteComponentProps<{ id: string }> & FormComponentProps

// @ts-ignore
@Form.create()
export default class OrderDetail extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id) || 0
    return {
      id,
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '商品订单管理', to: pathMap.admin.order.list },
        { name: '商品订单详情' },
      ],
      detail: null,
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
      let { id } = this.state
      let {
        data: { detail: goodsOrder },
      } = await detail({ id })
      this.setState({ detail: goodsOrder })
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    let { detail } = this.state
    if (!detail) {
      return <div></div>
    }
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          <div className={style.list}>
            <div className={style.item}>
              <div className={style.title}>订单信息</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>ID</div>
                  <div className={style.detail}>{detail.id}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>支付积分</div>
                  <div className={style.detail}>{detail.payScore}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>购买数量</div>
                  <div className={style.detail}>{detail.count}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>商品规格</div>
                  <div className={style.detail}>{detail.standard}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>兑换时间</div>
                  <div className={style.detail}>{detail.ctime}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>房屋Id</div>
                  <div className={style.detail}>{detail.houseId}</div>
                </div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>用户信息</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>ID</div>
                  <div className={style.detail}>{detail.user.id}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>昵称</div>
                  <Link
                    style={{ color: '#0089ff' }}
                    to={pathMap.admin.user.detail + '?id=' + detail.user.id}
                    className={style.detail}
                  >
                    {detail.user.nick || '未命名'}
                  </Link>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>头像</div>
                  <div className={style.detail}>
                    <img alt="" style={{ width: 90 }} src={detail.user.wxInfo.headImgUrl} />
                  </div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>联系方式</div>
                  <div className={style.detail}>{detail.user.phone}</div>
                </div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>商品详情</div>
              <div className={csn(style.desc, 'flex a-center flexWrap')}>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>ID</div>
                  <div className={style.detail}>{detail.goodsDetail.id}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>商品名称</div>
                  <Link
                    style={{ color: '#0089ff' }}
                    to={pathMap.admin.integralMall.detail + '?id=' + detail.goodsDetail.id}
                    className={style.detail}
                  >
                    {detail.goodsDetail.name || '未命名'}
                  </Link>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>商品类别</div>
                  <div className={style.detail}>{detail.goodsDetail.category.name}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>库存</div>
                  <div className={style.detail}>{detail.goodsDetail.stock}</div>
                </div>
                <div className={csn(style.term, 'flex ')}>
                  <div className={style.label}>销量</div>
                  <div className={style.detail}>{detail.goodsDetail.buyCount}</div>
                </div>
                <div className={csn(style.term, 'flex ')} style={{ width: '100%' }}>
                  <div className={style.label}>规格&价格</div>
                  <div className={csn(style.detail, 'flex a-center wrap')} style={{ width: '100%' }}>
                    <div className={csn(style.priceList, 'flex a-center wrap')} style={{ width: '100%' }}>
                      {detail.goodsDetail.priceInfo.map((item, idx) => {
                        return (
                          <div className={style.priceItem} key={'price-info-' + idx}>
                            <div className={csn(style.labelPar, 'flex')}>
                              <div className={style.label}>规格 </div>
                              <div className={style.desc}>{item.standard}</div>
                            </div>
                            <div className={csn(style.labelPar, 'flex ')}>
                              <div className={style.label}>所需积分 </div>
                              <div className={style.desc}>{item.score}</div>
                            </div>
                            <div className={csn(style.labelPar, 'flex')}>
                              <div className={style.label}>介绍 </div>
                              <div className={style.desc}>{item.description}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className={csn(style.term, 'flex')} style={{ width: '100%' }}>
                  <div className={style.label}>商品列表</div>
                  <div className={style.detail}>
                    {detail.goodsDetail.picList.map((v, k) => {
                      return (
                        <img
                          key={k}
                          alt=""
                          style={{ width: 90 }}
                          src={getPicFullUrl(v.url) || gImg.common.defaultAvatar}
                          onClick={() => {
                            let showImages: string[] = []
                            for (let img of detail!.goodsDetail.picList) {
                              showImages.push(getPicFullUrl(img.url))
                            }
                            this.setState({
                              isShowImg: detail ? true : false,
                              showImages,
                              photoIndex: k,
                            })
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PictureView
          isShowImg={this.state.isShowImg}
          photoIndex={this.state.photoIndex}
          showImages={this.state.showImages}
          onMovePrevRequest={this.onMovePrevRequest}
          onMoveNextRequest={this.onMoveNextRequest}
          onChange={this.onChange}
        />
      </div>
    )
  }

  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
    })
  }
}
