/*
 * Author: buff <admin@buffge.com>
 * Created on : 2019-1-4, 16:50:36
 * QQ:1515888956
 */

export const BASE_URL = 'https://wx.jiadingcloud.com'
export const SESSION_EXPIRE = 15 * 60
export const tokenExpire = 3600 * 24 * 15
export const withCredentials = true
export const antdUploadImgAction = BASE_URL + '/antdUploadImg'
export const ProjectRoot = '/var/www/wx.jiadingcloud.com'
export const metaData = {
  title: '嘉隆物业',
  keywords: '嘉隆物业xxxx,',
  description: '嘉隆物业xxxxx',
}
