import { DbBaseField } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { CommonWxUser } from './user'
import { GetListParam } from './api'
export type Type = 'house' | 'park'
export type Scene = 'default' | 'admin'
export const typeZh: Record<Type, string> = {
  house: '房屋物业费',
  park: '车位物业费',
}
export type Status = 'waitPay' | 'cancelPay' | 'completePay'
export const statusZh: Record<Status, string> = {
  cancelPay: '取消支付',
  waitPay: '等待支付',
  completePay: '完成支付',
}
export interface Order extends DbBaseField {
  uid: number
  outTradeNo: string //流水号
  totalFee: number // 分
  status: Status
  type: Type
  title: string // 订单详情
  houseId: string
  parkId: string
  extra: OrderExtra
}
export type AdminSceneOrder = Order & { user: CommonWxUser }
export interface OrderExtra {
  begin: string
  end: string
}

export async function detail(data: { id: number }) {
  return bpost<{ detail: AdminSceneOrder }>({
    url: 'order/detail',
    data: {
      ...data,
      scene: 'admin',
    },
  })
}

export async function list(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'admin'
  return bpost<{ list: AdminSceneOrder[] }>({
    url: 'order/list',
    data,
  })
}
