import { Picture, DbBaseField } from '@/typings'
import { bpost } from './api'
import { Assign } from 'utility-types'
import { ListParam } from '@/utils/jsbdk'
/**
 * 文章类别
 */
export interface PostsCategory extends DbBaseField {
  name: string
  pid: number
  description: string
  picture: Picture
}

export type CommonPostsCategory = Pick<PostsCategory, 'id' | 'name' | 'pid' | 'description' | 'picture'>
export type PostsCategoryWithChild = CommonPostsCategory & {
  children: PostsCategoryWithChild[]
}
export type SimplePostsCategory = Pick<PostsCategory, 'id' | 'name' | 'pid'>
export type AddCategoryData = Assign<
  Pick<PostsCategory, 'name' | 'description' | 'pid'>,
  {
    picId?: number
  }
>
export type EditCategoryData = Assign<
  Pick<PostsCategory, 'id' | 'description' | 'name' | 'pid'>,
  {
    picId?: number
  }
>
/**
 * 获取分类列表
 */
export async function list(data: ListParam) {
  return bpost<{ list: PostsCategoryWithChild[] }>({
    url: '/postsCategory/list',
    data,
  })
}
/**
 * 添加分类
 */
export async function add(data: AddCategoryData) {
  return bpost({
    url: '/postsCategory/add',
    data,
  })
}
/**
 * 编辑分类
 */
export async function edit(data: EditCategoryData) {
  return bpost({
    url: '/postsCategory/edit',
    data,
  })
}
/**
 * 删除分类
 */
export async function del(ids: number[]) {
  return bpost({
    url: '/postsCategory/del',
    data: {
      ids,
    },
  })
}
export default {
  list,
  add,
  del,
  edit,
}
