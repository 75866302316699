import React, { Component } from "react"
import { MenuItem } from "@/typings"
import { gImg, gSass } from "@utils/global"
import { Icon, Layout, Menu } from "antd"
import { MenuMode } from "antd/lib/menu"
import { Link } from "react-router-dom"
import { MenuTheme } from "antd/lib/menu/MenuContext"
const style = gSass.admin.common.sidebar
const { SubMenu } = Menu
const { Sider } = Layout
interface Props {
  collapsed: boolean
  changeCollapse: (sideBarIsCollapsed: boolean) => void
  menuList: MenuItem[]
  defaultOpenKeys: string[]
  defaultSelectedKeys: string[]
}
interface State {
  width: number
  inlineCollapsed: boolean
  collapsed: boolean
  menuConfig: {
    theme?: MenuTheme
    mode?: MenuMode
  }
}
type DefaultProps = {}

export default class SideBar extends Component<Props & DefaultProps, State> {
  menuKey = 0
  rootSubmenuKeys: string[] = []
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      width: 250,
      inlineCollapsed: false,
      collapsed: false,
      menuConfig: {
        theme: "dark",
        mode: "inline",
      },
    }
  }
  getMenuListDom = (menuList: MenuItem[], level = 0, partentKeys: string[] = []) => {
    let that = this
    if (level === 0) {
      that.menuKey = 0
    }
    return menuList.map(v => {
      if (Array.isArray(v.child) && v.child.length > 0) {
        if (level === 0) {
          that.rootSubmenuKeys.push(`sub${that.menuKey}`)
        }
        let key = that.menuKey++
        partentKeys.push(`sub${key}`)
        return (
          <SubMenu
            key={`sub${key}`}
            title={
              <span>
                {" "}
                {v.icon ? <Icon type={v.icon} /> : ""}
                <span>{v.title}</span>
              </span>
            }>
            {that.getMenuListDom(v.child, level + 1, partentKeys)}
          </SubMenu>
        )
      } else {
        let key = that.menuKey++
        return (
          <Menu.Item key={`${key}`}>
            <Link to={v.href as string}>
              {v.icon ? <Icon type={v.icon as string} /> : ""}
              <span>{v.title}</span>
            </Link>
          </Menu.Item>
        )
      }
    })
  }
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }
  render() {
    return (
      <Sider
        collapsible
        collapsed={this.props.collapsed}
        onCollapse={this.props.changeCollapse} //当前张开收起
        width={this.state.width}>
        <div
          className={
            this.props.collapsed ? style.collapsedLogo : style.logo + " flex center v-center"
          }>
          {this.props.collapsed ? (
            <img className={style["collapsed-img"]} src={gImg.common.adminCollapsedLogo} alt="" />
          ) : (
            <img className={style["img"]} src={gImg.common.adminLogo} alt="" />
          )}
        </div>
        {
          <Menu
            theme={this.state.menuConfig.theme}
            mode={this.state.menuConfig.mode}
            defaultOpenKeys={this.props.defaultOpenKeys}
            defaultSelectedKeys={this.props.defaultSelectedKeys}>
            {this.getMenuListDom(this.props.menuList)}
          </Menu>
        }
      </Sider>
    )
  }
}
