import { DbBaseField, Picture } from '@/typings'
import { ListParam } from '@/utils/jsbdk'
import { Overwrite } from 'utility-types'
import { bpost } from './api'
import { SimplePostsCategory } from './postsCategory'
import { UserWithAvatar } from './user'

//公告 资讯对应的id
export const postCategoryNameMapId = {
  news: 0x3,
  notice: 0x4,
}

/// constants
export const postsType: Record<PostsTypeKey, PostsType> = {
  article: 'article', //
  text: 'text',
  int: 'int',
  double: 'double',
  json: 'json',
  pic: 'pic',
  attachment: 'attachment',
  tips: 'tips',
}
export const postsTypeZh = {
  [postsType.article]: '文章',
  [postsType.text]: '字符串',
  [postsType.int]: '整数',
  [postsType.double]: '浮点数',
  [postsType.json]: 'json',
  [postsType.pic]: '图片',
  [postsType.attachment]: '附件',
  [postsType.tips]: '文字提示',
}
export const status: Record<StatusKey, Status> = {
  publish: 'publish', // 发布
  draft: 'draft', // 草稿
  autoDraft: 'autoDraft', // 自动草稿
}
/// type
export type PostsType = 'article' | 'text' | 'int' | 'double' | 'json' | 'pic' | 'attachment' | 'tips'
export type PostsTypeKey = PostsType

export type Status = 'publish' | 'draft' | 'autoDraft'
export type StatusKey = Status
/**
 * 文章
 */
export interface Posts extends DbBaseField {
  sort: number
  viewCount: number
  name: string
  title: string
  content: string
  excerpt: string
  sourceName: string
  sourceUrl: string
  type: PostsType
  status: Status
  picList: Picture[]
  mangerIdList: string[]
  category: SimplePostsCategory // 文章类别
  author: UserWithAvatar // 作者
}

type EditPostsBaseField = Pick<Posts, 'id'>
type AddPostsPicField = { picIdList: number[] }
type AddPostsCategoryField = { category: number }
type AddPostsBaseData = Pick<Posts, 'type'> & AddPostsCategoryField
type AddPostsTextData = AddPostsBaseData & Pick<Posts, 'name' | 'content'>
type AddPostsNumberData = Overwrite<AddPostsTextData, { content: number }>
type AddPostsPicData = AddPostsBaseData & Pick<Posts, 'name'> & AddPostsPicField
type AddPostsArticleData = AddPostsBaseData &
  Pick<Posts, 'title' | 'content' | 'sort' | 'excerpt' | 'sourceName' | 'sourceUrl'> &
  AddPostsPicField
export type AddPostsData = AddPostsTextData | AddPostsPicData | AddPostsArticleData | AddPostsNumberData

export type EditPostsData = (AddPostsTextData | AddPostsNumberData | AddPostsPicData | AddPostsArticleData) &
  EditPostsBaseField

/**
 * 获取文章列表
 */
export async function listPosts(data: ListParam) {
  return bpost<{ list: Posts[] }>({
    url: '/posts/list',
    data,
  })
}
/**
 * 获取文章
 */
export async function detail(data: { id: number; withMangerIdList?: boolean }) {
  return bpost<{ detail: Posts }>({
    url: '/posts/detail',
    data,
  })
}
/**
 * 添加文章
 */
export async function add(data: AddPostsData) {
  return bpost({
    url: '/posts/add',
    data,
  })
}
/**
 * 编辑文章
 */
export async function edit(data: EditPostsData) {
  return bpost({
    url: '/posts/edit',
    data,
  })
}
/**
 * 删除文章
 */
export async function del(ids: number[]) {
  return bpost({
    url: '/posts/del',
    data: {
      ids,
    },
  })
}
/**
 * 添加社区文章
 */
export async function addMangerPost(data: AddPostsData & { mangerIdList: string[] }) {
  return bpost({
    url: '/posts/addMangerPost',
    data,
  })
}
/**
 * 编辑社区文章
 */
export async function editMangerPost(data: EditPostsData & { mangerIdList: string[] }) {
  return bpost({
    url: '/posts/editMangerPost',
    data,
  })
}
