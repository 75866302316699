import { DbBaseField } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { GetListParam } from './api'
export type Scene = 'default' | 'withChildren'
/**
 * 商品分类
 */
export interface GoodsCategory extends DbBaseField {
  pid: number
  name: string
}
export type GoodsCategoryWithChildren = GoodsCategory & { children: GoodsCategoryWithChildren[] }
export type WithChildrenSceneGoodsCategory = GoodsCategoryWithChildren

export async function add(data: Pick<GoodsCategory, 'pid' | 'name'>) {
  return bpost({
    url: 'goods/addCategory',
    data,
  })
}
export async function del(data: { ids: number[] }) {
  return bpost({
    url: 'goods/delCategory',
    data,
  })
}
export async function edit(data: Pick<GoodsCategory, 'id' | 'pid' | 'name'>) {
  return bpost({
    url: 'goods/editCategory',
    data,
  })
}
export async function detail(data: { id: number }) {
  return bpost<{ detail: WithChildrenSceneGoodsCategory }>({
    url: 'goods/categoryDetail',
    data: {
      ...data,
      scene: 'withChildren',
    },
  })
}

export async function list(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'withChildren'
  return bpost<{ list: WithChildrenSceneGoodsCategory[] }>({
    url: 'goods/listCategory',
    data,
  })
}
