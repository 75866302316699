import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { House } from '@/services/house'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import { adminListCommonUser, CommonAdminGetCommonUser } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form, message, Divider, Modal, Input, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { modifyScore } from '@api/score'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
type UserInfo = CommonAdminGetCommonUser
interface State {
  status: boolean
  isChangeScore: boolean
  breadCrumbList: breadCrumbItem[]
  userList: UserInfo[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: UserInfo[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  changeScore: {
    uid: number
    name: string
    score: number
    addOrSub: 'addition' | 'subtraction'
  }
}
//@ts-ignore
@Form.create()
export default class UserList extends Component<Props & FormComponentProps & RouteProps & RouteComponentProps, State> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '昵称',
      dataIndex: 'nick',
    },
    {
      title: '头像',
      dataIndex: 'headImgUrl',
      render: (_: string, record: UserInfo) => {
        return (
          <>
            <img
              className={tableStyle.avatar}
              src={record.wxInfo.headImgUrl ? record.wxInfo.headImgUrl : this.state.defaultAvatar}
              alt="头像"
              onClick={() => {
                this.setState({
                  isShowImg: true,
                  showImages: [record.wxInfo.headImgUrl],
                })
              }}
            />
            <PictureView
              isShowImg={this.state.isShowImg}
              photoIndex={this.state.photoIndex}
              showImages={this.state.showImages}
              onMovePrevRequest={this.onMovePrevRequest}
              onMoveNextRequest={this.onMoveNextRequest}
              onChange={this.onChange}
            />
          </>
        )
      },
    },
    {
      title: '性别',
      dataIndex: 'gender',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
    },
    {
      title: '积分',
      dataIndex: 'score',
    },
    {
      title: '用户类型',
      dataIndex: 'members',
      render: (_: any, record: UserInfo) => {
        let type = '-'
        let arr = record.houses.map(v => {
          return v.type
        })
        if (arr) {
          type = arr.join(' , ')
        }
        return <div>{type}</div>
      },
    },
    {
      title: '房屋数',
      dataIndex: 'houses',
      render: (houses: House[]) => {
        return <div>{houses.length}</div>
      },
    },
    {
      title: '注册时间',
      dataIndex: 'ctime',
      render: (text: string) => {
        return <div>{text && text.substr(0, 10)}</div>
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: UserInfo) => {
        return this.state.status ? (
          <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
            <Link to={pathMap.admin.user.detail + '?id=' + record.id}>查看</Link>
            <Divider type="vertical" />
            <div style={{ cursor: 'pointer' }} onClick={() => this.changeScore(record)}>
              变动积分
            </div>
            <Divider type="vertical" />
            <Link to={pathMap.admin.user.score + '?id=' + record.id}>积分变动记录</Link>
            <Divider type="vertical" />
            <div style={{ cursor: 'pointer' }}>
              <Link to={pathMap.admin.message.add + '?ids=' + [record.id]}>发送消息</Link>
            </div>
            <Divider type="vertical" />
            <Link to={pathMap.admin.repair.list + '?id=' + record.id}>查看报修</Link>
            <Divider type="vertical" />
            <Link to={pathMap.admin.complaint.list + '?id=' + record.id}>查看投诉</Link>
          </div>
        ) : (
          <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
            <Link to={pathMap.admin.user.detail + '?id=' + record.id}>查看</Link>
            <Divider type="vertical" />
            <Link to={pathMap.admin.user.score + '?id=' + record.id}>积分变动记录</Link>
            <Divider type="vertical" />
            <div style={{ cursor: 'pointer' }}>
              <Link to={pathMap.admin.message.add + '?ids=' + [record.id]}>发送消息</Link>
            </div>
            <Divider type="vertical" />
            <Link to={pathMap.admin.repair.list + '?id=' + record.id}>查看报修</Link>
            <Divider type="vertical" />
            <Link to={pathMap.admin.complaint.list + '?id=' + record.id}>查看投诉</Link>
          </div>
        )
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    return {
      status: false,
      isChangeScore: false,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '用户管理', to: pathMap.admin.user.index },
        { name: '用户列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      userList: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
      },
      filterModalIsActive: false,
      filterList: [],
      changeScore: {
        uid: 0,
        name: '',
        score: 0,
        addOrSub: 'addition',
      },
    }
  }

  listUser = async () => {
    try {
      let { pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      let json = await adminListCommonUser({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        userList: json.data.list,
        status: json.data.status,
        pagination,
      })
      this.setState({
        tableIsLoading: false,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  sendMsg = (ids: number[]) => {
    this.props.history.push(pathMap.admin.message.add + '?ids=' + JSON.stringify(ids))
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '发送消息',
              onClick: () => {
                let idArr = []
                for (let v of this.state.selectedRows) {
                  idArr.push(v.id)
                }
                if (idArr.length < 1) {
                  message.error('请选择要发送消息的用户', 2)
                  return
                }
                this.sendMsg(idArr)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
    ]
    let { isChangeScore, changeScore } = this.state
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入姓名|手机号|邮箱...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.userList,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'openid',
                  conditionFieldName: 'openidCondition',
                  type: 'value',
                  valueType: 'string',
                  title: 'openid',
                },
                {
                  valFieldName: 'nick',
                  conditionFieldName: 'nickCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '昵称',
                },
                {
                  valFieldName: 'gender',
                  conditionFieldName: 'genderCondition',
                  type: 'select',
                  title: '性别',
                  selectDataList: [
                    { value: 0, title: '全部' },
                    { value: 'undefined', title: '未知' },
                    { value: 'man', title: '男' },
                    { value: 'woman', title: '女' },
                  ],
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'phone',
                  conditionFieldName: 'phoneCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '手机号码',
                  decoratorOptions: {},
                },
                {
                  valFieldName: 'registerTime',
                  conditionFieldName: 'registerTimeCondition',
                  dateFmt: 'YYYY-MM-DD',
                  outDateFmt: 'YYYY-MM-DD 00:00:00',
                  type: 'time',
                  title: '注册时间',
                },
              ],
            },
          }}
          operationList={operationList}
        />
        <Modal visible={isChangeScore} title="积分变动" onOk={this.changeScoreOk} onCancel={this.changeScoreCancel}>
          <div className="flex a-center" style={{ fontSize: 15, color: '#333', lineHeight: 2 }}>
            用户:
            <div style={{ marginLeft: 20 }}>{changeScore.name}</div>{' '}
          </div>
          <div className="flex a-center" style={{ margin: '12px 0' }}>
            操作:
            <Select
              defaultValue="addition"
              style={{ width: 120, marginLeft: 20 }}
              onChange={(value: 'addition' | 'subtraction') => {
                let { changeScore } = this.state
                changeScore.addOrSub = value
                this.setState({
                  changeScore,
                })
              }}
            >
              <Select.Option value="addition">增加</Select.Option>
              <Select.Option value="subtraction">减少</Select.Option>
            </Select>
          </div>
          <div className="flex a-center">
            积分:
            <Input
              type="number"
              min={0}
              style={{ width: 120, marginLeft: 20 }}
              value={changeScore.score}
              placeholder="请输入积分"
              onChange={evt => {
                let { changeScore } = this.state
                changeScore.score = parseInt(evt.target.value)
                this.setState({
                  changeScore,
                })
              }}
            />
          </div>
        </Modal>
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
  //积分变动
  changeScore = (record: UserInfo) => {
    let { changeScore } = this.state
    changeScore = {
      uid: record.id,
      name: record.nick,
      score: 0,
      addOrSub: 'addition',
    }
    this.setState({
      changeScore,
      isChangeScore: true,
    })
  }
  changeScoreOk = () => {
    let {
      changeScore: { uid, score, addOrSub },
    } = this.state
    if (addOrSub === 'subtraction') {
      score = -score
    }
    modifyScore({ uid, score })
      .then(() => {
        message.success('修改成功', 1, this.init)
        this.changeScoreCancel()
      })
      .catch(err => {
        message.error('修改失败, 错误信息: ' + err.msg, 3)
      })
  }
  changeScoreCancel = () => {
    this.setState({
      isChangeScore: false,
    })
  }
}
