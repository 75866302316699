import pathMap from '@/routes/pathMap'
import { AdminHouse, adminListHouse } from '@/services/house'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import { CommonWxUser, userType } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import qs from 'qs'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
type UserInfo = CommonWxUser
interface State {
  mangerName: string
  breadCrumbList: breadCrumbItem[]
  list: AdminHouse[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: UserInfo[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class HouseList extends Component<Props & RouteComponentProps & FormComponentProps & RouteProps, State> {
  tableColumns = [
    {
      title: '房屋ID',
      dataIndex: 'houseId',
    },
    {
      title: '小区名称',
      dataIndex: 'mangerName',
    },
    {
      title: '房屋地址',
      dataIndex: 'houseAddr',
    },
    {
      title: '用户数量',
      dataIndex: 'userCount',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: AdminHouse) => {
        return <Link to={pathMap.admin.house.detail + '?houseId=' + record.houseId}>查看</Link>
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let mangerName: string = search.name || ''
    return {
      mangerName,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '小区管理', to: pathMap.admin.manger.list },
        { name: '小区房屋列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.after,
          val: '',
        },
        type: {
          condition: conditionType.eqString,
          val: userType.admin,
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }

  listUser = async () => {
    try {
      const { pagination, mangerName } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = {
          mangerName: conditionType.eqString,
          val: mangerName,
        }
      let json = await adminListHouse({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  deleteUser = (ids: number[]) => {
    console.log(ids)
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = []
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入房屋名称...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'houseId',
              pagination: this.state.pagination,
              selectedRows: [],
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
