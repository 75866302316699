import * as appAction from '@redux/actions/app'
import { AppState } from '@redux/stores/app'
import { Action, BuffRoute } from '@/typings'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Dispatch } from 'redux'
import { FmtRoute } from './common/FmtRoute'
interface Props {}
interface State {}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
})
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang))
    },
  }
}
type DefaultProps = { routes: BuffRoute[] } & RouteComponentProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class Basic extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {}
  }
  render() {
    return (
      <div>
        <FmtRoute routes={this.props.routes} />
      </div>
    )
  }
}
