import { getUploadImgUrl } from '@api/api'
import gSass from '@utils/sass'
import { Button, Form, Icon, Input, message, Modal, Spin, Upload } from 'antd'
import { detail, adminEditUser } from '@/services/user'
import { FormComponentProps } from 'antd/lib/form'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { getPicFullUrl } from '@/utils/fn'
// import qs from 'qs'
import React, { Component } from 'react'

const style = gSass.admin.settings.base
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
)
const formItemLayout = {
  labelCol: {
    sm: {
      span: 24,
      offset: 0,
    },
  },
  wrapperCol: {
    sm: {
      span: 24,
      offset: 0,
    },
  },
}
interface Props { }
interface State {
  id: number
  spinning: boolean
  previewVisible: boolean
  previewImage: string
  fileList: any[]
  currNick: string
  userInfo: any
}
type DefaultProps = FormComponentProps
//@ts-ignore
@Form.create()
export default class BaseSettings extends Component<Props & DefaultProps, State> {
  constructor(props: any) {
    super(props)
    // let {
    //   location: { search },
    // } = props
    // const { id } = qs.parse(search, { ignoreQueryPrefix: true })
    this.state = {
      id: 0,
      spinning: true,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      currNick: '',
      userInfo: {},
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
      await this.getUserInfo()
      this.setState({
        spinning: false,
      })
    } catch (e) {
      console.log(e)
      this.setState({
        spinning: false,
      })
    }
  }
  //个人信息获取
  getUserInfo = async () => {
    let { id, fileList } = this.state
    let detailTask = detail({ id })
    let {
      data: { detail: dataDetail },
    } = await detailTask
    const {
      form: { setFieldsValue },
    } = this.props
    setTimeout(() => {
      setFieldsValue({
        nick: dataDetail.nick,
        account: dataDetail.account,
        phone: dataDetail.phone,
        mangerIdList: dataDetail.mangerIdList
      })
    }, 0)
    this.setState({
      currNick: dataDetail.nick,
      userInfo: dataDetail,
    })
    if (dataDetail.avatar) {
      fileList.push({
        url: getPicFullUrl(dataDetail.avatar.url),
        uid: dataDetail.avatar.url,
        id: dataDetail.avatar.id,
      })
    }
    this.setState({
      currNick: dataDetail.nick,
      userInfo: dataDetail,
      fileList,
    })
  }
  //提交
  updateUserInfo = (evt: any) => {
    evt.preventDefault()
    this.props.form.validateFields((err, fieldsValue) => {
      if (err !== null) {
        console.log(err)
        message.warn(err[0].error, 1)
        return
      }
      let avatar
      if (this.state.fileList.length > 0) {
        avatar = {
          url: this.state.fileList[0].url,
          picId: this.state.fileList[0].picId,
          id: this.state.fileList[0].id,
        }
      }
      let data = {
        nick: fieldsValue.nick,
        phone: fieldsValue.phone,
        pwd: fieldsValue.pwd,
        picIdList: avatar
      }
      adminEditUser(data)
        .then(() => {
          message.success("修改成功", 1)
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1)
        })
    })
  }
  //上传头像
  onAvatarChange = ({ file, fileList }: UploadChangeParam) => {
    if (file.status === 'done') {
      fileList = []
      let json = file.response
      if (typeof json === 'object') {
        if (json.code === 0) {
          file.url = json.data.url
            ; (file as UploadFile & { picId: number }).picId = json.data.picId
          fileList.push(file)
        } else {
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      fileList,
    })
  }
  handlePreview = (file: UploadFile) => {
    let previewImage = file.url ? file.url : (file.thumbUrl as string)
    this.setState({
      previewImage,
      previewVisible: true,
    })
  }
  handleCancel = () => this.setState({ previewVisible: false })
  checkNickIsExist = async (nick: string) => {
    console.log(nick)
    return false
    // return await userApi.checkNickIsExist(nick)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    //头像上传按钮
    const { previewVisible, previewImage, fileList } = this.state
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>基本信息</div>
          <Form className={style.form}>
            <Form.Item {...formItemLayout} label="账号">
              {getFieldDecorator('account', {
                initialValue: this.state.userInfo.account,
              })(<Input placeholder="账号" disabled />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="昵称">
              {getFieldDecorator('nick', {
                initialValue: this.state.userInfo.nick,
                rules: [
                  {
                    validator: async (_, val, cb) => {
                      let errors = []
                      if (this.state.currNick !== val) {
                        let isExist = await this.checkNickIsExist(val)
                        if (isExist) {
                          errors.push(new Error('昵称已存在'))
                        }
                      }
                      cb(errors)
                    },
                  },
                ],
              })(<Input placeholder="请输入昵称" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="手机号码">
              {getFieldDecorator('phone', {
                initialValue: this.state.userInfo.phone,
                rules: [{ message: '请输入正确的手机号码', pattern: /^1[3456789]\d{9}$/ }],
              })(<Input placeholder="手机号码" />)}
            </Form.Item>
            <Form.Item label="密码" {...formItemLayout}>
              {getFieldDecorator('pwd', {
                rules: [{ required: false, message: '请输入密码' }],
              })(<Input.Password placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="头像">
              {getFieldDecorator(
                'avatar',
                {},
              )(
                <div className="clearfix">
                  <Upload
                    action={getUploadImgUrl()}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.onAvatarChange}
                    accept="image/*"
                    name="file"
                    data={{ name: 'file' }}
                  >
                    {fileList.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="头像" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </div>,
              )}
            </Form.Item>
            {/* <Form.Item {...formItemLayout} label="个人介绍">
              {getFieldDecorator('profile', { initialValue: this.state.userInfo.profile })(
                <TextArea rows={4} maxLength={500} placeholder="请输入个人介绍" />,
              )}
            </Form.Item> */}
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 0,
                },
              }}
            >
              <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>
                更新基本信息
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    )
  }
}
