import { DbBaseField, Picture } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { AdminUser } from './adminUser'
import { WxUser } from './wxUser'
import { GetListParam } from './api'
import { House } from './house'
export type Scene = 'admin' | 'default' | 'wx' | 'adminGetWxUser'
export const rootUid = 1
/// constant
/**
 * 登录方式
 */
export const userType: Record<UserType, string> = {
  admin: '管理员',
  general: '个人',
}
export const genderMap: Record<Gender, string> = {
  undefined: '未知',
  man: '男',
  woman: '女',
}
/// type
export type UserType = 'admin' | 'general'
export type Gender = 'undefined' | 'man' | 'woman'

/**
 * 用户
 */
export interface User extends DbBaseField {
  age: number
  account: string
  score: number
  pwd: string
  nick: string
  email: string
  profile: string // 简介
  phone: string
  type: UserType
  gender: Gender
  avatar: Picture
}
export type CommonUser = Pick<User, 'id' | 'nick' | 'phone'>
export type UserWithAvatar = CommonUser & { avatar: User['avatar'] }
export type CommonWxUser = Pick<User, 'id' | 'phone' | 'ctime' | 'nick'> & { wxInfo: WxUser }
export type CommonAdminUser = User & AdminUser
export type CommonAdminGetCommonUser = User & { houses: House[] } & { wxInfo: WxUser }

export interface AdminLoginData {
  account: string
  pwd: string
  code: string
  codeKey: string
}
export interface AddUserParam {
  isAdmin: boolean
  account: string
  pwd: string
  rePwd: string
  nick: string
  email: string
  phone: string
  profile: string
}
export interface Detail {
  id?: number
  nick: string
  phone: string
  account: string
  pwd: string
  mangerIdList: string[]
  picIdList: number[]
  avatar?: Picture
}
/**
 *  管理员登录
 */
export async function adminLogin(data: AdminLoginData) {
  return bpost<{ token: string; detail: CommonAdminUser }>({
    url: '/admin/login',
    data,
  })
}
/**
 *  退出登录
 */
export async function logout() {
  return bpost({
    url: '/logout',
  })
}
/**
 * 管理员获取普通用户列表
 */
export async function adminListCommonUser(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'adminGetWxUser'
  return bpost<{ list: CommonAdminGetCommonUser[],status:boolean }>({
    url: 'user/adminListCommonUser',
    data,
  })
}
/**
 * 管理员获取普通用户列表
 */
export async function adminGetCommonUserDetail(data: { id: number }) {
  return bpost<{ detail: CommonAdminGetCommonUser }>({
    url: 'user/adminGetCommonUserDetail',
    data: {
      ...data,
      scene: 'adminGetWxUser',
    },
  })
}
/**
 * 管理员添加用户
 */
export async function adminAddCommonUser(data: Detail) {
  return bpost({
    url: 'user/adminAddCommonUser',
    data: {
      ...data,
      scene: 'adminGetWxUser',
    },
  })
}
/**
 * 管理员编辑用户
 */
export async function adminEditCommonUser(data: Detail) {
  return bpost({
    url: 'user/adminEditCommonUser',
    data: {
      ...data,
      scene: 'adminGetWxUser',
    },
  })
}
/**
 * 管理员编辑用户
 */
export async function adminEditUser(data: any) {
  return bpost({
    url: 'user/adminEditUser',
    data: {
      ...data,
      scene: 'adminGetWxUser',
    },
  })
}
/**
 * 删除用户
 */
export async function deleteUsers(data: { idArr: number[] }) {
  return bpost({
    url: 'user/deleteUsers',
    data: {
      ...data,
      scene: 'adminGetWxUser',
    },
  })
}
export async function detail(data: { id: number }) {
  return bpost<{ detail: Detail }>({
    url: 'user/detail',
    data: {
      ...data,
      scene: 'admin',
    },
  })
}