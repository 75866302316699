import { DbBaseField } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { GetListParam } from './api'
import { CommonWxUser } from './user'
import { AdminSceneGoods } from './goods'
import { House } from './house'
export type ShippingStatus = '待发货' | '已发货'
export type HasUsed = '未使用' | '已使用'
/**
 * 商品订单
 */
export interface GoodsOrder extends DbBaseField {
  goodsId: number
  goodsDetail: AdminSceneGoods
  user: CommonWxUser // 用户
  payScore: number // 支付积分
  standard: string
  houseId: string // 房屋id
  house: House
  count: number
  hasUsed: HasUsed // 是否已使用
}

export async function del(data: { ids: number[] }) {
  return bpost({
    url: 'goods/delOrder',
    data,
  })
}

export async function detail(data: { id: number }) {
  return bpost<{ detail: GoodsOrder }>({
    url: 'goods/orderDetail',
    data: {
      ...data,
      scene: 'admin',
    },
  })
}

export async function list(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'admin'
  return bpost<{ list: GoodsOrder[],count: Number }>({
    url: 'goods/orderList',
    data,
  })
}
// 改变使用状态
export async function changeUsedStatus(data: Pick<GoodsOrder, 'id' | 'hasUsed'>) {
  return bpost({
    url: 'goods/changeUsedStatus',
    data,
  })
}
