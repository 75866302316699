import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { adminGetParking, Parking } from '@/services/outer'
import gSass from '@utils/sass'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
const style = gSass.admin.house.detail
interface Props {}
interface State {
  parkId: string
  breadCrumbList: breadCrumbItem[]
  detail: Parking | null
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
type DefaultProps = RouteComponentProps<{ id: string }> & FormComponentProps

// @ts-ignore
@Form.create()
export default class Detail extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let parkId: string = search.parkId || ''
    return {
      parkId,
      isShowImg: false,
      photoIndex: 0,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '车位列表', to: pathMap.admin.parking.list },
        { name: '车位详情' },
      ],
      detail: null,
      showImages: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    let { parkId } = this.state
    try {
      let {
        data: { detail },
      } = await adminGetParking({ parkId })
      this.setState({ detail })
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    let { detail } = this.state
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          {detail && (
            <div className={style.list}>
              <div className={style.title}>房屋信息</div>
              <div className={csn(style.item, 'flex  flexWrap')}>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>房屋ID</div>
                  <div className={style.detail}>{detail.parkId}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>小区名称</div>
                  <div className={style.detail}>{detail.mangerName}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>停车位地址</div>
                  <div className={style.detail}>{detail.parkAddr}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>车牌号</div>
                  <div className={style.detail}>{detail.carNo}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>用户id</div>
                  <div className={style.detail}>{detail.uid}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>用户昵称</div>
                  <div className={style.detail}>{detail.wxNick}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>用户手机号码</div>
                  <div className={style.detail}>{detail.phone}</div>
                </div>
                <div className={csn(style.term, 'flex')}>
                  <div className={style.label}>用户头像</div>
                  <div className={style.detail}>
                    <img
                      className="img"
                      src={detail.wxAvatar}
                      alt=""
                      onClick={() => {
                        this.setState({
                          isShowImg: true,
                          photoIndex: 0,
                          showImages: detail ? [detail.wxAvatar] : [],
                        })
                      }}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <PictureView
          isShowImg={this.state.isShowImg}
          photoIndex={this.state.photoIndex}
          showImages={this.state.showImages}
          onMovePrevRequest={this.onMovePrevRequest}
          onMoveNextRequest={this.onMoveNextRequest}
          onChange={this.onChange}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
