import { DbBaseField } from '@/typings'
import { bpost } from '@/utils/jsbdk'
import { GetListParam } from './api'
import { CommonWxUser } from './user'
export type Scene = 'default' | 'admin'
/**
 * 消息
 */
export interface Msg extends DbBaseField {
  uid: number // 为0 则发送给所有人
  content: string // 普通字符串
  hasView: 'true' | 'false' // 是否已读
}
export type AdminSceneMsg = Msg & { user: CommonWxUser | null } // 如果uid == 0 那么 user 就是null
export type SendData = Pick<Msg, 'content'> & { uid: number }
export type EditData = Msg

export async function add(data: SendData) {
  return bpost({
    url: 'msg/add',
    data,
  })
}
export async function del(data: { ids: number[] }) {
  return bpost({
    url: 'msg/del',
    data,
  })
}
export async function edit(data: EditData) {
  return bpost({
    url: 'msg/edit',
    data,
  })
}
export async function detail(data: { id: number }) {
  return bpost<{ detail: Msg }>({
    url: 'msg/detail',
    data: {
      ...data,
      scene: 'admin',
    },
  })
}

export async function list(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'admin'
  return bpost<{ list: AdminSceneMsg[] }>({
    url: 'msg/list',
    data,
  })
}
