import { antdUploadImgAction, SESSION_EXPIRE } from '@/config/prod'
import { Region } from '@/typings'
import { getPicFullUrl } from '@/utils/fn'
import { bget, bpost, LocalStorage as storage } from '@utils/jsbdk'

export const updateSessionTime = async () => {
  const adminIsLogin = storage.get('adminIsLogin')
  const userIsLogin = storage.get('userIsLogin')
  if (adminIsLogin === true) {
    storage.set('adminIsLogin', true, SESSION_EXPIRE)
  }
  if (userIsLogin === true) {
    storage.set('userIsLogin', true, SESSION_EXPIRE)
  }
  if (storage.has('uid') && (userIsLogin || adminIsLogin)) {
    storage.set('uid', storage.get('uid'), SESSION_EXPIRE)
  }
  if (storage.has('nick') && (userIsLogin || adminIsLogin)) {
    storage.set('nick', storage.get('nick'), SESSION_EXPIRE)
  }
}

export async function getRegion() {
  if (storage.has('region')) {
    let res: { data: { region: Region[] } } = { data: { region: storage.get('region') } }
    return res
  }
  return bpost<{ region: Region[] }>({ url: '/getRegion' })
}
export const getUploadImgUrl = () => {
  return antdUploadImgAction
}
export interface GetListParam {
  page?: number
  limit?: number
  filter?: Record<string, any>
  order?: Record<string, 'desc' | 'asc'>
  extra?: Record<string, any> // 扩展用来放一些其他参数 比如withSubUsers:true,表示带上所有的子用户
}
// 上传图片
export async function antdUploadImg(formData: FormData) {
  return bpost({
    url: '/antdUploadImg',
    data: formData,
  })
}
// 获取obj-url 图片的blob数据
export function getBlobFromObjectUrl(url: string) {
  return new Promise<Blob>((s, j) => {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function() {
      if (this.status === 200) {
        let blob = this.response
        s(blob)
      }
    }
    xhr.onerror = () => {
      j('get Object.Url failed')
    }
    xhr.send()
  })
}
interface BraftMediaUploadFnParam {
  file: File
  progress: (progress: number) => void
  libraryId: string
  success: (res: {
    url: string
    meta: {
      id: string
      title: string
      alt: string
      loop: boolean
      autoPlay: boolean
      controls: boolean
      poster: string
    }
  }) => void
  error: (err: { msg: string }) => void
}
export async function BraftMediaFn(param: BraftMediaUploadFnParam) {
  let formData = new FormData()
  formData.append('name', 'file')
  formData.append('file', param.file)
  antdUploadImg(formData)
    .then(json => {
      const {
        data: { url, picId, name },
      } = json
      param.success({
        url: getPicFullUrl(url),
        meta: {
          id: picId + '',
          title: name,
          alt: '',
          loop: false, // 指定音视频是否循环播放
          autoPlay: false, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
          poster: '', // 指定视频播放器的封面
        },
      })
    })
    .catch(err => {
      return param.error({
        msg: err,
      })
    })
}
export const notLimit = 0
export { bget }
export { bpost }
export default {}
