import pathMap from '@/routes/pathMap'
import { adminListParking, Parking } from '@/services/outer'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import { userType } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import qs from 'qs'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
const tableStyle = gSass.common.table
interface Props { }
interface RouteProps { }
interface State {
  mangerName: string
  breadCrumbList: breadCrumbItem[]
  list: Parking[]
  tableIsLoading: boolean
  defaultAvatar: string
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class ParkingList extends Component<
Props & RouteComponentProps & FormComponentProps & RouteProps,
State
> {
  tableColumns = [
    {
      title: '车位ID',
      dataIndex: 'parkId',
    },
    {
      title: '车牌号',
      dataIndex: 'carNo',
    },
    {
      title: '小区名称',
      dataIndex: 'mangerName',
    },
    {
      title: '车位地址',
      dataIndex: 'parkAddr',
    },
    {
      title: '用户',
      dataIndex: 'user',
      render: (_: string, record: Parking) => {
        return (
          <Tooltip
            title={
              <div>
                <div>uid:{record.uid}</div>
                <div>手机号码:{record.phone}</div>
              </div>
            }
            placement="left"
          >
            <span style={{ color: '#1890ff' }}>{record.wxNick}</span>
          </Tooltip>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: Parking) => {
        return <Link to={pathMap.admin.parking.detail + '?parkId=' + record.parkId}>查看</Link>
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let mangerName: string = search.name || ''
    return {
      mangerName,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '物业管理', to: pathMap.admin.manger.list },
        { name: '车位列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
        type: {
          condition: conditionType.eqString,
          val: userType.admin,
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }

  listUser = async () => {
    try {
      const { pagination, mangerName } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter;
      if (mangerName) {
        filter.mangerName = {
          condition: conditionType.eqString,
          val: mangerName,
        }
      }
      let json = await adminListParking({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  deleteUser = (ids: number[]) => {
    console.log(ids)
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = []
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入车位ID|小区名|车位地址名称...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'openid',
                  conditionFieldName: 'openidCondition',
                  type: 'value',
                  valueType: 'string',
                  title: 'openid',
                },
                {
                  valFieldName: 'nick',
                  conditionFieldName: 'nickCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '昵称',
                },
                {
                  valFieldName: 'gender',
                  conditionFieldName: 'genderCondition',
                  type: 'select',
                  title: '性别',
                  selectDataList: [
                    { value: 0, title: '全部' },
                    { value: 'undefined', title: '未知' },
                    { value: 'man', title: '男' },
                    { value: 'woman', title: '女' },
                  ],
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'phone',
                  conditionFieldName: 'phoneCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '手机号码',
                  decoratorOptions: {},
                },
                {
                  valFieldName: 'registerTime',
                  conditionFieldName: 'registerTimeCondition',
                  dateFmt: 'YYYY-MM-DD',
                  outDateFmt: 'YYYY-MM-DD 00:00:00',
                  type: 'time',
                  title: '注册时间',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
