import { BuffRouteProps } from '@/typings'
import { Layout } from 'antd'
import React, { PureComponent } from 'react'
import { FmtRoute } from '@/components/common/FmtRoute'
const { Content: AntdContent } = Layout
interface Props extends BuffRouteProps {}
type DefaultProps = {}

export default class Content extends PureComponent<Props & DefaultProps> {
  static defaultProps: DefaultProps

  render() {
    return (
      <AntdContent>
        <FmtRoute routes={this.props.routes} />
      </AntdContent>
    )
  }
}
