export default {
  global: require('@sass/global.scss'),
  common: {
    table: require('@sass/common/table.module.scss'),
    nav: {
      breadCrumb: require('@sass/common/nav/breadCrumb.module.scss'),
    },
    user: {
      detail: require('@sass/admin/common/detail.module.scss'),
    },
  },
  admin: {
    common: {
      sidebar: require('@sass/admin/common/sidebar.module.scss'),
      header: require('@sass/admin/common/header.module.scss'),
      login: require('@sass/admin/common/login.module.scss'),
    },
    master: {
      addUser: require('@sass/admin/master/add.module.scss'),
      detail: require('@sass/admin/master/detail.module.scss'),
    },
    user: {
      addUser: require('@sass/admin/user/add.module.scss'),
      detail: require('@sass/admin/user/detail.module.scss'),
    },
    cms: {
      category: {
        list: require('@sass/admin/cms/category/list.module.scss'),
      },
      logs: {
        edit: require('@sass/admin/cms/logs/edit.module.scss'),
      },
    },
    operation: {
      add: require('@sass/admin/operation/add.module.scss'),
      addGroup: require('@sass/admin/operation/addGroup.module.scss'),
    },
    settings: {
      base: require('@sass/admin/settings/base.module.scss'),
    },
    workplace: require('@sass/admin/workplace.module.scss'),
    house: {
      detail: require('@sass/admin/house/detail.module.scss'),
    },
    integralMall: {
      rule: require('@sass/admin/integralMall/rule.module.scss'),
      category: require('@sass/admin/integralMall/category.module.scss'),
      detail: require('@sass/admin/integralMall/detail.module.scss'),
    },
    message: {
      addOrEdit: require('@sass/admin/message/addOrEdit.module.scss'),
    },
    convenient: {
      addOrEdit: require('@sass/admin/convenientService/addOrEdit.module.scss'),
    },
  },
}
