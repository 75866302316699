import { iotaStr } from "@utils/fn"
const s = iotaStr()
export default {
  app: {
    common: {
      导航: {
        走进我们: s(),
        我们的使命: s(),
        我们的研发: s(),
        我们的产品: s(),
        我们的新闻: s(),
        投资者关系: s(),
      },
    },
    公司名: s(),
    集团名: s(),
    成立时间信息: s(),
    绿色使命: s(),
    公司简介: s(),
  },
}
