import React, { Component } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { Spin } from 'antd'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { LocalStorage as storage } from '@utils/jsbdk'
import * as adminAction from '@redux/actions/admin'
import { AppState } from '@redux/stores/app'
import pathMap from './pathMap'
interface Props {}
interface State {
  isPending: boolean
}
const mapStateToProps = (state: AppState) => ({
  isLogin: state.admin.isLogin,
})
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: (preload: adminAction.AdminLoginPreload) => {
      dispatch(adminAction.login(preload))
    },
    logout: () => {
      dispatch(adminAction.logout())
    },
  }
}
type DefaultProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps & { component: any }
// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class AdminAuth extends Component<Props & DefaultProps, State> {
  checkTimer?: NodeJS.Timeout
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      isPending: true,
    }
  }
  componentDidMount() {
    this.checkIsLogin()
    this.checkTimer = setInterval(() => {
      let isLogin = storage.has('adminIsLogin')
      if (isLogin && !this.props.isLogin) {
        this.props.login({
          uid: storage.get('uid'),
          nick: storage.get('nick'),
        })
      }
      if (!isLogin && this.props.isLogin) {
        this.props.logout()
        storage.remove('uid')
        storage.remove('nick')
      }
      if (this.state.isPending) {
        this.setState({
          isPending: false,
        })
      }
    }, 100)
  }
  checkIsLogin = () => {
    this.setState({
      isPending: true,
    })
  }
  componentWillUnmount() {
    clearInterval(this.checkTimer as NodeJS.Timeout)
  }
  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={() => {
          if (this.state.isPending) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                }}
              >
                <Spin size="large" />
              </div>
            )
          }
          return this.props.isLogin ? <Component {...this.props} /> : <Redirect to={pathMap.admin.login} />
        }}
      />
    )
  }
}
