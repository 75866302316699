export default {
  common: {
    adminLogo: require('@img/common/admin-logo.png'),
    adminCollapsedLogo: require('@img/common/collapsed_logo.png'),
    defaultAvatar: require('@img/common/avatar.png'),
    loginBg: require('@img/common/loginBg.svg'),
  },
  user: {
    defaultAvatar: require('@img/user/default_avatar.jpg'),
  },
  login: {
    loginLogo: require('@img/common/logo.png'),
    keepOnRecord: require('@img/common/keep-on-record.png'),
  },
  home: {
    header: require('@img/home/header.png'),
    footer: require('@img/home/footer.png'),
    wechat: require('@img/home/wechat_qr.png'),
    public: require('@img/home/public_qr.png'),
  },
}
