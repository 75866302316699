import { conditionType, conditionTypeZh, ConditionType } from '@utils/constant'
import { getFmtRegion } from '@utils/fn'
import gSass from '@utils/sass'
import {
  Button,
  Cascader,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Radio,
  Select,
  Slider,
  Table,
} from 'antd'
import { ButtonProps } from 'antd/lib/button'
import Form, { FormComponentProps } from 'antd/lib/form'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import { SearchProps } from 'antd/lib/input'
import { ModalProps } from 'antd/lib/modal'
import RadioGroup from 'antd/lib/radio/group'
import { TableProps } from 'antd/lib/table'
import moment from 'moment'
import React, { Component, ReactNode } from 'react'
import { NavLink, RouteProps } from 'react-router-dom'
import { ListFilter } from '@/utils/jsbdk'
const style = gSass.common.table
const Search = Input.Search
const Option = Select.Option
const RangePicker = DatePicker.RangePicker
/**
 * 操作 比如 新增 删除
 */
const OperationComponent = (props: TableOperation) => {
  if (props.to) {
    return (
      <NavLink className={style.item} to={props.to}>
        <Button>{props.title}</Button>
        {/* <Button {...props.btnProps}>{props.title}</Button> */}
      </NavLink>
    )
  }
  if (props.dropdown) {
    return (
      <Dropdown
        className={style.item}
        overlay={
          <Menu>
            {props.dropdown.menuList.map((menuItem, k) => {
              return (
                <Menu.Item key={k}>
                  <div onClick={menuItem.onClick}>{menuItem.title}</div>
                </Menu.Item>
              )
            })}
          </Menu>
        }
        placement="bottomLeft"
      >
        <Button>
          {props.title}
          {props.btnProps && props.btnProps.rightIconType ? <Icon type={props.btnProps.rightIconType} /> : null}
        </Button>
      </Dropdown>
    )
  }
  return (
    <Button className={style.item} {...props.btnProps}>
      {props.title}
    </Button>
  )
}
export interface TableOperation {
  title: string
  btnProps?: {
    shape?: 'round' | 'circle' | 'circle-outline'
    size?: 'small' | 'default' | 'large'
    icon?: string
    onClick?: () => any
    rightIconType?: string
  } & ButtonProps
  to?: string
  dropdown?: {
    menuList: {
      title: string
      onClick?: () => void
    }[]
  }
}
export interface RadioItemData {
  value: number | string
  title: string
}
export interface SelectItemData {
  value: number | string
  title: string
}
export interface ArrayItemData {
  value: number | string
  title: string
}
export interface FilterItem {
  conditionFieldName?: string
  valFieldName: string
  valFieldPlaceholder?: string
  decoratorOptions?: GetFieldDecoratorOptions
  title: string
  type: 'radio' | 'value' | 'time' | 'array' | 'address' | 'select'
  valueType?: 'int' | 'string' | 'float' | 'bool'
  rangeValMin?: number
  rangeValMax?: number
  selectDataList?: SelectItemData[]
  addressRegion?: Array<any>
  radioDataList?: RadioItemData[]
  dateFmt?: string
  outDateFmt?: string
  arrayDataList?: ArrayItemData[]
}

interface Props {
  componentsProps: {
    search: SearchProps
    table: TableProps<any>
    modal: ModalProps & {
      filterList?: FilterItem[]
      onOutOk?: Function
      closeFilterModal: Function
    }
  }
  operationList: TableOperation[]
}
interface State {
  region: Array<any>
  filterCondition: Record<string, ConditionType>
}
class MyTable extends Component<Props & FormComponentProps & RouteProps, State> {
  defaultDateFmt = 'YYYY-MM-DD HH:mm:ss'
  constructor(props: any) {
    super(props)
    this.state = {
      region: [],
      filterCondition: {},
    }
  }
  async componentDidMount() {
    let region = await getFmtRegion()
    this.setState({
      region,
    })
  }
  /**
   * 生成 int 值的dom
   */
  buildIntValDom = (filterItem: FilterItem): React.ReactNode => {
    const { getFieldDecorator } = this.props.form
    switch (this.state.filterCondition[filterItem.conditionFieldName as string]) {
      case conditionType.betweenValue:
        return (
          <div className={style.age}>
            <div className={style.ageInput}>{filterItem.rangeValMin}</div>
            {getFieldDecorator(filterItem.valFieldName as string, {
              initialValue: [filterItem.rangeValMin, filterItem.rangeValMax],
            })(<Slider className={style.slider} range min={filterItem.rangeValMin} max={filterItem.rangeValMax} />)}
            <div className={style.ageInput}>{filterItem.rangeValMax}</div>
          </div>
        )
      default:
        return getFieldDecorator(
          filterItem.valFieldName,
          {},
        )(
          <InputNumber
            placeholder={filterItem.valFieldPlaceholder}
            min={filterItem.rangeValMin}
            max={filterItem.rangeValMax}
            className={style.input}
          />,
        )
    }
  }
  /**
   * 生成time 值的dom
   */
  buildIntTimeDom = (filterItem: FilterItem): React.ReactNode => {
    const { getFieldDecorator } = this.props.form
    switch (this.state.filterCondition[filterItem.conditionFieldName as string]) {
      case conditionType.before:
        return getFieldDecorator(
          filterItem.valFieldName as string,
          {},
        )(<DatePicker format={filterItem.dateFmt || this.defaultDateFmt} className={style.input} />)
      case conditionType.betweenTime:
        return getFieldDecorator(
          filterItem.valFieldName as string,
          {},
        )(<RangePicker format={filterItem.dateFmt || this.defaultDateFmt} className={style.input} />)
      default:
        return getFieldDecorator(
          filterItem.valFieldName as string,
          {},
        )(<DatePicker format={filterItem.dateFmt || this.defaultDateFmt} className={style.input} />)
    }
  }
  buildFilterDom = (filterItem: FilterItem): ReactNode => {
    const { getFieldDecorator } = this.props.form
    switch (filterItem.type) {
      case 'radio':
        return getFieldDecorator(
          filterItem.valFieldName,
          filterItem.decoratorOptions,
        )(
          <RadioGroup>
            {(filterItem.radioDataList as RadioItemData[]).map((radioItem, k) => {
              return (
                <Radio key={k} value={radioItem.value}>
                  {radioItem.title}
                </Radio>
              )
            })}
          </RadioGroup>,
        )
      case 'select':
        return (
          <>
            {getFieldDecorator(filterItem.conditionFieldName as string, {
              initialValue: conditionType.undefined,
            })(
              <Select className={style.condition}>
                <Option value={conditionType.undefined}>请选择</Option>
                <Option value={conditionType.eq}>{conditionTypeZh[conditionType.eq]}</Option>
                <Option value={conditionType.neq}>{conditionTypeZh[conditionType.neq]}</Option>
              </Select>,
            )}
            {getFieldDecorator(
              filterItem.valFieldName,
              filterItem.decoratorOptions,
            )(
              <Select className={style.input}>
                {(filterItem.selectDataList as SelectItemData[]).map((selectItem, k) => (
                  <Option key={k} value={selectItem.value}>
                    {selectItem.title}
                  </Option>
                ))}
              </Select>,
            )}
          </>
        )
      case 'time':
        return (
          <>
            {getFieldDecorator(filterItem.conditionFieldName as string, {
              initialValue: conditionType.undefined,
            })(
              <Select<ConditionType>
                className={style.condition}
                onChange={val => {
                  let { filterCondition } = this.state
                  filterCondition[filterItem.conditionFieldName as string] = val
                  this.props.form.setFields({
                    [filterItem.valFieldName]: [],
                  })
                  this.setState({
                    filterCondition,
                  })
                }}
              >
                <Option value={conditionType.undefined}>请选择</Option>
                <Option value={conditionType.before}>{conditionTypeZh[conditionType.before]}</Option>
                <Option value={conditionType.after}>{conditionTypeZh[conditionType.after]}</Option>
                <Option value={conditionType.betweenTime}>{conditionTypeZh[conditionType.betweenTime]}</Option>
              </Select>,
            )}
            {this.buildIntTimeDom(filterItem)}
          </>
        )
      case 'value':
        let conditionDecoratorOptions
        if (filterItem.valueType === 'string') {
          conditionDecoratorOptions = {
            initialValue: conditionType.undefined,
          }
          return (
            <>
              {getFieldDecorator(
                filterItem.conditionFieldName as string,
                conditionDecoratorOptions,
              )(
                <Select className={style.condition}>
                  <Option value={conditionType.undefined}>请选择</Option>
                  <Option value={conditionType.eqString}>{conditionTypeZh[conditionType.eqString]}</Option>
                  <Option value={conditionType.like}>{conditionTypeZh[conditionType.like]}</Option>
                  <Option value={conditionType.notLike}>{conditionTypeZh[conditionType.notLike]}</Option>
                </Select>,
              )}
              {getFieldDecorator(
                filterItem.valFieldName,
                filterItem.decoratorOptions || {},
              )(<Input placeholder={filterItem.valFieldPlaceholder || '请输入'} className={style.input} />)}
            </>
          )
        } else if (filterItem.valueType === 'int') {
          return (
            <>
              {getFieldDecorator(filterItem.conditionFieldName as string, {
                initialValue: conditionType.undefined,
                rules: [{ type: 'integer' }],
              })(
                <Select<ConditionType>
                  className={style.condition}
                  onChange={val => {
                    let { filterCondition } = this.state
                    filterCondition[filterItem.conditionFieldName as string] = val
                    this.setState({
                      filterCondition,
                    })
                  }}
                >
                  <Option value={conditionType.undefined}>请选择</Option>
                  <Option value={conditionType.eq}>{conditionTypeZh[conditionType.eq]}</Option>
                  <Option value={conditionType.lt}>{conditionTypeZh[conditionType.lt]}</Option>
                  <Option value={conditionType.gt}>{conditionTypeZh[conditionType.gt]}</Option>
                  <Option value={conditionType.neq}>{conditionTypeZh[conditionType.neq]}</Option>
                  <Option value={conditionType.betweenValue}>{conditionTypeZh[conditionType.betweenValue]}</Option>
                </Select>,
              )}
              {this.buildIntValDom(filterItem)}
            </>
          )
        }
        break
      case 'array':
        return (
          <>
            {getFieldDecorator(filterItem.conditionFieldName as string, {
              initialValue: conditionType.undefined,
            })(
              <Select className={style.condition}>
                <Option value={conditionType.undefined}>请选择</Option>
                <Option value={conditionType.in}>{conditionTypeZh[conditionType.in]}</Option>
                <Option value={conditionType.notIn}>{conditionTypeZh[conditionType.notIn]}</Option>
              </Select>,
            )}
            {getFieldDecorator(
              filterItem.valFieldName,
              {},
            )(
              <Select mode="multiple" placeholder="请选择" className={style.input}>
                {(filterItem.arrayDataList as ArrayItemData[]).map((arrayItem, k) => (
                  <Option key={k} value={arrayItem.value}>
                    {arrayItem.title}
                  </Option>
                ))}
              </Select>,
            )}
          </>
        )
      case 'address':
        return (
          <>
            {getFieldDecorator(filterItem.conditionFieldName as string, {
              initialValue: conditionType.undefined,
            })(
              <Select className={style.condition}>
                <Option value={conditionType.undefined}>请选择</Option>
                <Option value={conditionType.eq}>{conditionTypeZh[conditionType.eq]}</Option>
                <Option value={conditionType.neq}>{conditionTypeZh[conditionType.neq]}</Option>
              </Select>,
            )}
            {getFieldDecorator(
              filterItem.valFieldName,
              filterItem.decoratorOptions,
            )(
              <Cascader
                options={this.state.region}
                className={style.input}
                placeholder={filterItem.valFieldPlaceholder}
              />,
            )}
          </>
        )
    }
    return null
  }
  onFilterSubmit = () => {
    if (!this.props.componentsProps.modal.filterList) {
      return
    }
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(err[Object.keys(err)[0]].errors[0].message, 3)
        return
      }
      let filterRes: ListFilter = {}
      for (let filterItem of this.props.componentsProps.modal.filterList as FilterItem[]) {
        let val = values[filterItem.valFieldName]
        if (filterItem.type === 'time') {
          switch (values[filterItem.conditionFieldName as string]) {
            case conditionType.before:
              val = moment(values[filterItem.valFieldName as string]).format(
                filterItem.outDateFmt || this.defaultDateFmt,
              )
              break
            case conditionType.after:
              val = moment(values[filterItem.valFieldName as string]).format(
                filterItem.outDateFmt || this.defaultDateFmt,
              )
              break
            case conditionType.betweenTime:
              val = [
                moment(values[filterItem.valFieldName as string][0]).format(
                  filterItem.outDateFmt || this.defaultDateFmt,
                ),
                moment(values[filterItem.valFieldName as string][1]).format(
                  filterItem.outDateFmt || this.defaultDateFmt,
                ),
              ]
              break
          }
        }
        filterRes[filterItem.valFieldName] = {
          val,
          condition: values[filterItem.conditionFieldName as string],
        }
      }
      let filter: ListFilter = {}
      for (let [k, v] of Object.entries(filterRes)) {
        if (
          v.condition === undefined ||
          v.condition === conditionType.undefined ||
          v.val === undefined ||
          v.val === ''
        ) {
          continue
        }
        filter[k] = v
      }
      if (this.props.componentsProps.modal.onOutOk) {
        this.props.componentsProps.modal.onOutOk(filter)
      }
      this.props.componentsProps.modal.closeFilterModal()
    })
  }
  resetFilter = () => {
    this.setState({
      filterCondition: {},
    })
    this.props.form.resetFields()
  }
  render() {
    return (
      <div className={style.main}>
        <div className={style.tableHeader}>
          <div className={style.operationList}>
            {this.props.operationList.map((operation, k) => {
              return <OperationComponent key={k} {...operation} />
            })}
          </div>
          <Search {...this.props.componentsProps.search} className={style.search} />
        </div>
        <Table
          className={style.table}
          {...Object.assign(this.props.componentsProps.table, {
            pagination: {
              ...this.props.componentsProps.table.pagination,
              showTotal: (total: number) => `共${total}条数据`,
            },
          })}
        />
        <Modal
          {...this.props.componentsProps.modal}
          onOk={this.onFilterSubmit}
          footer={[
            <Button key="reset" type="danger" style={{ float: 'left' }} onClick={this.resetFilter}>
              重置
            </Button>,
            <Button key="cancel" onClick={this.props.componentsProps.modal.onCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.onFilterSubmit}>
              提交
            </Button>,
          ]}
        >
          <Form className={style.filterList}>
            {this.props.componentsProps.modal.filterList &&
              this.props.componentsProps.modal.filterList.map((filterItem, k) => {
                return (
                  <div key={k} className={style.item}>
                    <div className={style.title}>{filterItem.title}</div>
                    {this.buildFilterDom(filterItem)}
                  </div>
                )
              })}
          </Form>
        </Modal>
      </div>
    )
  }
}
const FormTable: any = Form.create()(MyTable)
export default FormTable
