import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { PostsType, postsType as POSTS_TYPE } from '@api/posts'
import { Config, editConfig, getConfig } from '@api/score'
import gSass from '@utils/sass'
import { Alert, Button, Form, InputNumber, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import 'braft-editor/dist/index.css'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
const style = gSass.admin.integralMall.rule

interface Props {}
interface State {
  breadCrumbList: breadCrumbItem[]
  detail: Config | null
}
const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 3,
      offset: 0,
    },
  },
}

// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class Rule extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '积分商城', to: pathMap.admin.integralMall.rule },
        { name: '积分规则' },
      ],
      detail: null,
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    try {
      let detailTask = getConfig()
      let {
        data: { detail },
      } = await detailTask
      this.setState({
        detail,
      })
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props
    let { detail } = this.state
    return (
      <div className={style.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div className={style.content}>
          <Alert message="积分规则" description="积分规则积分规则积分规则积分规则积分规则" type="info" showIcon />
          <div className={style.list}>
            <div style={{ fontSize: 15, fontWeight: 700, marginLeft: '10%', marginBottom: 8 }}>
              首次注册绑定房产获得对应的积分
            </div>
            <Form.Item {...textLayout} label="首次注册绑定房产">
              {getFieldDecorator('house', {
                rules: [{ required: true, message: '首次注册绑定房产的积分必填' }],
                initialValue: detail ? detail.houseRule && detail.houseRule : 0,
              })(<InputNumber className={style.detail} placeholder="首次注册绑定房产的积分" />)}
            </Form.Item>
            <div style={{ fontSize: 15, fontWeight: 700, marginLeft: '10%', marginBottom: 8 }}>
              连续签到 1-7 天获得对应的积分
            </div>
            <Form.Item {...textLayout} label="连续签到1天">
              {getFieldDecorator('integral1', {
                rules: [{ required: true, message: '连续签到1天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[0] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到1天的积分" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="连续签到2天">
              {getFieldDecorator('integral2', {
                rules: [{ required: true, message: '连续签到2天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[1] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到2天的积分" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="连续签到3天">
              {getFieldDecorator('integral3', {
                rules: [{ required: true, message: '连续签到3天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[2] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到3天的积分" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="连续签到4天">
              {getFieldDecorator('integral4', {
                rules: [{ required: true, message: '连续签到4天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[3] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到4天的积分" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="连续签到5天">
              {getFieldDecorator('integral5', {
                rules: [{ required: true, message: '连续签到5天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[4] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到5天的积分" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="连续签到6天">
              {getFieldDecorator('integral6', {
                rules: [{ required: true, message: '连续签到6天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[5] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到6天的积分" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="连续签到7天">
              {getFieldDecorator('integral7', {
                rules: [{ required: true, message: '连续签到7天的积分必填' }],
                initialValue: detail ? detail.signInRule && detail.signInRule[6] : 0,
              })(<InputNumber className={style.detail} placeholder="连续签到7天的积分" />)}
            </Form.Item>
            <div style={{ fontSize: 15, fontWeight: 700, marginLeft: '10%', marginTop: 15, marginBottom: 8 }}>
              物业缴费 (多少) 元对应增加 (多少) 积分
            </div>
            <Form.Item {...textLayout} label="缴费(元)">
              {getFieldDecorator('money', {
                rules: [{ required: true, message: '缴费必填' }],
                initialValue: detail ? detail.payRule.money : 0,
              })(<InputNumber className={style.detail} placeholder="请输入缴费金额" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="积分">
              {getFieldDecorator('integral', {
                rules: [{ required: true, message: '积分必填' }],
                initialValue: detail ? detail.payRule.score : 0,
              })(<InputNumber className={style.detail} placeholder="请输入积分" />)}
            </Form.Item>
            <div style={{ fontSize: 15, fontWeight: 700, marginLeft: '10%', marginTop: 15, marginBottom: 8 }}>
              停车缴费 (多少) 元对应增加 (多少) 积分
            </div>
            <Form.Item {...textLayout} label="缴费(元)">
              {getFieldDecorator('parkMoney', {
                rules: [{ required: true, message: '缴费必填' }],
                initialValue: detail && detail.parkPayRule ? detail.parkPayRule.money : 0,
              })(<InputNumber className={style.detail} placeholder="请输入缴费金额" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="积分">
              {getFieldDecorator('parkIntegral', {
                rules: [{ required: true, message: '积分必填' }],
                initialValue: detail && detail.parkPayRule ? detail.parkPayRule.score : 0,
              })(<InputNumber className={style.detail} placeholder="请输入积分" />)}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 12,
                },
              }}
            >
              <Button className={style.btn} size="large" type="primary" onClick={this.formSubmit}>
                确认编辑
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    )
  }
  formSubmit = () => {
    let validFields: string[] = [
      'house',
      'integral1',
      'integral2',
      'integral3',
      'integral4',
      'integral5',
      'integral6',
      'integral7',
      'integral',
      'money',
      'parkIntegral',
      'parkMoney',
    ]
    this.props.form.validateFields(validFields, async (err, fieldsValue) => {
      if (err) {
        return
      }
      let config: Config = {
        signInRule: [
          fieldsValue.integral1,
          fieldsValue.integral2,
          fieldsValue.integral3,
          fieldsValue.integral4,
          fieldsValue.integral5,
          fieldsValue.integral6,
          fieldsValue.integral7,
        ],
        houseRule: fieldsValue.house,
        // 支付规则
        payRule: {
          money: fieldsValue.money,
          score: fieldsValue.integral,
        },
        parkPayRule: {
          money: fieldsValue.parkMoney,
          score: fieldsValue.parkIntegral,
        },
      }
      editConfig({ config })
        .then(() => {
          message.success('修改成功', 1, this.init)
        })
        .catch(err => {
          message.error('修改失败, 错误信息:' + err.msg, 3)
        })
    })
  }
}
