import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { adminAddCommonUser, adminEditCommonUser, detail, Detail } from '@/services/user'
import { getUploadImgUrl } from '@/services/api'
import { WithChildrenSceneGoodsCategory } from '@/services/goodsCategory'
import { listManger, Manger } from '@/services/outer'
import { getPicFullUrl } from '@/utils/fn'
import { Posts, PostsType, postsType as POSTS_TYPE } from '@api/posts'
import { Button, Form, Icon, Input, message, Modal, Select, TreeSelect, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { Picture } from '@/typings'
import 'braft-editor/dist/index.css'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
const TreeNode = TreeSelect.TreeNode

const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 5,
      offset: 0,
    },
  },
}
const imgListLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 6,
      offset: 0,
    },
  },
}

type Mode = 'add' | 'edit'
interface Props {
  mode: Mode
}
interface State {
  id: number
  isAdmin: boolean
  previewVisible: boolean
  previewImage: string
  postsPicList: UploadFile[]
  mangerList: Manger[]
  detail: Detail | null
  breadCrumbList: breadCrumbItem[]
}

// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id) || 0
    return {
      id,
      isAdmin: false,
      previewVisible: false,
      previewImage: '',
      postsPicList: [],
      mangerList: [],
      detail: null,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '用户列表', to: pathMap.admin.user.list },
        { name: '添加/编辑' },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    let mangerListTask = listManger()
    const {
      data: { list: mangerList },
    } = await mangerListTask
    this.setState({
      mangerList: [
        {
          id: '0',
          name: '全部',
        },
        ...mangerList,
      ],
    })
    const {
      mode,
      form: { setFieldsValue },
    } = this.props
    console.log(mode)
    if (mode === 'edit') {
      let { id } = this.state
      let detailTask = detail({ id })
      let {
        data: { detail: dataDetail },
      } = await detailTask
      let avatar: Picture[] = dataDetail.avatar ? [dataDetail.avatar] : []
      this.setPicList(avatar)
      setTimeout(() => {
        setFieldsValue({
          nick: dataDetail.nick,
          account: dataDetail.account,
          phone: dataDetail.phone,
          mangerIdList: dataDetail.mangerIdList
        })
      }, 0)
      this.setState({
        detail: dataDetail,
      })
    }
  }
  setPicList = (picList: Posts['picList']) => {
    if (picList) {
      let { postsPicList } = this.state
      postsPicList = []
      for (let pic of picList) {
        ; (postsPicList as (UploadFile & { picId: number })[]).push({
          name: pic.title,
          uid: pic.id + '',
          url: getPicFullUrl(pic.url),
          type: 'image',
          size: 0,
          picId: pic.id,
        })
      }
      this.setState({ postsPicList })
    }
  }
  renderTreeNodes = (data: WithChildrenSceneGoodsCategory[]) =>
    data.map(category => {
      return (
        <TreeNode key={category.id + ''} title={category.name} value={category.id} dataRef={category}>
          {this.renderTreeNodes(category.children || [])}
        </TreeNode>
      )
    })

  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      mode,
    } = this.props
    let { postsPicList, mangerList, detail } = this.state
    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>
          <Form>
            <Form.Item label="用户名" {...textLayout}>
              {getFieldDecorator('nick', {
                rules: [{ required: false, message: '请输入用户名称' }],
                initialValue: detail && detail.nick,
              })(<Input placeholder="请输入" />)}
            </Form.Item>

            <Form.Item label="手机号" {...textLayout}>
              {getFieldDecorator('phone', {
                rules: [{ required: false, message: '请输入手机号码' }],
                initialValue: detail && detail.phone,
              })(<Input placeholder="请输入" />)}
            </Form.Item>
            <Form.Item label="账户" {...textLayout}>
              {getFieldDecorator('account', {
                rules: [{ required: true, message: '请输入账户' }],
                initialValue: detail && detail.account,
              })(<Input min={0} placeholder="请输入" />)}
            </Form.Item>
            <Form.Item label="密码" {...textLayout}>
              {getFieldDecorator('pwd', {
                rules: [{ required: this.props.mode === 'edit' ? false : true, message: '请输入密码' }],
              })(<Input.Password placeholder="请输入"/>)}
            </Form.Item>
            <Form.Item label="小区" {...textLayout}>
              {getFieldDecorator('mangerIdList', {
                rules: [{ required: true, message: '请选择小区' }],
                initialValue: mode === 'edit' ? detail && detail.mangerIdList : ['0'],
              })(
                <Select mode="multiple" placeholder="请选择">
                  {mangerList.map(v => {
                    return (
                      <Select.Option key={v.name} value={v.id}>
                        {v.name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...imgListLayout} label="头像">
              <Upload
                action={getUploadImgUrl()}
                listType="picture-card"
                fileList={postsPicList}
                onPreview={this.handlePreview}
                onChange={this.onPostsPicChange}
                accept="image/*"
                name="file"
                data={{ name: 'file' }}
              >
                {postsPicList.length > 0 ? null : (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">上传图片</div>
                  </div>
                )}
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 6,
                },
              }}
            >
              <Button size="large" type="primary" onClick={this.formSubmit}>
                {this.props.mode === 'add' ? '添加' : '保存'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                size="large"
                type="primary"
                ghost
                onClick={() => {
                  this.props.history.goBack()
                }}
              >
                返回
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
  onPostsPicChange = ({ file, fileList: postsPicList }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code !== 0) {
          postsPicList.splice(postsPicList.length - 1, 1)
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        } else {
          ; (postsPicList[postsPicList.length - 1] as UploadFile & { picId: number }).picId = json.data.picId
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      postsPicList,
    })
  }
  getValidFields = () => {
    let validFields = ['nick', 'phone', 'account', 'pwd', 'mangerIdList']
    return validFields
  }
  formSubmit = () => {
    const { postsPicList } = this.state
    const { mode } = this.props
    let validFields = this.getValidFields()
    this.props.form.validateFields(validFields, async(err, fieldsValue) => {
      if (err) {
        return
      }
      let picIdList: number[] = []
      for (let pic of postsPicList) {
        picIdList.push((pic as UploadFile & { picId: number }).picId)
      }
      if (mode === 'edit') {
        let data = {
          id: this.state.id,
          nick: fieldsValue.nick,
          phone: fieldsValue.phone,
          account: fieldsValue.account,
          pwd: fieldsValue.pwd,
          mangerIdList: fieldsValue.mangerIdList || [],
          picIdList,
        }
        adminEditCommonUser(data)
          .then(_ => {
            message.success('编辑成功', 1)
          })
          .catch(err => {
            message.error('编辑失败,失败原因: ' + err.msg, 3)
          })
      } else {
        let data = {
          nick: fieldsValue.nick,
          phone: fieldsValue.phone,
          account: fieldsValue.account,
          pwd: fieldsValue.pwd,
          mangerIdList: fieldsValue.mangerIdList || [],
          picIdList,
        }
        adminAddCommonUser(data)
          .then(_ => {
            this.props.form.resetFields()
            message.success('添加成功', 1)
          })
          .catch(err => {
            message.error('添加失败,失败原因: ' + err.msg, 3)
          })
      }
    })
  }
  roundData = (arr: number[]) => {
    let newData = Math.floor(Math.random() * 100)
    for (let v of arr) {
      if (v === newData) {
        this.roundData(arr)
      }
    }
    return newData
  }
}
