import pathMap from '@/routes/pathMap'
import { listRepair, Repair } from '@/services/outer'
import { ListFilter } from '@/utils/jsbdk'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Form, Modal } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
type Item = Repair
interface State {
  uid: number
  breadCrumbList: breadCrumbItem[]
  list: Item[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: Item[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class RepairList extends Component<
  Props & FormComponentProps & RouteProps & RouteComponentProps,
  State
> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '小区名',
      dataIndex: 'mangerName',
    },
    {
      title: '报修人',
      dataIndex: 'name',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
    },
    {
      title: '报修内容',
      dataIndex: 'content',
      render: (content: string) => {
        return (
          <div
            style={content.length > 20 ? { color: '#0089ff', cursor: 'pointer' } : {}}
            onClick={() => {
              if (content.length > 20) {
                Modal.info({
                  title: '报修内容',
                  content: <div> {content}</div>,
                  maskClosable: true,
                  onOk() {},
                })
              }
            }}
          >
            {content.length > 20 ? content.substr(0, 20) + '...' : content}
          </div>
        )
      },
    },
    {
      title: '地址',
      dataIndex: 'address',
    },
    {
      title: '维修状态',
      dataIndex: 'status',
    },
    {
      title: '期望上门时间',
      dataIndex: 'exBookTime',
      render: (_: string, record: Item) => {
        return (
          <div>
            {record.exBookTime} {record.exNoon}
          </div>
        )
      },
    },
    {
      title: '报修时间',
      dataIndex: 'submitTime',
    },
    // {
    //   title: '操作',
    //   dataIndex: 'operation',
    //   render: (_: string, record: Item) => {
    //     return (
    //       <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
    //         <Link to={pathMap.admin.repair.detail + '?id=' + record.id}>查看</Link>
    //       </div>
    //     )
    //   },
    // },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let uid: number = parseInt(search.id) || 0
    return {
      uid,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '用户管理', to: pathMap.admin.user.index },
        { name: '报修列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {},
      filterModalIsActive: false,
      filterList: [],
    }
  }

  listUser = async () => {
    try {
      let { pagination, uid } = this.state
      let json = await listRepair({ uid })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
      this.setState({
        tableIsLoading: false,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  sendMsg = (ids: number[]) => {
    this.props.history.push(pathMap.admin.message.add + '?ids=' + JSON.stringify(ids))
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      // {
      //   title: '筛选',
      //   btnProps: {
      //     icon: 'filter',
      //     type: 'primary',
      //     onClick: () => {
      //       this.setState({
      //         filterModalIsActive: true,
      //       })
      //     },
      //   },
      // },
    ]
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入小区名|房屋名|报修人名称|手机号码..',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              // rowSelection: {
              //   onChange: (_: any, selectedRows: any) => {
              //     this.setState({
              //       selectedRows,
              //     })
              //   },
              // },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'mangerName',
                  conditionFieldName: 'mangerNameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '小区名',
                },
                {
                  valFieldName: 'houseName',
                  conditionFieldName: 'houseNameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '房屋名',
                },
                {
                  valFieldName: 'user',
                  conditionFieldName: 'userCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '报修人姓名',
                },
                {
                  valFieldName: 'phone',
                  conditionFieldName: 'phoneCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '手机号码',
                  decoratorOptions: {},
                },
                {
                  valFieldName: 'status',
                  conditionFieldName: 'statusCondition',
                  type: 'select',
                  title: '维修状态',
                  selectDataList: [
                    { value: 0, title: '全部' },
                    { value: '维修下单', title: '维修下单' },
                    { value: '维修中', title: '维修中' },
                    { value: '服务完成', title: '服务完成' },
                    { value: '服务评价', title: '服务评价' },
                  ],
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'ctime',
                  conditionFieldName: 'ctimeCondition',
                  dateFmt: 'YYYY-MM-DD',
                  outDateFmt: 'YYYY-MM-DD 00:00:00',
                  type: 'time',
                  title: '报修时间',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
