import pathMap from '@/routes/pathMap'
import { MenuItem } from '@/typings'
import moment from 'moment'
let buildTime = ''
if (process.env.NODE_ENV === 'production') {
  // console.log(`构建时间: ${process.env.REACT_APP_BUILD_TIME}`)
  localStorage.setItem('buildTime', process.env.REACT_APP_BUILD_TIME || '')
  buildTime = moment(process.env.REACT_APP_BUILD_TIME).format('MM-DD HH:mm')
}
const developer: MenuItem = {
  // icon: ["paper-clip", "dev"],
  icon: 'paper-clip',
  title: `开发者 ${buildTime || ''}`,
  child: [
    {
      href: '',
      title: 'CMS',
      child: [
        {
          href: pathMap.admin.post.category.index,
          title: '分类',
          child: null,
        },
        {
          href: pathMap.admin.post.index,
          title: '内容',
          child: null,
        },
      ],
    },
    {
      href: '/',
      title: '权限管理',
      child: [
        {
          href: pathMap.admin.operation.index,
          title: '操作列表',
          child: null,
        },
        {
          href: pathMap.admin.operation.add,
          title: '添加操作',
          child: null,
        },
        {
          href: pathMap.admin.operation.group.index,
          title: '操作组列表',
          child: null,
        },
        {
          href: pathMap.admin.operation.group.add,
          title: '添加操作组',
          child: null,
        },
      ],
    },
  ],
}
const user: MenuItem = {
  icon: 'user',
  href: '',
  title: '用户管理',
  child: [
    {
      href: pathMap.admin.user.index,
      title: '用户列表',
      child: null,
    },
    {
      href: pathMap.admin.user.add,
      title: '添加管理员',
      child: null,
    },
    {
      href: pathMap.admin.master.index,
      title: '管理员列表',
      child: null,
    },
    {
      href: pathMap.admin.user.score,
      title: '积分记录',
      child: null,
    },
  ],
}
const userMaster: MenuItem = {
  icon: 'user',
  href: '',
  title: '用户管理',
  child: [
    {
      href: pathMap.admin.user.index,
      title: '用户列表',
      child: null,
    },
    {
      href: pathMap.admin.user.score,
      title: '积分记录',
      child: null,
    },
  ],
}
const pay: MenuItem = {
  icon: 'profile',
  href: '',
  title: '缴费记录',
  child: [
    {
      href: pathMap.admin.pay.index,
      title: '缴费记录列表',
      child: null,
    },
    {
      href: pathMap.admin.pay.rule,
      title: '缴费通知配置',
      child: null,
    },
  ],
}
const pay2: MenuItem = {
  icon: 'profile',
  href: '',
  title: '缴费记录',
  child: [
    {
      href: pathMap.admin.pay.index,
      title: '缴费记录列表',
      child: null,
    },
  ],
}
const apply: MenuItem = {
  icon: 'diff',
  href: '',
  title: '申请管理',
  child: [
    {
      href: pathMap.admin.apply.index,
      title: '申请列表',
      child: null,
    },
  ],
}
const house: MenuItem = {
  icon: 'reconciliation',
  href: '',
  title: '物业管理',
  child: [
    {
      href: pathMap.admin.manger.list,
      title: '小区列表',
      child: null,
    },
    {
      href: pathMap.admin.parking.list,
      title: '车位列表',
      child: null,
    },
  ],
}
/**
 * 资讯管理
 */
const News: MenuItem = {
  icon: 'book',
  href: '',
  title: '社区资讯管理',
  child: [
    {
      href: pathMap.admin.news.list,
      title: '社区资讯列表',
      child: null,
    },
    {
      href: pathMap.admin.news.add,
      title: '添加社区资讯',
      child: null,
    },
  ],
}
/**
 * 公告管理
 */
const Notice: MenuItem = {
  icon: 'sound',
  href: '',
  title: '公告管理',
  child: [
    {
      href: pathMap.admin.notice.list,
      title: '公告列表',
      child: null,
    },
    {
      href: pathMap.admin.notice.add,
      title: '添加公告',
      child: null,
    },
  ],
}
/**
 * 积分规则
 */
const IntegralMall: MenuItem = {
  icon: 'home',
  href: '',
  title: '积分商城',
  child: [
    {
      href: pathMap.admin.integralMall.rule,
      title: '积分规则',
      child: null,
    },
    {
      href: pathMap.admin.integralMall.category,
      title: '商品分类',
      child: null,
    },
    {
      href: pathMap.admin.integralMall.product,
      title: '积分商品',
      child: null,
    },
    {
      href: pathMap.admin.integralMall.add,
      title: '添加商品',
      child: null,
    },
  ],
}
/**
 * 消息
 */
const message: MenuItem = {
  icon: 'mail',
  href: '',
  title: '消息管理',
  child: [
    {
      href: pathMap.admin.message.list,
      title: '消息列表',
      child: null,
    },
  ],
}
/**
 * 商品管理
 */
const order: MenuItem = {
  icon: 'ordered-list',
  href: '',
  title: '订单管理',
  child: [
    {
      href: pathMap.admin.order.list,
      title: '订单列表',
      child: null,
    },
  ],
}
export const master: MenuItem = {
  icon: 'deployment-unit',
  href: '',
  title: '管理员',
  child: [
    {
      href: pathMap.admin.master.index,
      title: '管理员列表',
      child: null,
    },
    {
      href: pathMap.admin.master.add,
      title: '添加管理员',
      child: null,
    },
  ],
}
const convenientService: MenuItem = {
  icon: 'gift',
  href: '',
  title: '便民服务',
  child: [
    {
      href: pathMap.admin.convenientService.index,
      title: '便民服务列表',
      child: null,
    },
    {
      href: pathMap.admin.convenientService.add,
      title: '添加便民服务',
      child: null,
    },
  ],
}

export const menuList = [
  /**
   * 开发者
   */
  developer,
  // master,
  user,
  house,
  apply,
  IntegralMall,
  convenientService,
  pay,
  order,
  message,
  News,
  Notice,
]
export function getMenuList(isRoot: boolean) {
  if (isRoot) {
    return menuList
  } else {
    return [
      userMaster,
      house,
      apply,
      convenientService,
      pay2,
      order,
      message,
      News,
      Notice,
    ]
  }
}
