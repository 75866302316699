import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { BraftMediaFn, getUploadImgUrl, notLimit } from '@/services/api'
import { AddData, AdminSceneGoods, detail, edit, EditData, PriceInfo, add } from '@/services/goods'
import { list, WithChildrenSceneGoodsCategory } from '@/services/goodsCategory'
import { listManger, Manger } from '@/services/outer'
import { getPicFullUrl } from '@/utils/fn'
import { Posts, PostsType, postsType as POSTS_TYPE } from '@api/posts'
import { Button, Form, Icon, Input, InputNumber, message, Modal, Select, TreeSelect, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import BraftEditor, { EditorState } from 'braft-editor'
import 'braft-editor/dist/index.css'
import { ContentUtils } from 'braft-utils'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
const TreeNode = TreeSelect.TreeNode

const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 5,
      offset: 0,
    },
  },
}
const imgListLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 6,
      offset: 0,
    },
  },
}
const htmlEditLayout = {
  labelCol: {
    sm: {
      span: 1,
      offset: 0,
    },
    xxl: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 23,
      offset: 0,
    },
    xxl: {
      span: 18,
      offset: 0,
    },
  },
}

type Mode = 'add' | 'edit'
interface Props {
  mode: Mode
}
interface State {
  id: number
  priceInfoCount: number[]
  previewVisible: boolean
  previewImage: string
  editorState: EditorState
  productCategoryList: WithChildrenSceneGoodsCategory[]
  postsPicList: UploadFile[]
  mangerList: Manger[]
  detail: AdminSceneGoods | null
  breadCrumbList: breadCrumbItem[]
}

// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let id: number = parseInt(search.id) || 0
    return {
      id,
      priceInfoCount: [1],
      previewVisible: false,
      previewImage: '',
      productCategoryList: [],
      postsPicList: [],
      mangerList: [],
      editorState: BraftEditor.createEditorState(null),
      detail: null,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '积分商品', to: pathMap.admin.integralMall.product },
        { name: '添加/编辑' },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    let categoryListTask = list({ page: notLimit, limit: notLimit, filter: {} })
    let mangerListTask = listManger()
    const {
      data: { list: mangerList },
    } = await mangerListTask
    const {
      data: { list: productCategoryList },
    } = await categoryListTask
    this.setState({
      productCategoryList,
      mangerList: [
        {
          id: '0',
          name: '全部',
        },
        ...mangerList,
      ],
    })
    const {
      mode,
      form: { setFieldsValue },
    } = this.props
    if (mode === 'edit') {
      let { id } = this.state
      let detailTask = detail({ id })
      let {
        data: { detail: dataDetail },
      } = await detailTask
      this.setPicList(dataDetail.picList)
      let priceInfoCount: number[] = []
      for (let i = 1; i <= dataDetail.priceInfo.length; i++) {
        priceInfoCount.push(i)
        setTimeout(() => {
          setFieldsValue({
            ['standard' + i]: dataDetail.priceInfo[i - 1].standard,
            ['score' + i]: dataDetail.priceInfo[i - 1].score,
            ['description' + i]: dataDetail.priceInfo[i - 1].description,
          })
        }, 0)
      }
      this.setState({
        detail: dataDetail,
        priceInfoCount,
        editorState: BraftEditor.createEditorState(dataDetail.content),
      })
    }
  }
  setPicList = (picList: Posts['picList']) => {
    if (picList) {
      let { postsPicList } = this.state
      postsPicList = []
      for (let pic of picList) {
        ;(postsPicList as (UploadFile & { picId: number })[]).push({
          name: pic.title,
          uid: pic.id + '',
          url: getPicFullUrl(pic.url),
          type: 'image',
          size: 0,
          picId: pic.id,
        })
      }
      this.setState({ postsPicList })
    }
  }
  renderTreeNodes = (data: WithChildrenSceneGoodsCategory[]) =>
    data.map(category => {
      return (
        <TreeNode key={category.id + ''} title={category.name} value={category.id} dataRef={category}>
          {this.renderTreeNodes(category.children || [])}
        </TreeNode>
      )
    })

  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      mode,
    } = this.props
    let { postsPicList, editorState, mangerList, detail, priceInfoCount } = this.state
    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>
          <Form>
            <Form.Item label="商品分类" {...textLayout}>
              {getFieldDecorator('category', {
                rules: [{ required: true, message: '请选择商品分类' }],
                initialValue: detail && detail.categoryId,
              })(
                <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="请选择商品分类"
                  allowClear
                  treeDefaultExpandAll
                >
                  {this.renderTreeNodes(this.state.productCategoryList)}
                </TreeSelect>,
              )}
            </Form.Item>
            <Form.Item label="小区" {...textLayout}>
              {getFieldDecorator('mangerIdList', {
                rules: [{ required: true, message: '请选择小区' }],
                initialValue: mode === 'edit' ? detail && detail.mangerIdList : ['0'],
              })(
                <Select mode="multiple" placeholder="请选择">
                  {mangerList.map(v => {
                    return (
                      <Select.Option key={v.name} value={v.id}>
                        {v.name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="商品名称" {...textLayout}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入商品名称' }],
                initialValue: detail && detail.name,
              })(<Input placeholder="请输入" />)}
            </Form.Item>
            <Form.Item label="库存" {...textLayout}>
              {getFieldDecorator('stock', {
                rules: [{ required: true, message: '请输入商品名称' }],
                initialValue: detail && detail.stock,
              })(<InputNumber min={0} placeholder="0" />)}
            </Form.Item>
            <Form.Item label="规格信息" {...textLayout}></Form.Item>
            <div style={{ marginLeft: 40 }}>
              {priceInfoCount.map((v, idx) => {
                return (
                  <div key={'price-info-' + idx} style={{ marginBottom: 40, position: 'relative' }}>
                    <Icon
                      style={{
                        position: 'absolute',
                        left: '48%',
                        top: 7,
                        color: 'red',
                        borderRadius: '50%',
                        border: '1px solid red',
                        padding: 3,
                        zIndex: 2,
                        cursor: 'pointer',
                      }}
                      type="close"
                      onClick={() => {
                        let { priceInfoCount } = this.state
                        priceInfoCount = priceInfoCount.filter(item => {
                          return v !== item
                        })
                        this.setState({
                          priceInfoCount,
                        })
                      }}
                    />
                    <Form.Item label={'规格' + (idx + 1)} {...textLayout}>
                      {getFieldDecorator('standard' + v, {
                        rules: [{ required: true, message: '请输入规格' }],
                      })(<Input min={0} placeholder="请输入规格" />)}
                    </Form.Item>
                    <Form.Item label="积分" {...textLayout}>
                      {getFieldDecorator('score' + v, {
                        rules: [{ required: true, message: '请输入积分' }],
                      })(<InputNumber min={0} placeholder="0" />)}
                    </Form.Item>
                    <Form.Item label="描述" {...textLayout}>
                      {getFieldDecorator('description' + v, {})(<TextArea rows={3} placeholder="请输入描述" />)}
                    </Form.Item>
                  </div>
                )
              })}
              <div
                style={{
                  width: 120,
                  height: 40,
                  marginLeft: '25%',
                  marginBottom: 30,
                  textAlign: 'center',
                  lineHeight: '40px',
                  fontSize: 16,
                  color: '#fff',
                  background: '#0089ff',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  let { priceInfoCount } = this.state
                  if (priceInfoCount.length >= 1) {
                    let newData = this.roundData(priceInfoCount)
                    priceInfoCount.push(newData)
                  } else {
                    priceInfoCount.push(1)
                  }
                  this.setState({
                    priceInfoCount,
                  })
                }}
              >
                + 添加规格
              </div>
            </div>
            <Form.Item {...imgListLayout} label="图片列表">
              <Upload
                action={getUploadImgUrl()}
                listType="picture-card"
                fileList={postsPicList}
                onPreview={this.handlePreview}
                onChange={this.onPostsPicChange}
                accept="image/*"
                name="file"
                data={{ name: 'file' }}
              >
                {postsPicList.length > 8 ? null : (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">上传图片</div>
                  </div>
                )}
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
            </Form.Item>
            <Form.Item {...htmlEditLayout} label="商品介绍">
              <BraftEditor
                style={{
                  border: 'solid 1px #333',
                  maxWidth: 1000,
                }}
                media={{
                  uploadFn: BraftMediaFn,
                }}
                value={editorState}
                onChange={editorState => {
                  this.setState({ editorState })
                }}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 12,
                },
              }}
            >
              <Button size="large" type="primary" onClick={this.formSubmit}>
                {this.props.mode === 'add' ? '添加' : '保存'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                size="large"
                type="primary"
                ghost
                onClick={() => {
                  this.props.history.goBack()
                }}
              >
                返回
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
  onPostsPicChange = ({ file, fileList: postsPicList }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code !== 0) {
          postsPicList.splice(postsPicList.length - 1, 1)
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        } else {
          ;(postsPicList[postsPicList.length - 1] as UploadFile & { picId: number }).picId = json.data.picId
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      postsPicList,
    })
  }
  clearContent = () => {
    this.setState({
      editorState: ContentUtils.clear(this.state.editorState),
    })
  }
  getValidFields = () => {
    let { priceInfoCount } = this.state
    let validFields = ['category', 'mangerIdList', 'name', 'stock', 'score', 'buyCount']
    for (let v of priceInfoCount) {
      validFields.push('standard' + v)
      validFields.push('description' + v)
      validFields.push('score' + v)
    }
    return validFields
  }
  formSubmit = () => {
    const { postsPicList, id, editorState } = this.state
    const { mode } = this.props
    let validFields = this.getValidFields()
    this.props.form.validateFields(validFields, async (err, fieldsValue) => {
      if (err) {
        return
      }
      let param: AddData | EditData | null = null
      let picIdList: number[] = []
      for (let pic of postsPicList) {
        picIdList.push((pic as UploadFile & { picId: number }).picId)
      }
      let { name, category: categoryId, stock, mangerIdList } = fieldsValue
      if (editorState.isEmpty()) {
        return message.error('文章内容必填', 2)
      }
      let priceInfo: PriceInfo[] = []
      let { priceInfoCount } = this.state
      for (let v of priceInfoCount) {
        priceInfo.push({
          standard: fieldsValue['standard' + v],
          description: fieldsValue['description' + v],
          score: fieldsValue['score' + v],
        })
      }
      let content = editorState.toHTML()
      if (mode === 'add') {
        param = {
          name,
          categoryId,
          content,
          priceInfo,
          stock,
          mangerIdList,
          picIdList,
        }
        add(param)
          .then(_ => {
            this.props.form.resetFields()
            this.setState({
              postsPicList: [],
            })
            this.clearContent()
            message.success('添加成功', 1)
          })
          .catch(err => {
            message.error('添加失败,失败原因: ' + err.msg, 3)
            console.log(err)
          })
      } else {
        param = {
          id,
          name,
          categoryId,
          content,
          priceInfo,
          stock,
          mangerIdList,
          picIdList,
        }
        edit(param as EditData)
          .then(_ => {
            message.success('编辑成功', 1)
          })
          .catch(err => {
            message.error('编辑失败,错误信息: ' + err.msg, 3)
            console.log(err)
          })
      }
    })
  }
  roundData = (arr: number[]) => {
    let newData = Math.floor(Math.random() * 100)
    for (let v of arr) {
      if (v === newData) {
        this.roundData(arr)
      }
    }
    return newData
  }
}
