import { DbBaseField } from '@/typings'
import { bpost, GetListParam } from './api'
import { GoodsOrder } from './goodsOrder'
import { Order } from './order'
import { UserWithAvatar } from './user'

export interface Config {
  // 签到积分规则
  signInRule: [number, number, number, number, number, number, number]
  // 首次注册绑定房产
  houseRule: Number
  // 支付规则
  payRule: {
    money: number // 多少元
    score: number // 对应多少积分
  }
  // 停车费支付规则
  parkPayRule: {
    money: number
    score: number
  }
}

export interface MangerConfig {
  mangerName: string // 小区名
  // 支付规则
  payRule: {
    money: number
    score: number
  }
  // 停车费支付规则
  parkPayRule: {
    money: number
    score: number
  }
}
export type Type = '管理员改动' | '缴费' | '签到' | '购买商品'
export interface UserScoreRecord extends DbBaseField {
  uid: number
  user: UserWithAvatar
  type: Type
  orderId: number
  order: Order | null
  goodsOrderId: number
  goodsOrder: GoodsOrder | null
  signInDays: number // 连续签到天数
  oriScore: number
  offset: number
  currScore: number
}

// 编辑配置
export async function editConfig(data: { config: Config }) {
  return bpost({
    url: 'score/editConfig',
    data,
  })
}
// 获取配置
export async function getConfig() {
  return bpost<{ detail: Config }>({
    url: 'score/getConfig',
  })
}

// 编辑小区配置
export async function editMangerConfig(data: { config: MangerConfig }) {
  return bpost({
    url: 'score/editMangerConfig',
    data,
  })
}
// 获取小区配置
export async function getMangerConfig(data: { mangerName: string }) {
  return bpost<{ detail: MangerConfig | null }>({
    url: 'score/getMangerConfig',
    data,
  })
}

// 获取积分变动记录列表
export async function listRecord(data: GetListParam) {
  data.extra = data.extra || {}
  data.extra.scene = 'admin'
  return bpost<{ list: UserScoreRecord[] }>({
    url: 'score/listRecord',
    data,
  })
}

// 获取记录详情
export async function recordDetail(data: Pick<UserScoreRecord, 'id'>) {
  return bpost<{ detail: UserScoreRecord }>({
    url: 'score/recordDetail',
    data: {
      ...data,
      scene: 'admin',
    },
  })
}

// 管理员修改积分
export async function modifyScore(data: { uid: number; score: number }) {
  return bpost({
    url: 'score/modifyScore',
    data,
  })
}
