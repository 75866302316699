import * as appAction from '@redux/actions/app'
import locale from '@utils/locale'
import React, { Component } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Dispatch } from 'redux'
import { AppState } from '@redux/stores/app'
import routes from './routes/app'
import { Action } from './typings'
import { FmtRoute } from '@components/common/FmtRoute'
interface Props {}
interface State {}
const mapStateToProps = (state: AppState) => ({
  app: state.app,
})
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    setLanguage: (lang: string) => {
      dispatch(appAction.setLanguage(lang))
    },
  }
}
type DefaultProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

// @ts-ignore
@connect(mapStateToProps, mapDispatchToProps)
export default class App extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {}
  }
  render() {
    const { lang } = this.props.app
    return (
      <IntlProvider locale={lang} messages={locale.msg[lang]}>
        <Router>
          <FmtRoute routes={routes} />
        </Router>
      </IntlProvider>
    )
  }
}
