import pathMap from '@/routes/pathMap'
import { adminListManger, Manger, updateMangerMasterPwd } from '@/services/house'
import { listManger } from '@/services/outer'
import { editMangerConfig, getMangerConfig, MangerConfig } from '@/services/score'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import { CommonWxUser, userType } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Divider, Form, Input, message, Modal } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Assign } from 'utility-types'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
const { confirm } = Modal
type UserInfo = CommonWxUser
type Item = Assign<Manger, { id: string }>
interface State {
  breadCrumbList: breadCrumbItem[]
  list: Item[]
  tableIsLoading: boolean
  isShowScoreConfig: boolean
  defaultAvatar: string
  selectedRows: UserInfo[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
  mangerConfig: MangerConfig | null
  scoreStatus: Boolean
}
//@ts-ignore
@Form.create()
export default class List extends Component<Props & FormComponentProps & RouteProps & RouteComponentProps, State> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '小区名称',
      dataIndex: 'name',
    },
    {
      title: '小区商城管理密码',
      dataIndex: 'pwd',
      render: (pwd: string, record: Item) => {
        return (
          <Input.Password
            style={{ width: 300 }}
            placeholder="请输入密码"
            defaultValue={pwd}
            autoComplete={'new-password'}
            onBlur={evt => {
              const mangerId = record.id
              const pwd = evt.target.value
              updateMangerMasterPwd({ mangerId, pwd })
                .then(() => {})
                .catch(err => {
                  if (err.msg !== '无更新') {
                    message.error(err.msg)
                  }
                })
            }}
          ></Input.Password>
        )
      },
    },
    {
      title: '房屋数量',
      dataIndex: 'houseCount',
    },
    {
      title: '用户数量',
      dataIndex: 'userCount',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: Item) => {
        if(this.state.scoreStatus){
          return (
            <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
              <Link to={pathMap.admin.house.list + '?name=' + record.name}>查看房屋</Link>
              <Divider type="vertical" />
              <Link to={pathMap.admin.notice.add + '?ids=[' + JSON.stringify(record.id) + ']'}>发布公告</Link>
              <Divider type="vertical" />
              <div style={{ cursor: 'pointer' }} onClick={() => this.addScoreConfig(record.name)}>
                添加积分配置
              </div>
              <Divider type="vertical" />
              <div style={{ cursor: 'pointer' }} onClick={() => this.editScoreConfig(record.name)}>
                编辑积分配置
              </div>
            </div>
          )
        }else{
          return (
            <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
              <Link to={pathMap.admin.house.list + '?name=' + record.name}>查看房屋</Link>
              <Divider type="vertical" />
              <Link to={pathMap.admin.notice.add + '?ids=[' + JSON.stringify(record.id) + ']'}>发布公告</Link>
            </div>
          )
        }
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    return {
      isShowScoreConfig: false,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '物业管理', to: pathMap.admin.house.list },
        { name: '小区列表' },
      ],
      isShowImg: false,
      scoreStatus: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
        type: {
          condition: conditionType.eqString,
          val: userType.admin,
        },
      },
      filterModalIsActive: false,
      filterList: [],
      mangerConfig: null,
    }
  }
  listUser = async () => {
    try {
      const { pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      let json = await adminListManger({ page, limit, filter })
      let {
        data: { list: mangerList,status },
      } = await listManger()
      let list: Item[] = []
      for (let item of mangerList) {
        for (let house of json.data.list) {
          if (item.name === house.name) {
            list.push({
              id: item.id,
              name: house.name,
              houseCount: house.houseCount,
              userCount: house.userCount,
              pwd: house.pwd,
            })
          }
        }
      }
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: list,
        scoreStatus:status,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  sendNotice = (ids: number[]) => {
    this.props.history.push(pathMap.admin.notice.add + '?ids=' + JSON.stringify(ids))
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '发布公告',
              onClick: () => {
                let idArr = []
                for (let v of this.state.selectedRows) {
                  idArr.push(v.id)
                }
                console.log(this.state.selectedRows)
                if (idArr.length < 1) {
                  message.error('请选择要发布公告的小区', 2)
                  return
                }
                this.sendNotice(idArr)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
    ]
    let { mangerConfig } = this.state
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入小区名称...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'name',
              pagination: this.state.pagination,
              selectedRows: [],
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [],
            },
          }}
          operationList={operationList}
        />
        <Modal
          title="小区积分配置"
          onOk={this.saveScoreConfig}
          onCancel={this.closeScoreConfig}
          visible={this.state.isShowScoreConfig}
        >
          <div className="flex a-center" style={{ fontSize: 16, color: '#333', lineHeight: 2 }}>
            <div style={{ marginRight: 10 }}>小区名称: </div>
            <div>{mangerConfig ? mangerConfig.mangerName : ''}</div>
          </div>
          <div style={{ margin: '10px 0', fontSize: 15, color: '#333' }}>支付规则</div>
          <div className="flex a-center" style={{ marginBottom: 12 }}>
            <div style={{ width: 65 }}>金额 (元): </div>
            <Input
              min={0}
              style={{ marginLeft: 20, width: 160 }}
              value={mangerConfig ? mangerConfig.payRule.money : 0}
              onChange={evt => {
                let { mangerConfig } = this.state
                mangerConfig!.payRule.money = parseInt(evt.target.value)
                this.setState({
                  mangerConfig,
                })
              }}
              type="number"
              placeholder="请输入金额"
            />
          </div>
          <div className="flex a-center" style={{ marginBottom: 12 }}>
            <div style={{ width: 65 }}>积分</div>
            <Input
              min={0}
              style={{ marginLeft: 20, width: 160 }}
              value={mangerConfig ? mangerConfig.payRule.score : 0}
              onChange={evt => {
                let { mangerConfig } = this.state
                mangerConfig!.payRule.score = parseInt(evt.target.value)
                this.setState({
                  mangerConfig,
                })
              }}
              type="number"
              placeholder="请输入积分"
            />
          </div>
          <div style={{ margin: '10px 0', fontSize: 15, color: '#333' }}>停车费支付规则</div>
          <div className="flex a-center" style={{ marginBottom: 12 }}>
            <div style={{ width: 65 }}>金额 (元): </div>
            <Input
              min={0}
              style={{ marginLeft: 20, width: 160 }}
              value={mangerConfig ? mangerConfig.parkPayRule.money : 0}
              onChange={evt => {
                let { mangerConfig } = this.state
                mangerConfig!.parkPayRule.money = parseInt(evt.target.value)
                this.setState({
                  mangerConfig,
                })
              }}
              type="number"
              placeholder="请输入金额"
            />
          </div>
          <div className="flex a-center" style={{ marginBottom: 12 }}>
            <div style={{ width: 65 }}>积分</div>
            <Input
              min={0}
              style={{ marginLeft: 20, width: 160 }}
              value={mangerConfig ? mangerConfig.parkPayRule.score : 0}
              onChange={evt => {
                let { mangerConfig } = this.state
                mangerConfig!.parkPayRule.score = parseInt(evt.target.value)
                this.setState({
                  mangerConfig,
                })
              }}
              type="number"
              placeholder="请输入积分"
            />
          </div>
        </Modal>
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
  addScoreConfig = (mangerName: string) => {
    let that = this
    getMangerConfig({ mangerName })
      .then(json => {
        let detail: MangerConfig | null = json.data.detail
        if (detail) {
          confirm({
            title: '提示',
            content: '已有积分配置, 是否编辑积分配置?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              let { mangerConfig } = that.state
              mangerConfig = detail
              that.setState({
                mangerConfig,
                isShowScoreConfig: true,
              })
            },
            onCancel() {
              console.log('关闭')
            },
          })
        } else {
          let { mangerConfig } = that.state
          mangerConfig = {
            mangerName,
            parkPayRule: {
              money: 0,
              score: 0,
            },
            payRule: {
              money: 0,
              score: 0,
            },
          }
          that.setState({
            mangerConfig,
            isShowScoreConfig: true,
          })
        }
      })
      .catch(err => {
        message.error('获取失败, 错误信息: ' + err.msg, 3)
      })
  }
  editScoreConfig = (mangerName: string) => {
    let that = this
    getMangerConfig({ mangerName })
      .then(json => {
        let detail: MangerConfig | null = json.data.detail
        if (detail) {
          that.setState({
            mangerConfig: detail,
            isShowScoreConfig: true,
          })
        } else {
          confirm({
            title: '提示',
            content: '暂无积分配置, 是否添加积分配置?',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              let { mangerConfig } = that.state
              mangerConfig = {
                mangerName,
                parkPayRule: {
                  money: 0,
                  score: 0,
                },
                payRule: {
                  money: 0,
                  score: 0,
                },
              }
              that.setState({
                mangerConfig,
                isShowScoreConfig: true,
              })
            },
            onCancel() {
              console.log('关闭')
            },
          })
        }
      })
      .catch(err => {
        message.error('获取失败, 错误信息: ' + err.msg, 3)
      })
  }
  saveScoreConfig = () => {
    let { mangerConfig } = this.state
    if (!mangerConfig) {
      message.info('小区配置失败, 请重试', 2)
      this.closeScoreConfig()
      return
    }
    if (mangerConfig.payRule.money === 0) {
      message.info('请输入支付规则的金额', 1)
      return
    }
    if (mangerConfig.payRule.score === 0) {
      message.info('请输入支付规则的积分', 1)
      return
    }
    if (mangerConfig.parkPayRule.money === 0) {
      message.info('请输入停车费支付规则的金额', 1)
      return
    }
    if (mangerConfig.parkPayRule.score === 0) {
      message.info('请输入停车费支付规则的积分', 1)
      return
    }
    editMangerConfig({ config: mangerConfig })
      .then(() => {
        message.success('编辑成功 ', 1, () => {
          this.closeScoreConfig()
        })
      })
      .catch(err => {
        message.error('编辑失败, 错误信息: ' + err.msg, 3)
      })
  }
  closeScoreConfig = () => {
    this.setState({
      isShowScoreConfig: false,
    })
  }
}
