import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { getUploadImgUrl } from '@/services/api'
import {
  add,
  AddData,
  Characteristic,
  ConvenientService,
  detail as getDetail,
  edit,
  EditData,
} from '@/services/convenientService'
import { listManger, Manger } from '@/services/outer'
import { getPicFullUrl } from '@/utils/fn'
import { PostsType, postsType as POSTS_TYPE } from '@api/posts'
import gSass from '@utils/sass'
import { Button, Form, Icon, Input, message, Modal, Select, Upload } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Assign } from 'utility-types'
const style = gSass.admin.convenient.addOrEdit

type Mode = 'add' | 'edit'
interface Props {
  mode: Mode
  type?: PostsType
}
interface State {
  id: number
  previewVisible: boolean
  previewImage: string
  mangerList: Manger[]
  detail: ConvenientService | null
  breadCrumbList: breadCrumbItem[]
  picList: UploadFile[]
  logo: UploadFile[]
  addCharacteristic: number[]
}
const imgListLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 6,
      offset: 0,
    },
  },
}
const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 5,
      offset: 0,
    },
  },
}

// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      id: 0,
      previewVisible: false,
      previewImage: '',
      mangerList: [],
      detail: null,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '公告管理', to: pathMap.admin.notice.list },
        { name: '添加/编辑' },
      ],
      picList: [],
      logo: [],
      addCharacteristic: [],
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    let {
      data: { list: mangerList,status },
    } = await listManger()
    if(status){
      mangerList.unshift({
        id: '0',
        name: '全部',
      })
    }
    this.setState({
      mangerList,
    })
    const { mode } = this.props
    if (mode === 'edit') {
      let { id } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const {
        data: { detail },
      } = await getDetail({ id:parseInt(id) })
      const { setFieldsValue } = this.props.form
      const { name, description, phone, businessHours, address, mangerIdList, picList, characteristicList } = detail
      setFieldsValue({
        name,
        description,
        phone,
        businessHours,
        address,
        mangerIdList,
      })
      let logo: Assign<UploadFile, { picId: number }>[] = []
      let newPicList: Assign<UploadFile, { picId: number }>[] = []
      let addCharacteristic: number[] = []
      logo[0] = {
        ...detail.logo,
        uid: detail.logo.id + '',
        picId: detail.logo.id,
        name: detail.logo.title,
        size: 100,
        status: 'done',
        type: '',
        url: getPicFullUrl(detail.logo.url),
      }
      for (let v of picList) {
        newPicList.push({
          ...v,
          uid: v.id + '',
          picId: v.id,
          name: v.title,
          size: 100,
          status: 'done',
          type: '',
          url: getPicFullUrl(v.url),
        })
      }
      for (let i = 0; i < characteristicList.length; i++) {
        addCharacteristic.push(i)
      }
      this.setState({
        detail,
        id: parseInt(id),
        logo,
        picList: newPicList,
        addCharacteristic,
      })
    }
  }
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props
    const { mangerList, logo, picList, addCharacteristic, detail } = this.state
    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>
          <Form className={style.form}>
            <Form.Item label="小区" {...textLayout}>
              {getFieldDecorator('mangerIdList', {
                rules: [{ required: true, message: '请选择小区' }],
                initialValue: mangerList[0] ? [mangerList[0].id] : ['0'],
              })(
                <Select mode="multiple">
                  {mangerList.map(house => {
                    return (
                      <Select.Option value={house.id} key={house.id}>
                        {house.name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...textLayout} label="门店名称">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入门店名称' }],
              })(<Input placeholder="请输入门店名称" />)}
            </Form.Item>
            <Form.Item
              {...imgListLayout}
              label={
                <span>
                  <span style={{ color: 'red' }}>* </span>门店LOGO
                </span>
              }
            >
              <Upload
                action={getUploadImgUrl()}
                listType="picture-card"
                fileList={logo}
                onPreview={this.handlePreview}
                onChange={this.onLogoChange}
                accept="image/*"
                name="file"
                data={{ name: 'file' }}
              >
                {logo.length > 0 ? null : (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">上传图片</div>
                  </div>
                )}
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
            </Form.Item>
            <Form.Item {...textLayout} label="门店描述">
              {getFieldDecorator('description', {
                rules: [{ required: true, message: '请输入门店描述' }],
              })(<TextArea rows={4} maxLength={500} placeholder="请输入门店描述" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="联系方式">
              {getFieldDecorator('phone', {
                rules: [{ required: true, message: '请输入联系方式' }],
              })(<Input placeholder="请输入联系方式" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="营业时间">
              {getFieldDecorator('businessHours', {
                rules: [{ required: true, message: '请输入营业时间' }],
              })(<Input placeholder="请输入营业时间" />)}
            </Form.Item>
            <Form.Item {...textLayout} label="地址">
              {getFieldDecorator('address', {
                rules: [{ required: true, message: '请输入地址' }],
              })(<Input placeholder="请输入地址" />)}
            </Form.Item>
            <Form.Item {...imgListLayout} label="门店图集">
              <Upload
                action={getUploadImgUrl()}
                listType="picture-card"
                fileList={picList}
                onPreview={this.handlePreview}
                onChange={this.onPicChange}
                accept="image/*"
                name="file"
                data={{ name: 'file' }}
              >
                {picList.length > 6 ? null : (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">上传图片</div>
                  </div>
                )}
              </Upload>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
            </Form.Item>
            {/* 门店特色 */}
            {addCharacteristic.map(v => {
              return (
                <div key={v}>
                  <Form.Item {...textLayout} label="门店特色名称">
                    {getFieldDecorator('characteristicName' + v, {
                      initialValue: detail ? detail.characteristicList[v].title : '',
                    })(<Input placeholder="请输入门特色店名称" />)}
                  </Form.Item>
                  <Form.Item {...textLayout} label="门店特色描述">
                    {getFieldDecorator('characteristicDescription' + v, {
                      initialValue: detail ? detail.characteristicList[v].description : '',
                    })(<Input placeholder="请输入门店特色描述" />)}
                  </Form.Item>
                  <Form.Item {...imgListLayout} label="门店特色图片">
                    {getFieldDecorator('characteristicPic' + v, {
                      valuePropName: 'fileList',
                      getValueFromEvent: this.normFile,
                      initialValue: detail
                        ? [
                            {
                              ...detail.characteristicList[v].pic,
                              uid: detail.characteristicList[v].pic.id + '',
                              picId: detail.characteristicList[v].pic.id,
                              name: detail.characteristicList[v].pic.title,
                              size: 100,
                              status: 'done',
                              type: '',
                              url: getPicFullUrl(detail.characteristicList[v].pic.url),
                            },
                          ]
                        : [],
                    })(
                      <Upload
                        action={getUploadImgUrl()}
                        name="file"
                        accept="image/*"
                        data={{ name: 'file' }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon type="plus" />
                          <div className="ant-upload-text">上传图片</div>
                        </div>
                      </Upload>,
                    )}
                  </Form.Item>
                </div>
              )
            })}
            <Form.Item {...textLayout} label="添加门店特色">
              {addCharacteristic.length < 6 && (
                <div
                  style={{ padding: '0px 12px', border: '1px solid #ccc', width: 150, borderRadius: 5 }}
                  onClick={() => {
                    let { addCharacteristic, detail } = this.state
                    addCharacteristic.push(addCharacteristic.length)
                    if (detail) {
                      detail.characteristicList.push({
                        description: '',
                        pic: {
                          id: 0,
                          title: '',
                          url: '',
                        },
                        picId: 0,
                        title: '',
                      })
                    }
                    this.setState({
                      addCharacteristic,
                      detail,
                    })
                  }}
                >
                  + 添加门店特色
                </div>
              )}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 12,
                },
              }}
            >
              <Button size="large" type="primary" onClick={this.formSubmit}>
                {this.props.mode === 'add' ? '添加' : '编辑'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
  normFile = ({ file, fileList }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code !== 0) {
          fileList.splice(fileList.length - 1, 1)
          message.error('上传图片失败,错误信息: ' + json.msg, 3)
        } else {
          ;(fileList[fileList.length - 1] as UploadFile & { picId: number }).picId = json.data.picId
          ;(fileList[fileList.length - 1] as UploadFile & { picId: number }).url = json.data.url
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    return file && fileList
  }

  onPicChange = ({ file, fileList: picList }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code !== 0) {
          picList.splice(picList.length - 1, 1)
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        } else {
          ;(picList[picList.length - 1] as UploadFile & { picId: number }).picId = json.data.picId
          ;(picList[picList.length - 1] as UploadFile & { picId: number }).url = json.data.url
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      picList,
    })
  }
  onLogoChange = ({ file, fileList: logo }: UploadChangeParam) => {
    if (file.status === 'done') {
      let json = file.response
      if (typeof json === 'object') {
        if (json.code !== 0) {
          logo.splice(logo.length - 1, 1)
          message.error('上传图片失败,错误信息: ' + json.data.msg, 3)
        } else {
          ;(logo[logo.length - 1] as UploadFile & { picId: number }).picId = json.data.picId
          ;(logo[logo.length - 1] as UploadFile & { picId: number }).url = json.data.url
        }
      } else {
        message.error('上传图片失败,服务器错误', 3)
      }
    }
    this.setState({
      logo,
    })
  }
  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }
  formSubmit = () => {
    const { mode } = this.props
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return
      }
      let param: AddData | EditData | null = null
      const { addCharacteristic, logo, picList } = this.state
      let { name, description, phone, address, mangerIdList, businessHours } = fieldsValue
      if (logo.length === 0) {
        message.info('请上传门店LOGO', 1)
        return
      }
      let picIdList: number[] = []
      for (let v of picList as UploadFile[] & { picId: number }[]) {
        picIdList.push(v.picId)
      }
      let characteristicList: Characteristic[] = []
      for (let v of addCharacteristic) {
        characteristicList.push({
          title: fieldsValue['characteristicName' + v],
          description: fieldsValue['characteristicDescription' + v],
          picId: fieldsValue['characteristicPic' + v][0].picId,
          pic: fieldsValue['characteristicPic' + v][0],
        })
      }
      param = {
        name,
        description,
        phone,
        address,
        mangerIdList,
        logoId: (logo[0] as UploadFile & { picId: number }).picId,
        businessHours,
        picIdList,
        characteristicList,
      }
      if (!param) {
        return
      }
      if (mode === 'add') {
        add(param)
          .then(_ => {
            this.props.form.resetFields()
            message.success('添加成功', 1)
            this.setState({
              logo: [],
              picList: [],
            })
          })
          .catch(err => {
            message.error('添加失败,失败原因: ' + err.msg, 3)
            console.log(err)
          })
      } else {
        let { id } = this.state
        ;(param as EditData).id = id
        edit(param as EditData)
          .then(_ => {
            message.success('编辑成功', 1)
          })
          .catch(err => {
            message.error('编辑失败,错误信息: ' + err.msg, 3)
            console.log(err)
          })
      }
    })
  }
}
