import pathMap from '@/routes/pathMap'
import postsCategoryApi, { PostsCategoryWithChild } from '@/services/postsCategory'
import { conditionType } from '@/utils/constant'
import { ListFilter } from '@/utils/jsbdk'
import { del as delPosts, listPosts, Posts, postsType, postsTypeZh } from '@api/posts'
import { CommonPostsCategory } from '@api/postsCategory'
import { CommonUser } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import { gImg, gSass } from '@utils/global'
import { Divider, Form, message, Popconfirm } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { ColumnProps } from 'antd/lib/table'
import { trim } from 'lodash'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
const style = gSass.common.table
const PostsType = postsType
const POSTS_TYPE_ZH = postsTypeZh
type Item = Posts
// export interface SelectedRow {
//   id: number
//   name: string
// }
interface Props {}
interface State {
  tableIsLoading: boolean
  filterModalIsActive: boolean
  isAddDepartmentModalActive: boolean
  defaultAvatar: string
  pagination: PaginationProps
  breadCrumbList: breadCrumbItem[]
  list: Item[]
  selectedRows: Item[]
  filter: ListFilter
  filterList: FilterItem[]
  categoryList: { value: number; title: string }[]
}
class List extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  tableColumns: ColumnProps<Item>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '分类',
      dataIndex: 'category',
      render: (category?: CommonPostsCategory) => {
        return <div>{category ? category.name : '无分类'}</div>
      },
    },
    {
      title: '文章类型',
      dataIndex: 'type',
      render: (type: number) => {
        return <div>{POSTS_TYPE_ZH[type]}</div>
      },
    },
    {
      title: '作者',
      dataIndex: 'author',
      render: (author: CommonUser) => {
        return (
          <div>
            用户id:{author.id}
            用户名:{author.nick}
            <br />
          </div>
        )
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      render: (name: string) => {
        return (
          <div
            style={{
              maxWidth: 100,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {name ? name.substr(0, 100) : ''}
          </div>
        )
      },
    },
    {
      title: '内容',
      dataIndex: 'content',
      render: (content: string, record: Item) => {
        switch (record.type) {
          case PostsType.attachment:
            return '文件'
          case PostsType.double:
            return parseFloat(content).toFixed(2)
          case PostsType.int:
            return parseInt(content)
          case PostsType.pic:
            return '图片'
          case PostsType.text:
          case PostsType.json:
          case PostsType.article:
            return (
              <div
                style={{
                  maxWidth: 100,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {content.substr(0, 100)}
              </div>
            )
        }
        return ''
      },
    },
    {
      title: '标题',
      dataIndex: 'title',
      width: 100,
    },
    {
      title: '摘要',
      dataIndex: 'excerpt',
      render: (excerpt: string) => {
        return (
          <div
            style={{
              maxWidth: 100,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {excerpt ? excerpt.substr(0, 100) : ''}
          </div>
        )
      },
    },
    // {
    //   title: "排序",
    //   dataIndex: "sort",
    // },
    // {
    //   title: "创建时间",
    //   dataIndex: "ctime",
    // },
    {
      title: '更新时间',
      dataIndex: 'utime',
    },
    {
      title: '操作',
      width: 150,
      render: (_, record) => {
        return (
          <div style={{ color: '#007bff', display: 'flex', alignItems: 'center' }}>
            {/* <div style={{ cursor: "pointer" }} onClick={() => this.lookupPosts(record.id)}>
              查看
            </div>
            <Divider type="vertical" /> */}
            <div style={{ cursor: 'pointer' }} onClick={() => this.editPosts(record.id)}>
              编辑
            </div>
            <Divider type="vertical" />
            <div style={{ cursor: 'pointer' }}>
              <Popconfirm
                title={<div>你确定要删除文章{record.id}吗?</div>}
                onConfirm={() => this.delPosts([record.id])}
              >
                <span>删除</span>
              </Popconfirm>
            </div>
          </div>
        )
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteComponentProps) {
    super(props)
    this.state = this.getInitState()
  }
  init = async () => {
    this.listData()
    try {
      const {
        data: { list },
      } = await postsCategoryApi.list({ page: 0, limit: 0 })
      let categoryList: { value: number; title: string }[] = []
      for (let v of list) {
        if (v.id === 2) {
          categoryList.push({
            value: 0,
            title: '全部',
          })
          if (v.children && v.children.length > 0) {
            categoryList.push(...this.listCategory(v.children))
          }
        }
      }
      this.setState({
        categoryList,
      })
    } catch (err) {
      console.log(err)
    }
  }
  listCategory = (list: PostsCategoryWithChild[]) => {
    let arr: { value: number; title: string }[] = []
    for (let v of list) {
      arr.push({
        value: v.id,
        title: v.name,
      })
      if (v.children && v.children.length > 0) {
        arr.push(...this.listCategory(v.children))
      }
    }
    return arr
  }
  getInitState = (): State => {
    return {
      filterModalIsActive: false,
      isAddDepartmentModalActive: false,
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listData,
          )
        },
      },
      filter: {
        search: { condition: conditionType.like, val: '' },
      },
      list: [],
      selectedRows: [],
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: 'cms', to: pathMap.admin.post.category.index },
        { name: '文章列表' },
      ],
      filterList: [],
      categoryList: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  delPosts = (ids: number[]) => {
    const { list } = this.state,
      selectedRows = this.state.selectedRows
    delPosts(ids)
      .then(() => {
        message.success('删除成功', 1)
        this.setState({
          list: list.filter(item => !ids.includes(item.id)),
          selectedRows: selectedRows.filter(item => !ids.includes(item.id)),
        })
      })
      .catch(err => {
        message.error('删除失败,错误原因: ' + err.msg, 3)
      })
  }
  listData = async () => {
    const pagination = this.state.pagination
    try {
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      this.setState({
        tableIsLoading: true,
      })
      let json = await listPosts({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  lookupPosts = (postsId: number) => {
    this.props.history.push(pathMap.admin.post.edit + '?id=' + postsId)
  }
  editPosts = (postsId: number) => {
    this.props.history.push(pathMap.admin.post.edit + '?id=' + postsId)
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listData,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listData,
    )
  }
  showAddDepartmentModal = () => {
    this.setState({
      isAddDepartmentModalActive: true,
    })
  }
  closeAddDepartmentModal = () => {
    this.setState({
      isAddDepartmentModalActive: false,
    })
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
      {
        title: '新增',
        btnProps: {
          icon: 'add',
          type: 'default',
          onClick: () => {
            this.props.history.push(pathMap.admin.post.add)
          },
        },
      },
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '删除',
              onClick: () => {
                let ids: number[] = []
                for (let v of this.state.selectedRows) {
                  ids.push(v.id)
                }
                if (ids.length < 1) {
                  message.error('请选择要删除的文章', 2)
                  return
                }
                this.delPosts(ids)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
    ]
    return (
      <div className={style.symptomList}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入ID|内容名|标题...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'category',
                  conditionFieldName: 'categoryCondition',
                  type: 'select',
                  title: '分类',
                  selectDataList: this.state.categoryList,
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'type',
                  conditionFieldName: 'typeCondition',
                  type: 'select',
                  title: '文章类型',
                  selectDataList: [
                    { value: '全部', title: '全部' },
                    { value: postsType.article, title: postsTypeZh[postsType.article] },
                    { value: postsType.text, title: postsTypeZh[postsType.text] },
                    { value: postsType.int, title: postsTypeZh[postsType.int] },
                    { value: postsType.double, title: postsTypeZh[postsType.double] },
                    { value: postsType.json, title: postsTypeZh[postsType.json] },
                    { value: postsType.pic, title: postsTypeZh[postsType.pic] },
                    { value: postsType.attachment, title: postsTypeZh[postsType.attachment] },
                    { value: postsType.tips, title: postsTypeZh[postsType.tips] },
                  ],
                  decoratorOptions: {
                    initialValue: '全部',
                  },
                },
                {
                  valFieldName: 'name',
                  conditionFieldName: 'nameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '名称',
                },
                {
                  valFieldName: 'title',
                  conditionFieldName: 'titleCondition',
                  type: 'value',
                  title: '标题',
                  valueType: 'string',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
}

export default Form.create()(List)
