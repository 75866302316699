import Logout from "@/pages/common/Logout"
import { BuffRoute } from "@/typings"
import Page404 from "@pages/common/Page404"
import adminRoute from "./admin"
import indexRoute from "./index"
import pathMap from "./pathMap"
const routes: BuffRoute[] = [
  {
    name: "404",
    path: pathMap[404],
    component: Page404,
  },
  {
    name: "logout",
    path: pathMap.logout,
    component: Logout,
  },
  adminRoute,
  indexRoute,
]
export default routes
