import PictureView from '@/components/common/PictureView'
import pathMap from '@/routes/pathMap'
import { HouseApply, listApply, passApply, rejectApply } from '@/services/house'
import { Picture } from '@/typings'
import { conditionType } from '@/utils/constant'
import { getPicFullUrl } from '@/utils/fn'
import { ListFilter } from '@/utils/jsbdk'
import { userType } from '@api/user'
import MyBreadCrumb, { breadCrumbItem } from '@components/common/nav/BreadCrumb'
import MyTable, { FilterItem, TableOperation } from '@components/common/Table'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Button, Form, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { PaginationProps } from 'antd/lib/pagination'
import { trim } from 'lodash'
import React, { Component } from 'react'
const tableStyle = gSass.common.table
interface Props {}
interface RouteProps {}
interface State {
  breadCrumbList: breadCrumbItem[]
  list: HouseApply[]
  tableIsLoading: boolean
  defaultAvatar: string
  selectedRows: HouseApply[]
  pagination: PaginationProps
  filter: ListFilter
  filterModalIsActive: boolean
  filterList: FilterItem[]
  isShowImg: boolean
  photoIndex: number
  showImages: string[]
}
//@ts-ignore
@Form.create()
export default class ApplyList extends Component<Props & FormComponentProps & RouteProps, State> {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '申请类型',
      dataIndex: 'type',
    },
    {
      title: '申请人',
      dataIndex: 'name',
    },
    {
      title: '申请人手机号码',
      dataIndex: 'phone',
    },
    {
      title: '房屋地址',
      dataIndex: 'houseAddr',
    },
    {
      title: '小区名',
      dataIndex: 'mangerName',
    },
    {
      title: '图片',
      dataIndex: 'picList',
      render: (picList: Picture[]) => {
        return (
          <>
            <img
              className={tableStyle.avatar}
              src={picList.length > 0 ? getPicFullUrl(picList[0].url) : this.state.defaultAvatar}
              alt="头像"
              onClick={() => {
                if (picList.length > 0) {
                  let showImages: string[] = []
                  for (let v of picList) {
                    showImages.push(getPicFullUrl(v.url))
                  }
                  this.setState({
                    isShowImg: true,
                    showImages,
                  })
                }
              }}
            />
            <PictureView
              isShowImg={this.state.isShowImg}
              photoIndex={this.state.photoIndex}
              showImages={this.state.showImages}
              onMovePrevRequest={this.onMovePrevRequest}
              onMoveNextRequest={this.onMoveNextRequest}
              onChange={this.onChange}
            />
          </>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: string, record: HouseApply) => {
        return (
          <div className="flex">
            <Button style={{ margin: '0 5px' }} type="primary" onClick={() => this.agree(record.id)}>
              同意
            </Button>
            <Button style={{ margin: '0 5px' }} ghost type="danger" onClick={() => this.reject(record.id)}>
              拒绝
            </Button>
          </div>
        )
      },
    },
  ]
  constructor(props: Props & FormComponentProps & RouteProps) {
    super(props)
    this.state = this.getInitState()
  }

  componentDidMount() {
    this.init()
  }
  init = async () => {
    this.listUser()
  }
  getInitState = (): State => {
    return {
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '申请管理', to: pathMap.admin.apply.index },
        { name: '申请列表' },
      ],
      isShowImg: false,
      photoIndex: 0,
      showImages: [],
      list: [],
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.listUser,
          )
        },
      },
      filter: {
        search: {
          condition: conditionType.like,
          val: '',
        },
        type: {
          condition: conditionType.eqString,
          val: userType.admin,
        },
      },
      filterModalIsActive: false,
      filterList: [],
    }
  }
  agree = (id: number) => {
    passApply({ id })
      .then(() => {
        message.success('申请已通过', 1, this.init)
      })
      .catch(err => {
        message.error('操作失败, 错误信息: ' + err.msg, 3)
      })
  }
  reject = (id: number) => {
    rejectApply({ id })
      .then(() => {
        message.success('申请已拒绝', 1, this.init)
      })
      .catch(err => {
        message.error('操作失败, 错误信息: ' + err.msg, 3)
      })
  }
  listUser = async () => {
    try {
      const { pagination } = this.state
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      let json = await listApply({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        list: json.data.list,
        pagination,
        tableIsLoading: false,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  deleteUser = (ids: number[]) => {
    console.log(ids)
  }
  closeFilterModal = () => {
    this.setState({
      filterModalIsActive: false,
    })
  }
  onFilterSubmit = (filterRes: ListFilter) => {
    this.setState(
      {
        filter: filterRes,
      },
      this.listUser,
    )
  }
  onTableSearch = (val: string) => {
    const pagination = this.state.pagination
    pagination.current = 1
    let search = this.state.filter.search
    search.val = trim(val)
    this.setState(
      {
        filter: { search },
      },
      this.listUser,
    )
  }
  render() {
    const operationList: TableOperation[] = [
      {
        title: '更多操作',
        dropdown: {
          menuList: [
            {
              title: '删除',
              onClick: () => {
                let idArr = []
                for (let v of this.state.selectedRows) {
                  idArr.push(v.id)
                }
                if (idArr.length < 1) {
                  message.error('请选择要删除的用户', 2)
                  return
                }
                this.deleteUser(idArr)
              },
            },
          ],
        },
        btnProps: {
          rightIconType: 'down',
        },
      },
      {
        title: '筛选',
        btnProps: {
          icon: 'filter',
          type: 'primary',
          onClick: () => {
            this.setState({
              filterModalIsActive: true,
            })
          },
        },
      },
    ]
    return (
      <div className={tableStyle.main}>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <MyTable
          componentsProps={{
            search: {
              placeholder: '请输入姓名|手机号|小区名...',
              enterButton: '搜索',
              onSearch: this.onTableSearch,
            },
            table: {
              columns: this.tableColumns,
              dataSource: this.state.list,
              loading: this.state.tableIsLoading,
              rowKey: 'id',
              pagination: this.state.pagination,
              selectedRows: [],
              rowSelection: {
                onChange: (_: any, selectedRows: any) => {
                  this.setState({
                    selectedRows,
                  })
                },
              },
            },
            modal: {
              title: '筛选',
              visible: this.state.filterModalIsActive,
              okText: '确认',
              cancelText: '取消',
              width: 600,
              onCancel: this.closeFilterModal,
              closeFilterModal: this.closeFilterModal,
              onOutOk: this.onFilterSubmit,
              filterList: [
                {
                  valFieldName: 'type',
                  conditionFieldName: 'typeCondition',
                  type: 'select',
                  title: '申请类型',
                  selectDataList: [
                    { value: 0, title: '全部' },
                    { value: '业主', title: '业主' },
                    { value: '租户', title: '租户' },
                    { value: '家庭成员', title: '家庭成员' },
                  ],
                  decoratorOptions: {
                    initialValue: 0,
                  },
                },
                {
                  valFieldName: 'name',
                  conditionFieldName: 'nameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '申请人',
                },
                {
                  valFieldName: 'phone',
                  conditionFieldName: 'phoneCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '手机号码',
                },

                {
                  valFieldName: 'mangerName',
                  conditionFieldName: 'mangerNameCondition',
                  type: 'value',
                  valueType: 'string',
                  title: '小区名',
                },
              ],
            },
          }}
          operationList={operationList}
        />
      </div>
    )
  }
  onMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.showImages.length - 1) % this.state.showImages.length,
    })
  }
  onMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.showImages.length,
    })
  }
  onChange = () => {
    this.setState({
      isShowImg: false,
      photoIndex: 0,
    })
  }
}
