import MyBreadCrumb, { breadCrumbItem } from '@/components/common/nav/BreadCrumb'
import pathMap from '@/routes/pathMap'
import { notLimit } from '@/services/api'
import { WithChildrenSceneGoodsCategory } from '@/services/goodsCategory'
import { add, detail, edit, EditData, Msg, SendData } from '@/services/msg'
import { adminListCommonUser, CommonWxUser } from '@/services/user'
import { PostsType, postsType as POSTS_TYPE } from '@api/posts'
import gImg from '@utils/img'
import gSass from '@utils/sass'
import { Button, Form, message, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import BraftEditor, { EditorState } from 'braft-editor'
import 'braft-editor/dist/index.css'
import { ContentUtils } from 'braft-utils'
import qs from 'qs'
import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
const style = gSass.admin.message.addOrEdit
const textLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 2,
    },
  },
  wrapperCol: {
    sm: {
      span: 5,
      offset: 0,
    },
  },
}

type Mode = 'add' | 'edit'
interface Props {
  mode: Mode
}
interface State {
  ids: number[]
  previewVisible: boolean
  previewImage: string
  detail: Msg | null
  editorState: EditorState
  productCategoryList: WithChildrenSceneGoodsCategory[]
  userList: CommonWxUser[]
  breadCrumbList: breadCrumbItem[]
}

// @ts-ignore
@withRouter
// @ts-ignore
@Form.create()
export default class AddOrEdit extends Component<Props & FormComponentProps & RouteComponentProps, State> {
  static defaultProps: FormComponentProps & RouteComponentProps
  defaultType: PostsType = POSTS_TYPE.article
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    let search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    let ids: number[] = search.ids ? JSON.parse(search.ids) : [0]
    return {
      ids,
      previewVisible: false,
      previewImage: '',
      productCategoryList: [],
      userList: [],
      editorState: BraftEditor.createEditorState(null),
      detail: null,
      breadCrumbList: [
        { name: '首页', to: pathMap.admin.index },
        { name: '消息管理', to: pathMap.admin.user.list },
        { name: '添加/编辑' },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }

  init = async () => {
    let userListTask = adminListCommonUser({ page: notLimit, limit: notLimit, filter: {} })
    const {
      data: { list: oriUserList },
    } = await userListTask
    let userList: CommonWxUser[] = [
      {
        id: 0,
        nick: '全部',
        phone: '',
        wxInfo: {
          uid: 0,
          openid: '',
          nickname: '',
          sex: 0,
          city: '',
          country: '',
          province: '',
          language: '',
          headImgUrl: '',
          id: 0,
          ctime: '',
          dtime: '',
          utime: '',
        },
        ctime: '',
      },
    ]
    userList.push(...oriUserList)
    this.setState({
      userList,
    })
    const { mode } = this.props
    if (mode === 'edit') {
      let { ids } = this.state
      const {
        data: { detail: msgDetail },
      } = await detail({ id: ids[0] })
      this.setState({ detail: msgDetail })
      const { setFieldsValue } = this.props.form
      setFieldsValue({
        uid: [msgDetail.uid],
        content: msgDetail.content,
        hasView: msgDetail.hasView,
      })
    }
  }

  getValidFields = () => {
    let validFields = ['uid', 'content']
    return validFields
  }
  render() {
    const {
      form: { getFieldDecorator },
      mode,
    } = this.props
    let { userList, ids } = this.state
    return (
      <div>
        <MyBreadCrumb breadCrumbList={this.state.breadCrumbList} />
        <div style={{ background: '#fff', margin: 15, padding: 15 }}>
          <Form>
            <Form.Item label="用户" {...textLayout}>
              {getFieldDecorator('uid', {
                rules: [{ required: true, message: '请选择用户' }],
                initialValue: mode === 'add' ? ids : [0],
              })(
                <Select mode="multiple" disabled placeholder="请选择" className={style.userSelect}>
                  {userList.map(v => {
                    return (
                      <Select.Option key={v.wxInfo.uid} value={v.wxInfo.uid}>
                        <img
                          alt=""
                          style={{ width: 40, marginRight: 8 }}
                          src={v.wxInfo.headImgUrl || gImg.common.defaultAvatar}
                        ></img>
                        {v.nick || '未命名'}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="内容" {...textLayout}>
              {getFieldDecorator('content', {
                rules: [{ required: true, message: '请输入内容' }],
              })(<TextArea rows={6} placeholder="请输入" />)}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                xs: {
                  span: 7,
                  offset: 12,
                },
              }}
            >
              <Button size="large" type="primary" onClick={this.formSubmit}>
                {this.props.mode === 'add' ? '添加' : '编辑'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
  clearContent = () => {
    this.setState({
      editorState: ContentUtils.clear(this.state.editorState),
    })
  }
  formSubmit = () => {
    const { ids } = this.state
    const { mode } = this.props
    let validFields = this.getValidFields()
    this.props.form.validateFields(validFields, async (err, fieldsValue) => {
      if (err) {
        return
      }
      let param: SendData | EditData | null = null
      let { uid, content } = fieldsValue
      if (mode === 'add') {
        param = {
          // uidList: ids,
          uid,
          content,
          hasView: 'false',
        }
        add(param as SendData)
          .then(_ => {
            this.props.form.resetFields()
            this.clearContent()
            message.success('添加成功', 1)
          })
          .catch(err => {
            message.error('添加失败,失败原因: ' + err.msg, 3)
            console.log(err)
          })
      } else {
        param = {
          id: ids[0],
          uid,
          content,
          hasView: 'false',
        }
        edit(param as EditData)
          .then(_ => {
            message.success('编辑成功', 1)
          })
          .catch(err => {
            message.error('编辑失败,错误信息: ' + err.msg, 3)
            console.log(err)
          })
      }
    })
  }
}
