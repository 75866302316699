import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import { JsonReturnCode, ConditionType } from '@utils/constant'
import { updateSessionTime } from '@/services/api'
export function delay(time: number) {
  return new Promise(s => setTimeout(s, time))
}
export interface DbBase {
  id: number
  ctime: string
  utime: string
  dtime: string
}
export type ListFilter = Record<
  string,
  {
    condition: ConditionType
    val: any
  }
>
export interface ListParam {
  page?: number
  limit?: number
  filter?: ListFilter
  order?: Record<string, 'desc' | 'asc'>
  extra?: Record<string, any>
}
export interface JsonRes<T = unknown> {
  code: number
  page: number
  limit: number
  count: number
  msg: string
  data: T
}
interface ReqParam {
  url: string
  params?: AxiosRequestConfig['params']
  data?: AxiosRequestConfig['data']
  method?: AxiosRequestConfig['method']
  baseURL?: AxiosRequestConfig['baseURL']
}
export async function req<T = any>(param: ReqParam): Promise<JsonRes<T>> {
  return new Promise((s, j) => {
    let headers: any = {}
    if (LocalStorage.has('token')) {
      headers.token = LocalStorage.get('token')
    }
    axios
      .request<JsonRes<T>>({
        ...param,
        headers,
      })
      .then(resp => {
        if (!_.isObject(resp.data)) {
          return j({ msg: '返回值不是正确的值', data: resp.data })
        }
        let json = resp.data
        const { code, msg } = json
        if (code !== JsonReturnCode.success) {
          return j({ msg })
        }
        updateSessionTime()
        return s(json)
      })
      .catch(e => {
        j({ msg: '网络请求失败,错误信息: ' + e, data: e })
      })
  })
}

export async function bget<T = any>(param: { url: string; param?: Record<string, any> }) {
  const { url, param: params } = param
  return req<T>({
    url,
    params,
  })
}
export async function bpost<T = any>(param: { url: string; data?: Record<string, any> }) {
  const { url, data } = param
  return req<T>({
    url,
    method: 'POST',
    data,
  })
}

const localStorage = window.localStorage
const ALL_TIME = 0
export type StorageKey = string | number

export const LocalStorage = {
  set(k: StorageKey, v: any, expire: number = ALL_TIME): void {
    const data = {
      val: v,
      expire,
    }
    if (data.expire !== ALL_TIME) {
      data.expire = new Date().getTime() + data.expire * 1000
    }
    if (typeof k === 'number') {
      k = k.toString()
    }
    localStorage.setItem(k, JSON.stringify(data))
  },
  get(k: StorageKey) {
    let data,
      realData,
      now = new Date()
    try {
      if (typeof k === 'number') {
        k = k.toString()
      }
      let res = localStorage.getItem(k)
      if (res === null) {
        return null
      } else {
        data = JSON.parse(res)
      }
    } catch (e) {
      return null
    }
    if (data === null) {
      return null
    }
    realData = data.val
    if (data.expire !== ALL_TIME && now > new Date(data.expire)) {
      this.remove(k)
      return null
    }
    return realData
  },
  has(k: StorageKey): boolean {
    return this.get(k) !== null
  },
  remove(k: StorageKey) {
    if (typeof k === 'number') {
      k = k.toString()
    }
    return localStorage.removeItem(k)
  },
  clear() {
    return localStorage.clear()
  },
}
