import { metaData } from '@/config/prod'
import style from '@sass/index/index.module.scss'
import React, { Component, createRef } from 'react'
import Helmet from 'react-helmet'
import { Events, scrollSpy, Link, Element } from 'react-scroll'
import gImg from '@utils/img'
import csn from 'classnames'
interface Props {}
interface State {
  isShowMore: boolean
}
type DefaultProps = {}

export default class Main extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps
  scrollDiv = createRef<HTMLDivElement>()
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      isShowMore: false,
    }
  }
  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.scrollIntoView()
    }
    Events.scrollEvent.register('begin', function() {})
    Events.scrollEvent.register('end', function() {})
    scrollSpy.update()
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }
  render() {
    let { isShowMore } = this.state
    return (
      <div className={style.main} ref={this.scrollDiv}>
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="keywords" content={metaData.keywords} />
          <meta name="description" content={metaData.description} />
        </Helmet>
        <div className={style.content}>
          <div className={style.header}>
            <img className={style.img} alt="" src={gImg.home.header}></img>
          </div>
          <Element name="contact"></Element>
          <div className={csn(style.item, style.contact)}>
            <div className={style.theme}>
              <div className={style.title}>公司简介</div>
            </div>
            <div className={csn(isShowMore ? style.descMore : '', style.desc)}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上海嘉隆物业管理有限公司于2010年成立，是嘉定区国资委下属上海绿洲投资控股集团有限公司全资子公司，现为中国物业管理协会理事单位，上海市物业管理协会常务理事单位，中国物协标委会（医院物业服务）企业联盟会员单位，上海市卫生系统后勤管理协会会员单位，上海市政府采购中心会员供应商，国家二级物业管理企业。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司现有员工1600余人，其中拥有职称及其他持证上岗人员682人。主要承接项目类型为：医疗系统类（嘉定区中心医院、嘉定区妇幼保健院等）；公共场馆类（韩天衡美术馆、嘉北郊野公园等）；商务办公类（嘉定区国资金融大厦、绿洲集团大楼等）；商业园区类（现厂园区等）；生活居住类(绿洲北水湾、绿洲香格丽等)四十多个项目，管理面积约1000万平方米，年营业收入超过1.7亿元人民币，已初步形成了客户类型较为齐全、服务流程较为清晰的物业管理模式。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司在发展过程中，秉承“服务源于专业，专业塑造价值，价值成就荣耀，荣耀达成共赢”的管理理念，坚持“真诚合作、热情服务、严格管理、温馨关爱”的质量方针，先后荣获上海市物业管理行业AAA级诚信承诺企业、2020上海市物业服务综合百强企业第70位（医院类排名第10位）、2020物业服务企业综合实力500强第387位。嘉定国资金融大厦和嘉定体育新馆项目荣获2020年度上海市物业管理优秀示范项目称号，嘉定区南翔医院等5个项目荣获工人先锋号，2019-2020年度嘉定区文明单位、2021年先进基层党组织、2022年度上海市物业服务企业综合能力四星级企业等荣誉。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司一直坚持以“跻身上海市一流品牌物业公司行列”为目标，先后开发了设备巡检系统、集中监控系统、收费管理系统、BI智能分析系统，并上线了微信小程序、移动支付、电子发票等线上支付手段。严格执行ISO质量管理体系、环境管理体系、职业健康安全管理体系标准，对各项服务实施全程监控，保证管理服务的专业化、规范化和有效性。凭借公司雄厚的资金实力、高素质的管理人才、科学的管理理念，遵循“嘉惠一方、隆兴万家”的服务宗旨，以服务为中心，坚持专业化、精细化服务，以科学严谨的管理手段和模式，走重服务、重信誉的品牌化之路，为广大客户提供优质的专业化物业管理服务。
            </div>
            <Link
              to="contact"
              className={csn(style.more, 'flex a-center j-center')}
              onClick={() => {
                this.setState({
                  isShowMore: !this.state.isShowMore,
                })
              }}
            >
              {isShowMore ? '收起' : '了解更多'}
              <div className={style.icon}></div>
            </Link>
          </div>
          <div className={csn(style.item, style.idea)}>
            <div className={style.theme}>
              <div className={style.title}>经营理念</div>
            </div>
            <div className={style.descItem}>
              <div className={style.title}>服务理念</div>
              <div className={style.desc}>
                服务源于专业<span className={style.interval}></span> 专业塑造价值
                <span className={style.interval}></span>价值成就荣耀<span className={style.interval}></span>
                荣耀达成共赢
              </div>
            </div>
            <div className={style.descItem}>
              <div className={style.title}>管理方针</div>
              <div className={style.desc}>
                真诚合作<span className={style.interval}></span> 热情服务 <span className={style.interval}></span>
                严格管理<span className={style.interval}></span> 温馨关爱
              </div>
            </div>
          </div>
          <div className={csn(style.item, style.idea)}>
            <div className={style.theme}>
              <div className={style.title}>业务介绍</div>
            </div>
            <div className={style.descItem}>
              <div className={style.title}>物业管理服务</div>
              <div className={style.desc}>
                为客户提供完善的物业管理服务，包括综合管理、秩序维护、环境清洁、绿化养护、运送服务、设施设备养护等。通过优质的服务为客户提供良好的工作、生活和学习环境，实现物业的保值增值。
                服务形式包括顾问咨询服务和全委管理服务。
              </div>
            </div>
            <div className={style.descItem}>
              <div className={style.title}>设备能源管理</div>
              <div className={style.desc}>
                对设备设施实行计划性保养，科学地制定具体的维护保养周期和内容，使设备的维护保养实现系统化、规范化、标准化，确保设备维护保养的计划性、有效性、连续性，从而延长其生命周期。通过先进的能源管理技术及手段，有效降低物业项目的运行费用及管理成本，实现物业节能环保的工作目标。
              </div>
            </div>
            <div className={style.descItem}>
              <div className={style.title}>增值延伸服务</div>
              <div className={style.desc}>
                根据客户的服务需求，为客户度身定制物业增值服务作为日常物业服务的补充。通过全方位的后勤保障服务使客户感受到亲切、热情、贴心的真诚关怀与全面、周到、细致的优质服务，同时也加强了物业企业与客户之前的紧密联系。
                按照客户需求和项目类型开展如专项保洁服务、专属管家服务等。
              </div>
            </div>
          </div>
          <div className={csn(style.qrList, 'flex a-center j-center')}>
            <div className={style.qrItem}>
              <img className={style.img} alt="" src={gImg.home.public}></img>
              <div>
                <div className={style.title}>扫一扫进入</div>
                <div className={style.title}>嘉管家小程序</div>
              </div>
            </div>
            <div className={style.qrItem}>
              <img className={style.img} alt="" src={gImg.home.wechat}></img>
              <div>
                <div className={style.title}>扫一扫进入</div>
                <div className={style.title}>嘉隆物业公众号</div>
              </div>
            </div>
          </div>
          <div className={style.footer}>
            <img className={style.img} alt="" src={gImg.home.footer}></img>
          </div>
        </div>
      </div>
    )
  }
}
