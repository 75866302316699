import gSass from '@utils/sass'
import { Button, Input, Modal, Tree, Popconfirm, message } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form'
import csn from 'classnames'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import pathMap from '@/routes/pathMap'
import { WithChildrenSceneGoodsCategory, edit, add, del, list } from '@/services/goodsCategory'
import { notLimit } from '@/services/api'
const style = gSass.admin.integralMall.category

const { TreeNode } = Tree

type CategoryItem = WithChildrenSceneGoodsCategory
interface Props {}

interface State {
  isAddCategoryModalActive: boolean
  mode: 'add' | 'edit'
  currCategoryId: number
  currCategoryName: string
  currCategoryPid: number
  categoryList: CategoryItem[]
}
//@ts-ignore
@Form.create()
export default class Category extends Component<Props & FormComponentProps, State> {
  constructor(props: any) {
    super(props)
    this.state = this.getInitState()
  }
  getInitState = (): State => {
    return {
      isAddCategoryModalActive: false,
      currCategoryId: 0,
      currCategoryPid: 0,
      currCategoryName: '',
      mode: 'add',
      categoryList: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async () => {
    try {
      const {
        data: { list: categoryList },
      } = await list({ page: notLimit, limit: notLimit, filter: {} })
      this.setState({
        categoryList,
      })
    } catch (err) {
      console.log(err)
    }
  }
  findCategoryById = (id: number, categoryList: CategoryItem[]): CategoryItem | null => {
    for (let category of categoryList) {
      if (category.id === id) {
        return category
      }
      let res = this.findCategoryById(id, category.children)
      if (res !== null) {
        return res
      }
    }
    return null
  }
  renderTreeNodes = (data: CategoryItem[]) =>
    data.map(category => {
      return (
        <TreeNode
          className={style.treeNode}
          key={category.id + ''}
          title={
            <div className={csn(style.nodeItem, 'flex ')}>
              <div className={style.title}>{category.name} </div>
              <div>
                <Button type="primary" className={style.addChild}>
                  <Link to={pathMap.admin.integralMall.product + '?id=' + category.id}>查看商品</Link>
                </Button>
                <Button
                  type="primary"
                  ghost
                  className={style.addChild}
                  onClick={() => this.editOrAdd(category, 'edit')}
                >
                  编辑
                </Button>
                <Popconfirm
                  title={'您将删除分类 ' + category.name + ' 吗?'}
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => this.delCategory(category.id)}
                >
                  <Button type="danger" ghost className={style.addChild}>
                    删除
                  </Button>
                </Popconfirm>
                <Button className={style.addChild} onClick={() => this.editOrAdd(category, 'add')}>
                  添加下级分类
                </Button>
              </div>
            </div>
          }
          dataRef={category}
        >
          {this.renderTreeNodes(category.children || [])}
        </TreeNode>
      )
    })
  closeAddCategoryModal = () => {
    this.setState({
      isAddCategoryModalActive: false,
    })
  }
  editOrAdd = (category: CategoryItem, mode: 'edit' | 'add') => {
    if (mode === 'edit') {
      this.props.form.setFieldsValue({
        name: category.name,
      })
    } else {
      this.props.form.setFieldsValue({
        name: '',
      })
    }
    console.log(category)
    this.setState({
      mode,
      currCategoryId: category.id,
      currCategoryPid: category.pid,
      currCategoryName: category.name,
      isAddCategoryModalActive: true,
    })
  }
  addCategory = () => {
    let { mode, currCategoryId, currCategoryPid } = this.state
    this.props.form.validateFields(['name'], (err, fields) => {
      if (err) {
        return
      }
      if (mode === 'edit') {
        edit({ name: fields.name, id: currCategoryId, pid: currCategoryPid })
          .then(_ => {
            message.success('编辑成功', 0.5, () => {
              this.props.form.setFieldsValue({
                name: '',
              })
              this.setState({
                currCategoryId: 0,
                currCategoryName: '',
                currCategoryPid: 0,
              })
              this.init()
              this.closeAddCategoryModal()
            })
          })
          .catch(err => {
            console.log(err)
            this.closeAddCategoryModal()
            message.error('编辑失败, 失败原因: ' + err.msg || err, 3)
          })
      } else {
        add({ name: fields.name, pid: currCategoryId })
          .then(_ => {
            message.success('添加成功', 0.5, () => {
              this.props.form.setFieldsValue({
                name: '',
              })
              this.setState({
                currCategoryName: '',
                currCategoryId: 0,
              })
              this.init()
              this.closeAddCategoryModal()
            })
          })
          .catch(err => {
            console.log(err)
            this.closeAddCategoryModal()
            message.error('添加失败, 失败原因: ' + err.msg || err, 3)
          })
      }
    })
  }
  delCategory = (id: number) => {
    del({ ids: [id] })
      .then(_ => {
        message.success('删除成功', 0.5, () => {
          this.init()
        })
      })
      .catch(err => {
        console.log(err)
        message.error('删除失败, 失败原因: ' + err.msg || err, 3)
      })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    let { mode, currCategoryName, isAddCategoryModalActive } = this.state
    return (
      <div className={csn(style.categoryBox, 'flex ')}>
        <Tree blockNode className={style.treeBox}>
          {this.renderTreeNodes(this.state.categoryList)}
        </Tree>
        <Button
          className={style.addCategoryBtn}
          onClick={() => {
            this.setState({
              currCategoryName: '顶级',
              currCategoryPid: 0,
              isAddCategoryModalActive: true,
            })
          }}
        >
          添加顶级分类
        </Button>
        <Modal
          title={mode === 'add' ? '为"' + currCategoryName + '"添加子分类' : '编辑' + currCategoryName}
          visible={isAddCategoryModalActive}
          onOk={this.addCategory}
          onCancel={this.closeAddCategoryModal}
        >
          <Form>
            <Form.Item label="分类名" labelCol={{ span: 3, offset: 3 }} wrapperCol={{ span: 14, offset: 1 }}>
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: '请输入分类名' },
                  { min: 2, max: 10, message: '分类名长度为2-10' },
                ],
              })(<Input type="text" autoFocus size="large" placeholder="请输入" onPressEnter={this.addCategory} />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
