export default {
  home: '/',
  index: {},
  404: '/404',
  admin: {
    index: '/admin',
    login: '/admin/login',
    //管理员
    master: {
      index: '/admin/master',
      list: '/admin/master',
      add: '/admin/master/add',
      detail: {
        index: '/admin/master/detail',
        base: '/admin/master/detail/base',
        security: '/admin/master/detail/security',
      },
    },
    // 用户
    user: {
      index: '/admin/user',
      list: '/admin/user/list',
      add: '/admin/user/add',
      edit: '/admin/user/edit',
      detail: '/admin/user/detail',
      score: '/admin/user/socreRecord',
    },
    // 文章
    post: {
      index: '/admin/post',
      list: '/admin/post/list',
      look: '/admin/post/look',
      add: '/admin/post/add',
      edit: '/admin/post/edit',
      // 文章分类
      category: {
        index: '/admin/category',
        list: '/admin/category/list',
      },
    },
    // 操作
    operation: {
      index: '/admin/operation',
      list: '/admin/operation/list',
      add: '/admin/operation/add',
      edit: '/admin/operation/edit',
      // 文章分类
      group: {
        index: '/admin/operation/group',
        list: '/admin/operation/groupList',
        add: '/admin/operation/addGroup',
        edit: '/admin/operation/editGroup',
      },
    },
    // 资讯管理
    news: {
      index: '/admin/news',
      list: '/admin/news/list',
      add: '/admin/news/add',
      edit: '/admin/news/edit',
    },
    // 公告管理
    notice: {
      index: '/admin/notice',
      list: '/admin/notice/list',
      add: '/admin/notice/add',
      edit: '/admin/notice/edit',
    },
    // 缴费记录
    pay: {
      index: '/admin/pay',
      list: '/admin/pay/list',
      detail: '/admin/pay/detail',
      rule: '/admin/pay/rule',
    },
    // 申请管理
    apply: {
      index: '/admin/apply',
      list: '/admin/apply/list',
      detail: '/admin/apply/detail',
    },
    // 物业管理
    manger: {
      index: '/admin/manger',
      list: '/admin/manger/list',
    },
    // 车位管理
    parking: {
      index: '/admin/parking',
      list: '/admin/parking/list',
      detail: '/admin/parking/detail',
    },
    house: {
      index: '/admin/house',
      list: '/admin/house/list',
      detail: '/admin/house/detail',
    },
    //积分商城
    integralMall: {
      index: '/admin/integralMall',
      category: '/admin/integralMall/category',
      rule: '/admin/integralMall/rule',
      product: '/admin/integralMall/product',
      productListWithPage: '/admin/integralMall/product/:page',
      detail: '/admin/integralMall/detail',
      add: '/admin/integralMall/add',
      edit: '/admin/integralMall/edit',
    },
    //消息
    message: {
      index: '/admin/message',
      list: '/admin/message/list',
      add: '/admin/message/add',
      edit: '/admin/message/edit',
    },
    //订单
    order: {
      index: '/admin/order',
      list: '/admin/order/list',
      detail: '/admin/order/detail',
    },
    //报修
    repair: {
      index: '/admin/repair',
      list: '/admin/repair/list',
      detail: '/admin/repair/detail',
    },
    //投诉
    complaint: {
      index: '/admin/complaint',
      list: '/admin/complaint/list',
      detail: '/admin/complaint/detail',
    },
    //便民服务
    convenientService: {
      index: '/admin/convenientService',
      list: '/admin/convenientService/list',
      add: '/admin/convenientService/add',
      edit: '/admin/convenientService/edit',
    },
  },
  logout: '/logout',
}
