import { BuffRoute } from '@/typings'
import Home from '@pages/Home'
import pathMap from './pathMap'
import Basic from '@/components/Basic'
export default {
  name: 'home',
  path: pathMap.home,
  component: Basic,
  routes: [
    {
      name: 'home',
      path: pathMap.home,
      exact: true,
      component: Home,
    },
  ],
} as BuffRoute
